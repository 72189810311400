import React from 'react'
import { FormControl, InputGroup } from 'react-bootstrap'

const EmailBuilderTab = () => {
    return (
        <>
            <div className='d-flex align-items-center justify-content-between'>
                <h4>Your templates</h4>
                <InputGroup className="custom col-md-3 mb-3">
                    <InputGroup.Text id="basic-addon1" className='bg-transparent'><i class="fas fa-search"></i></InputGroup.Text>
                    <FormControl
                        placeholder="Search"
                        aria-label="Search"
                        aria-describedby="basic-addon1"
                    />
                </InputGroup>
            </div>
            <div className='my-3'>
                <button className='btn click-btn green-btn'>Create new email</button>
            </div>
            <div>
                <div className='lead'>Recently created</div>
                <div className='row mt-3'>
                    <div className='col-md-3'>
                        <div className='image-box mb-2'>
                            <img className='img-responsive' height={400} src={`${process.env.PUBLIC_URL}/assets/images/image 28.svg`} />
                        </div>
                        <h6>Welcome Email</h6>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EmailBuilderTab