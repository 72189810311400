import {
  GET_SUBSCRIPTION,
  GET_SUBSCRIPTION_SUCCESS,
  CANCEL_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION_SUCCESS,
  CHANGE_SUBSCRIPTION,
  CHANGE_SUBSCRIPTION_SUCCESS,
  CHANGE_PAYMENT_METHOD,
  CHANGE_PAYMENT_METHOD_SUCCESS,
  GET_PAYMENT_METHOD,
  GET_PAYMENT_METHOD_SUCCESS,
  LIST_BILLINGS,
  LIST_BILLINGS_SUCCESS,
  LIST_PLANS,
  LIST_PLANS_SUCCESS,
  PAY_SUBSCRIPTION,
  PAY_SUBSCRIPTION_SUCCESS,
  SELECT_PLAN,
  SELECT_PLAN_SUCCESS
} from "./actionTypes"

export const getSubscription = (user, history) => {
  return {
    type: GET_SUBSCRIPTION,
    payload: { user, history },
  }
}

export const getSubscriptionSuccess = (data) => {
  return {
    type: GET_SUBSCRIPTION_SUCCESS,
    payload: data
  }
}

export const changeSubscription = (user, history) => {
  return {
    type: CHANGE_SUBSCRIPTION,
    payload: { user, history }
  }
}

export const changeSubscriptionSuccess = (data) => {
  return {
    type: CHANGE_SUBSCRIPTION_SUCCESS,
    payload: data
  }
}

export const changePaymentMethod = () => {
  return {
    type: CHANGE_PAYMENT_METHOD,
    payload: {}
  }
}

export const changePaymentMethodSuccess = (data) => {
  return {
    type: CHANGE_PAYMENT_METHOD_SUCCESS,
    payload: data
  }
}

export const getPyamentMethod = (data) => {
  return {
    type: GET_PAYMENT_METHOD,
    payload: { data }
  }
}

export const getPyamentMethodSuccess = (data) => {
  return {
    type: GET_PAYMENT_METHOD_SUCCESS,
    payload: data
  }
}

export const listBillings = (data) => {
  return {
    type: LIST_BILLINGS,
    payload: { data }
  }
}

export const listBillingsSuccess = (data) => {
  return {
    type: LIST_BILLINGS_SUCCESS,
    payload: data
  }
}

export const listPlans = () => {
  return {
    type: LIST_PLANS,
    payload: {}
  }
}

export const listPlansSuccess = (data) => {
  return {
    type: LIST_PLANS_SUCCESS,
    payload: data
  }
}

export const selectPlan = (id, history) => {
  return {
    type: SELECT_PLAN,
    payload: { id, history }
  }
}

export const selectPlanSuccess = (data) => {
  return {
    type: SELECT_PLAN_SUCCESS,
    payload: data
  }
}

export const paySubscription = () => {
  return {
    type: PAY_SUBSCRIPTION,
    payload: {},
  }
}

export const paySubscriptionSuccess = () => {
  return {
    type: PAY_SUBSCRIPTION_SUCCESS,
    payload: {}
  }
}