// import React from 'react';

// import { Route, BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';

// import Auth from './pages/Auth/Auth';
// import Dashboard from './pages/Dashboard/index';

// import './App.css';
// import { useSelector } from 'react-redux';

// const handleAuth = (token) => {
//   if (!token) {
//     return (
//       <Switch>
//         <Route path="/dashboard" component={Dashboard} />
//         <Redirect to={"/dashboard"} />
//       </Switch>
//     )
//   } else {
//     return (
//       <Switch>
//         <Route path="/auth" component={Auth} />
//         <Redirect to={"/auth"} />
//       </Switch>
//     )
//   }
// }

// const App = () => {
//   const auth = true

//   const { token } = useSelector(state => ({
//     token: state?.auth?.token
//   }))

//   return (
//     <div className="mainPage">
//       <Router>
//         {handleAuth(token)}
//       </Router>
//     </div>
//   );
// }

// export default App;

import React, { useEffect } from 'react'
import Router from './routers/router'
import { ReactNotifications } from 'react-notifications-component'
import { Store } from 'react-notifications-component';
import { useDispatch, useSelector } from 'react-redux'

import 'react-notifications-component/dist/theme.css'
import 'animate.css/animate.min.css';
import { clearError } from './store/actions';

function App() {
  const dispatch = useDispatch()
  const { error, success } = useSelector(state => ({
    error: state.ui.error,
    success: state.ui.success
  }))

  const notificationValues = {
    container: "top-center",
    animationIn: ['animate__animated animate__fadeIn'],
    animationOut: ['animate__animated animate__fadeOut'],
    dismiss: {
      duration: 3000
    },
    insert: 'top'
  }

  useEffect(() => {
    if (error) {
      Store.addNotification({
        title: error['en'],
        type: "danger",
        ...notificationValues
      })
    }
    if (success) {
      Store.addNotification({
        title: success['en'],
        type: "success",
        ...notificationValues
      })
    }
    if(error || success) dispatch(clearError())
  }, [error, success])

  return (
    <div className="app-container">
      <ReactNotifications />
      <Router />
    </div>
  )
}

export default App
