import React from "react";

function Tabs({ handleSelectContent, tab, business }) {
  return (
    <div className='tabs'>
      <button type='button' className={tab === 1 ? 'btn active' : 'btn'} value="all" onClick={handleSelectContent}>
        All Keywords ({business?.keywords?.length})
      </button>
      <button type='button' className={tab === 2 ? 'btn active' : 'btn'} value="common" onClick={handleSelectContent}>
        Keywords in common with others ({business?.keywordsCommon?.length})
      </button>
      <button type='button' className={tab === 3 ? 'btn active' : 'btn'} value="posts" onClick={handleSelectContent}>
        Posts ({business?.posts?.length})
      </button>
      <button type='button' className={tab === 4 ? 'btn active' : 'btn'} value="reviews" onClick={handleSelectContent}>
        Reviews ({business?.reviews?.length})
      </button>
      <button type='button' className={tab === 5 ? 'btn active' : 'btn'} value="discovering" onClick={handleSelectContent}>
        Discover Growth Opportunity by Looking the Keywords of Similar Businesses
      </button>
    </div>
  )
}

export default Tabs;
