import React, { useState } from 'react'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Spinner } from 'reactstrap'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser } from '../../../store/auth/actions';
import { checkIfLoading } from '../../../store/functions';
import { GET_USER_DATA } from '../../../store/auth/actionTypes';

function AvatarDropdown() {
    // ** states
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dispatch = useDispatch()
    const history = useNavigate()

    // ** function to handle toggle of dropdown
    const toggle = () => setDropdownOpen((prevState) => !prevState);

    const { user, userLoading } = useSelector(state => ({
        user: state.auth.user,
        userLoading: checkIfLoading(state, GET_USER_DATA)
    }))

    return (
        <div className="avatar-con">
            <Dropdown className='custom-dropdown' isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle caret>
                    {userLoading ? <Spinner className="ml-2" size="sm" color="success" /> : <img src={user?.profilePicture || `${process.env.PUBLIC_URL}/assets/images/avatar.jpg`} alt="avatar" />}
                </DropdownToggle>
                <DropdownMenu right={true}>
                    <DropdownItem>
                        <Link to="/settings" className="btn">Settings</Link>
                    </DropdownItem>
                    <DropdownItem>
                        <button onClick={() => dispatch(logoutUser(history))} className="btn">Sign Out</button>
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </div>
    )
}

export default AvatarDropdown