import {
  ADD_CUSTOMER,
  ADD_CUSTOMER_SUCCESS,
  GET_ANALYTICS,
  GET_ANALYTICS_SUCCESS,
  LIST_CUSTOMERS,
  LIST_CUSTOMERS_SUCCESS,
  LIST_TAGS,
  LIST_TAGS_SUCCESS,
  LIST_TOP_5_TAGS,
  LIST_TOP_5_TAGS_SUCCESS
} from "./actionTypes"

const initialState = {
  analytics: {},
  customers: {},
  tags: [],
  top5tags: []
}

const crm = (state = initialState, action) => {
  switch (action.type) {
    case GET_ANALYTICS:
      return { ...state, }
    case GET_ANALYTICS_SUCCESS:
      return { ...state, analytics: action.payload }
    case LIST_CUSTOMERS:
      return { ...state, }
    case LIST_CUSTOMERS_SUCCESS:
      return { ...state, customers: action.payload }
    case ADD_CUSTOMER:
      return { ...state, }
    case ADD_CUSTOMER_SUCCESS:
      return {
        ...state, customers: {
          ...state.customers,
          list: [action.payload, ...state.customers.list],
          page_details: {
            ...state.customers.page_details,
            total_items: state.customers.page_details.total_items + 1
          }
        }
      }
    case LIST_TAGS:
      return { ...state, }
    case LIST_TAGS_SUCCESS:
      return { ...state, tags: action.payload }
    case LIST_TOP_5_TAGS:
      return { ...state, }
    case LIST_TOP_5_TAGS_SUCCESS:
      return { ...state, top5tags: action.payload }
    default:
      return state
  }
}

export default crm
