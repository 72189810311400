import React from 'react'
import { FormControl, InputGroup } from 'react-bootstrap'
import { Spinner } from 'reactstrap'
import TablePagination from '../../../../components/common/Pagination'

const BacklinksTable = ({ loading, backlinks, details, filters, setPage }) => {
    const renderBacklinks = () => {
        if (loading) return <Spinner size="sm" className="m-auto text-center" color="success" />
        if (backlinks?.length > 0) {
            return backlinks?.map(bk => {
                return <tr>
                    <td>
                        <div><b>{bk?.domain}</b></div>
                        <div><small className='text-muted text-sm'>{bk?.url}</small></div>
                    </td>
                    <td>{bk?.domainAuthority}</td>
                    <td>{bk?.pageAuthority}</td>
                    <td>{bk?.targetUrl}</td>
                </tr>
            })
        } else {
            return <tr><td colSpan={4}>No backlinks found</td></tr>
        }
    }

    return (
        <div className='mt-5'>
            <h6>{details?.total_items || 0} backlinks</h6>
            <div className='row justify-content-between align-items-end'>
                <div className='col-md-4'>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1" className='bg-transparent'><i class="fas fa-search"></i></InputGroup.Text>
                        <FormControl
                            placeholder="Search"
                            aria-label="Search"
                            aria-describedby="basic-addon1"
                        />
                    </InputGroup>
                </div>
                <div className='col-md-6'>
                    
                </div>
            </div>
            <table className='table'>
                <thead>
                    <tr>
                        <th>Who is linking to you</th>
                        <th>Domain Authority</th>
                        <th>Page Authority</th>
                        <th>Target URL</th>
                    </tr>
                </thead>
                <tbody>
                    {renderBacklinks()}
                </tbody>
            </table>
            <TablePagination count={details?.total_items} page={filters?.page} setPage={setPage} limit={filters?.limit} loading={loading} />
        </div>
    )
}

export default BacklinksTable