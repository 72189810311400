import React, { useEffect, useState } from 'react'
import { Col, FormControl, InputGroup, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Spinner } from 'reactstrap'
import TablePagination from '../../../components/common/Pagination'
import { addCompetitor, competitorsBacklinks, getCompetitorBacklinks, listBacklinks, listCompetitors, removeCompetitor } from '../../../store/actions'
import { checkIfLoading } from '../../../store/functions'
import { ADD_COMPETITOR, COMPETITORS_BACKLINKS, GET_COMPETITOR_BACKLINKS, LIST_BACKLINKS, LIST_COMPETITORS, REMOVE_COMPETITOR } from '../../../store/webreputation/actionTypes'
import WebReputationNav from './components/Nav'
import CompetitorsBacklinksTable from './components/CompetitorsBacklinksTable'

function CompetitorsBacklinks() {
    const dispatch = useDispatch()
    const [selectedCompetitor, setSelectedCompetitor] = useState(null)
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [addCompBox, setAddCompBox] = useState(false)
    const [compUrl, setCompUrl] = useState(null)

    const toggle = () => setDropdownOpen((prevState) => !prevState);

    const { backlinks, details, competitors, bkLoading, compLoading, addCompLoading, removeCompLoading } = useSelector(state => ({
        backlinks: state?.webreputation?.competitor?.list,
        details: state?.webreputation?.competitor?.page_details,
        competitors: state?.webreputation?.competitors?.list,
        bkLoading: checkIfLoading(state, GET_COMPETITOR_BACKLINKS),
        compLoading: checkIfLoading(state, LIST_COMPETITORS),
        addCompLoading: checkIfLoading(state, ADD_COMPETITOR),
        removeCompLoading: checkIfLoading(state, REMOVE_COMPETITOR)
    }))

    const [filters, setFilters] = useState({
        page: 1,
        limit: 10
    })

    const setPage = (page) => {
        setFilters({
            ...filters,
            page
        })
    }

    useEffect(() => {
        dispatch(listCompetitors())
    }, [])

    useEffect(() => {
        if (competitors?.length > 0) {
            setSelectedCompetitor(competitors[0])
        }
    }, [competitors])

    useEffect(() => {
        if (selectedCompetitor) {
            const query = new URLSearchParams(filters).toString()
            const id = selectedCompetitor?._id
            console.log(query)
            dispatch(getCompetitorBacklinks({ ...query, competitor: id }))
        }
    }, [selectedCompetitor, filters.page])

    const renderBacklinks = () => {
        if (bkLoading) return <Spinner size="sm" className="m-auto text-center" color="success" />
        if (backlinks && backlinks?.length > 0) {
            return backlinks?.map(bk => {
                return <tr>
                    <td>
                        <div><b>{bk?.domain}</b></div>
                        <div><small className='text-muted text-sm'>{bk?.url}</small></div>
                    </td>
                    <td>{bk?.domainAuthority}</td>
                    <td>{bk?.pageAuthority}</td>
                    <td>{bk?.targetDomain}</td>
                </tr>
            })
        } else {
            return <tr><td colSpan={4}>No backlinks found</td></tr>
        }
    }

    const renderCompetitors = () => {
        if (compLoading) return <Spinner size="sm" className="m-auto text-center" color="success" />
        if (competitors?.length > 0) {
            return competitors?.map(comp => {
                return <DropdownItem onClick={() => setSelectedCompetitor(comp)}>
                    <button className="btn">{comp?.url}</button>
                </DropdownItem>
            })
        } else {
            return <option>No Competitor found</option>
        }
    }

    const submitAddCompetitor = () => {
        if (!compUrl) return

        dispatch(addCompetitor(compUrl, () => setAddCompBox(false)))
    }

    return (
        <div className='container-fluid backlinks page'>
            <WebReputationNav />
            <div className='mt-5'>
                <h4>Choose the competitor to view who is linking to him</h4>
                <Dropdown className='custom-dropdown select-box' isOpen={dropdownOpen} toggle={toggle}>
                    <DropdownToggle caret>
                        {selectedCompetitor?.url}
                    </DropdownToggle>
                    <DropdownMenu right={true}>
                        {renderCompetitors()}
                    </DropdownMenu>
                </Dropdown>
                <div className='d-flex my-3'>
                    <button onClick={() => setAddCompBox(!addCompBox)} className='btn click-btn green-btn mr-2'>Add new competitor</button>
                    <button className='btn click-btn orange-btn-outline' onClick={() => dispatch(removeCompetitor(selectedCompetitor?._id))}>
                        {
                            removeCompLoading ? <Spinner size="sm" className="m-auto text-center" color="orange" /> : "Remove Competitor"
                        } 
                    </button>
                </div>
                <div className='add-competitor' style={{ display: addCompBox ? 'block' : 'none' }}>
                    <div className='row align-items-end'>
                        <div className='col-md-8'>
                            <input className='form-control' onChange={(e) => setCompUrl(e.target.value)} placeholder='Enter competitor url' />
                        </div>
                        <div className='col-md-4'>
                            <button className='btn click-btn orange-btn btn-block py-1 px-1' onClick={submitAddCompetitor}>
                                {
                                    addCompLoading ? <Spinner size="sm" className="m-auto text-center" color="light" /> : 'Add Competitor'
                                }
                            </button>
                        </div>
                    </div>
                </div>
                <h5>{backlinks?.length} Backlinks</h5>
                <div className='d-flex'>
                    <InputGroup className="mb-3 col-md-6">
                        <InputGroup.Text id="basic-addon1" className='bg-transparent'><i class="fas fa-search"></i></InputGroup.Text>
                        <FormControl
                            placeholder="Search"
                            aria-label="Search"
                            aria-describedby="basic-addon1"
                        />
                    </InputGroup>
                </div>
                <div className='col-md-12'>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>Who is linking to your competitors?</th>
                                <th>Domain Authority</th>
                                <th>Page Authority</th>
                                <th>Competitor’s URL</th>
                            </tr>
                        </thead>
                        <tbody>
                            {renderBacklinks()}
                        </tbody>
                    </table>
                </div>
                <div className='col-md-12 mt-3'>
                    <TablePagination count={details?.total_items} page={filters?.page} setPage={setPage} limit={filters?.limit} loading={bkLoading} />
                </div>
            </div>
        </div>
    )
}

export default CompetitorsBacklinks