import {
  ADD_REMINDER,
  ADD_REMINDER_SUCCESS,
  UPDATE_REMINDER,
  UPDATE_REMINDER_SUCCESS,
  LIST_REMINDERS,
  LIST_REMINDERS_SUCCESS
} from "./actionTypes"

export const listReminders = (query) => {
  return {
    type: LIST_REMINDERS,
    payload: { query },
  }
}

export const listRemindersSuccess = (data) => {
  return {
    type: LIST_REMINDERS_SUCCESS,
    payload: data
  }
}

export const addReminder = (data, toggle) => {
  return {
    type: ADD_REMINDER,
    payload: { data, toggle },
  }
}

export const addReminderSuccess = (data) => {
  return {
    type: ADD_REMINDER_SUCCESS,
    payload: data
  }
}

export const updateReminder = (id, data, toggle) => {
  return {
    type: UPDATE_REMINDER,
    payload: { id, data, toggle },
  }
}

export const updateReminderSuccess = (data) => {
  return {
    type: UPDATE_REMINDER_SUCCESS,
    payload: data
  }
}