import { put, select, takeEvery } from "redux-saga/effects"
import { GetBusinessAPI, ListBusinessesAPI } from "./calls"
import { getBusinessSuccess, listBusinessesSuccess } from './actions'
import { GET_BUSINESS, LIST_BUSINESSES } from "./actionTypes"
import { setError, startAction, stopAction } from "../actions"

function* listBusinesses({ type, payload: { name, toggle1, toggle2 } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const workspace = state.workspace.workspace?._id
    const query = new URLSearchParams({ name, workspace }).toString()
    const result = yield ListBusinessesAPI(query, state)
    console.log(result)
    yield put(listBusinessesSuccess(result?.data?.data))
    if(toggle1) toggle1()
    if(toggle2) toggle2()
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* getBusiness({ type, payload: { id, history } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const result = yield GetBusinessAPI(id, state)
    console.log(result)
    yield put(getBusinessSuccess(result?.data?.data))
    history('/detailed-report')
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* LocalseoSaga() {
  yield takeEvery(LIST_BUSINESSES, listBusinesses)
  yield takeEvery(GET_BUSINESS, getBusiness)
}

export default LocalseoSaga