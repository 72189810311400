import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  AUTH_GOOGLE,
  AUTH_GOOGLE_SUCCESS,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  GET_USER_DATA,
  GET_USER_DATA_SUCCESS,
  LIST_ANALYTICS,
  LIST_ANALYTICS_SUCCESS,
  LIST_LANGUAGES,
  LIST_LANGUAGES_SUCCESS,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  UPDATE_EMAIL,
  UPDATE_EMAIL_SUCCESS,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_USER_DATA,
  UPDATE_USER_DATA_SUCCESS,
  VERIFY_USER,
  VERIFY_USER_SUCCESS
} from "./actionTypes"

const initialState = {
  user: null,
  token: localStorage.getItem('token') || null
}

const auth = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state }
    case LOGIN_USER_SUCCESS:
      return { ...state, user: action.payload?.user, token: action.payload?.token }
    case REGISTER_USER:
      return { ...state }
    case REGISTER_USER_SUCCESS:
      return { ...state }
    case VERIFY_USER:
      return { ...state }
    case VERIFY_USER_SUCCESS:
      return { ...state, token: action.payload }
    case GET_USER_DATA:
      return { ...state }
    case GET_USER_DATA_SUCCESS:
      return { ...state, user: action.payload }
    case UPDATE_USER_DATA:
      return { ...state }
    case UPDATE_USER_DATA_SUCCESS:
      return { ...state, user: action.payload?.data }
    case UPDATE_PASSWORD:
      return { ...state }
    case UPDATE_PASSWORD_SUCCESS:
      return { ...state, }
    case FORGET_PASSWORD:
      return { ...state }
    case FORGET_PASSWORD_SUCCESS:
      return { ...state, }
    case RESET_PASSWORD:
      return { ...state }
    case RESET_PASSWORD_SUCCESS:
      return { ...state, }
    case UPDATE_EMAIL:
      return { ...state }
    case UPDATE_EMAIL_SUCCESS:
      return { ...state, user: { ...state?.user, email: action?.payload } }
    case LIST_LANGUAGES:
      return { ...state }
    case LIST_LANGUAGES_SUCCESS:
      return { ...state, }
    case AUTH_GOOGLE:
      return { ...state }
    case AUTH_GOOGLE_SUCCESS:
      return { ...state, }
    case LIST_ANALYTICS:
      return { ...state }
    case LIST_ANALYTICS_SUCCESS:
      return { ...state, }
    case DELETE_USER:
      return { ...state }
    case DELETE_USER_SUCCESS:
      return { ...state }
    case LOGOUT_USER:
      return { ...state }
    case LOGOUT_USER_SUCCESS:
      return { ...state, user: null, token: null }
    default:
      return state
  }
}

export default auth
