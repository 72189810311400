import { put, select, takeEvery } from "redux-saga/effects"
import { setError, setSuccess, startAction, stopAction } from "../actions"
import { addReminderSuccess, listRemindersSuccess, updateReminderSuccess } from "./actions"
import { ADD_REMINDER, LIST_REMINDERS, UPDATE_REMINDER } from "./actionTypes"
import { AddReminderAPI, ListRemindersAPI, UpdateReminderAPI } from "./calls"

function* listReminders({ type, payload: { } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const result = yield ListRemindersAPI(state)
    console.log(result)
    yield put(listRemindersSuccess(result?.data?.data))
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* addReminder({ type, payload: { data, toggle } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const result = yield AddReminderAPI(data, state)
    console.log(result)
    yield put(setSuccess(result?.data?.message))
    yield put(addReminderSuccess(result?.data?.data))
    toggle()
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* updateReminder({ type, payload: { id, data, toggle } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const result = yield UpdateReminderAPI(id, data, state)
    console.log(result)
    yield put(setSuccess(result?.data?.message))
    yield put(updateReminderSuccess(result?.data?.data))
    toggle()
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* ReminderSaga() {
  yield takeEvery(LIST_REMINDERS, listReminders)
  yield takeEvery(ADD_REMINDER, addReminder)
  yield takeEvery(UPDATE_REMINDER, updateReminder)
}

export default ReminderSaga