import React, { useEffect, useState } from 'react'
import { Col, FormControl, InputGroup, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Spinner } from 'reactstrap'
import TablePagination from '../../../components/common/Pagination'
import { competitorsBacklinks, getBacklinksData, listBacklinks, listCompetitors, removeCompetitor } from '../../../store/actions'
import { checkIfLoading } from '../../../store/functions'
import { GET_BACKLINKS_DATA, LIST_BACKLINKS, LIST_COMPETITORS } from '../../../store/webreputation/actionTypes'
import BacklinksOverTime from './components/BacklinksOvertime'
import BacklinksTable from './components/BacklinksTable'
import WebReputationNav from './components/Nav'
import NewLostBacklinks from './components/NewLostBacklinks'

function BacklinksPage() {
    const dispatch = useDispatch()

    const { workspace, backlinksData, backlinks, backlinksListDetails, backlinksLoading, backlinksDataLoading } = useSelector(state => ({
        backlinksData: state?.webreputation?.backlinksData,
        backlinks: state?.webreputation?.backlinks?.list,
        backlinksListDetails: state?.webreputation?.backlinks?.page_details,
        backlinksDataLoading: checkIfLoading(state, GET_BACKLINKS_DATA),
        backlinksLoading: checkIfLoading(state, LIST_BACKLINKS),
        workspace: state?.workspace?.workspace
    }))

    const [filters, setFilters] = useState({
        page: 1,
        limit: 10
    })

    const setPage = (page) => {
        setFilters({
            ...filters,
            page
        })
    }

    useEffect(() => {
        dispatch(getBacklinksData())
    }, [])

    useEffect(() => {
        dispatch(listBacklinks(filters))
    }, [filters.page])

    return (
        <div className='container-fluid backlinks page'>
            <WebReputationNav />
            <div className='d-flex justify-content-between mt-4'>
                <div className='d-flex align-items-center'>
                    <h5 className='m-0 mr-2'>Backlinks: </h5>
                    <span className='lead'>{workspace?.name || workspace?.domain}</span>
                </div>
                <span><i class="fas fa-sm mr-2 text-muted fa-sync"></i> Update</span>
            </div>
            <div className='row mt-4'>
                <div className='col-md-4'>
                    <div className='card'>
                        {
                            backlinksDataLoading ? <Spinner size="md" className="m-auto text-center" color="success" /> : <>
                                <h2>{backlinksData?.domainAuthority} <i className="fas fa-arrow-up text-success"></i></h2>
                                <span>Your domain authority</span>
                            </>
                        }
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className='card d-flex flex-row align-items-center justify-content-between'>
                        {
                            backlinksDataLoading ? <Spinner size="md" className="m-auto text-center" color="success" /> : <>
                                <div className='d-inline-block'>
                                    <h2>{backlinksData?.backlinksCount} <i className="fas fa-arrow-up text-success"></i></h2>
                                    <span>Your Backlinks</span>
                                </div>
                                <span>NoFollow: {backlinksData?.follow_backlinks}</span></>
                        }
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className='card'>
                        {
                            backlinksDataLoading ? <Spinner size="md" className="m-auto text-center" color="success" /> : <>
                                <h2>{backlinksData?.referring_domains} <i className="fas fa-arrow-down text-danger"></i></h2>
                                <span>Different domains are linking to you</span>
                            </>
                        }
                    </div>
                </div>
            </div>
            <Row className='mt-3'>
                <Col md={12}>
                    <BacklinksOverTime data={backlinksData?.backlinksHistory} />
                </Col>
            </Row>
            <Row className='mt-3'>
                <Col md={12}>
                    <NewLostBacklinks data={backlinksData?.newLostBacklinks} />
                </Col>
            </Row>
            <BacklinksTable filters={filters} setPage={setPage} details={backlinksListDetails} loading={backlinksLoading} backlinks={backlinks} />
        </div>
    )
}

export default BacklinksPage