import React, { useEffect, useState } from 'react'
import { InputGroup, FormControl } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { getCrmAnalytics } from '../../../store/actions'
import AutomationsTab from './Components/AutomationsTab'
import EmailBuilderTab from './Components/EmailBuilderTab'
import MarketingNav from './Components/MarketingNav'
import CrmNav from './Components/Nav'
import NewAutomationTab from './Components/NewAutomationTab'

function MarketingAutomation() {
  const dispatch = useDispatch()
  const [tab, setTab] = useState('new-automation')
  const [selectedContent, setSelectedContent] = useState()

  const handleTabChange = (tab) => {
    switch (tab) {
      case "new-automation":
        setSelectedContent(<NewAutomationTab />)
        break;
      case "email-builder":
        setSelectedContent(<EmailBuilderTab />)
        break;
      case "automations":
        setSelectedContent(<AutomationsTab />)
        break;
    }
  }

  useEffect(() => {
    handleTabChange(tab)
  }, [tab])

  return (
    <div className='container-fluid marketing page'>
      <CrmNav />
      <div className='mt-2'>
        <MarketingNav tab={tab} setTab={setTab} />
      </div>
      <hr />
      {selectedContent}
    </div>
  )
}

export default MarketingAutomation