export const LIST_COMPETITORS = 'LIST_COMPETITORS'
export const LIST_COMPETITORS_SUCCESS = 'LIST_COMPETITORS_SUCCESS'

export const ADD_COMPETITOR = 'ADD_COMPETITOR'
export const ADD_COMPETITOR_SUCCESS = 'ADD_COMPETITOR_SUCCESS'

export const REMOVE_COMPETITOR = 'REMOVE_COMPETITOR'
export const REMOVE_COMPETITOR_SUCCESS = 'REMOVE_COMPETITOR_SUCCESS'

export const LIST_BACKLINKS = 'LIST_BACKLINKS'
export const LIST_BACKLINKS_SUCCESS = 'LIST_BACKLINKS_SUCCESS'

export const GET_BACKLINKS_DATA = 'GET_BACKLINKS_DATA'
export const GET_BACKLINKS_DATA_SUCCESS = 'GET_BACKLINKS_DATA_SUCCESS'

export const COMPETITORS_BACKLINKS = 'COMPETITORS_BACKLINKS'
export const COMPETITORS_BACKLINKS_SUCCESS = 'COMPETITORS_BACKLINKS_SUCCESS'

export const GET_COMPETITOR_BACKLINKS = 'GET_COMPETITOR_BACKLINKS'
export const GET_COMPETITOR_BACKLINKS_SUCCESS = 'GET_COMPETITOR_BACKLINKS_SUCCESS'

export const CREATE_EMAIL = 'CREATE_EMAIL'
export const CREATE_EMAIL_SUCCESS = 'CREATE_EMAIL_SUCCESS'

export const GET_FEEDBACK = 'GET_FEEDBACK'
export const GET_FEEDBACK_SUCCESS = 'GET_FEEDBACK_SUCCESS'

export const LIST_CAMPAIGNS = 'LIST_CAMPAIGNS'
export const LIST_CAMPAIGNS_SUCCESS = 'LIST_CAMPAIGNS_SUCCESS'