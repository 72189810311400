import { put, select, takeEvery } from "redux-saga/effects"
import { setError, startAction, stopAction } from "../actions"
import { START_UPLOAD } from "./actionTypes"
import { UploadImageAPI } from "./calls"

export function* uploadImageSaga({ type, payload: { uploadType, file } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const result = yield UploadImageAPI(uploadType, file, state)
    console.log(result)
    return result?.data?.data
  } catch (err) {
    yield put(setError('Error, Please try again later'))
  } finally {
    yield put(stopAction(type))
  }
}

function* uploadSaga() {
  yield takeEvery(START_UPLOAD, uploadImageSaga)
}

export default uploadSaga