import React, { useEffect, useState } from 'react'
import { Collapse, Button, CardBody, Card, Tooltip } from 'reactstrap';

function Issues({ data }) {
  // ** states
  const [isOpen, setIsOpen] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [errors, setErrors] = useState([])

  // ** function to handle toggle tooltip
  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  useEffect(() => {
    if (data) {
      let errors = []
      for (let [key, value] of Object.entries(data)) {
        if (key !== 'pages' && key !== 'issuesCount' && key !== 'badUrls') {
          errors.push({
            key,
            value
          })
        }
      }
      setErrors(errors)
    }
  }, [data])

  const renderErrors = (errors) => {
    if (errors && errors?.length > 0) {
      return errors.map(err => {
        return <>
          <Button className={isOpen === err?.key ? 'toggled-btn issue-btn' : 'issue-btn'} onClick={() => isOpen === err?.key ? setIsOpen(null) : setIsOpen(err?.key)}>
            <i className="fas fa-question-circle" id="TooltipExample"></i>
            <Tooltip placement="right" isOpen={tooltipOpen} target="TooltipExample" toggle={toggleTooltip}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Massa porttitor fermentum viverra potenti luctus lorem et. In malesuada tincidunt vitae habitant in nisl diam accumsan.
            </Tooltip>
            {err?.key}
            <span className='numbered-issue'>{err?.value?.length || err?.value || 0}</span>
          </Button>
          {
            Array.isArray(err.value) && err?.value?.length > 0 ? err?.value?.map(e => {
              return <Collapse isOpen={isOpen === err?.key}>
              <Card>
                <a href='#top' className='btn'>{e} <span>1</span></a>
              </Card>
            </Collapse>
            }) : ""
          }
        </>
      })
    }
  }

  return (
    <div className='page-issues pb-5'>
      <h2>Page Issues</h2>
      <div className='issues'>
        {renderErrors(errors)}
      </div>
    </div>
  )
}

export default Issues