import api from '../apis/api'
import { tokenConfig } from '../config'

export const ListRemindersAPI = (state) => {
  return new Promise((res, rej) => {
    api.get(`/reminder`, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const AddReminderAPI = (body, state) => {
  return new Promise((res, rej) => {
    api.post(`/reminder`, body, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const UpdateReminderAPI = (id, body, state) => {
  return new Promise((res, rej) => {
    api.put(`/reminder/${id}`, body, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}