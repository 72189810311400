import {
  LIST_BUSINESSES,
  LIST_BUSINESSES_SUCCESS,
  GET_BUSINESS,
  GET_BUSINESS_SUCCESS
} from "./actionTypes"

export const listBusinesses = (name, toggle1, toggle2) => {
  return {
    type: LIST_BUSINESSES,
    payload: { name, toggle1, toggle2 },
  }
}

export const listBusinessesSuccess = (data) => {
  return {
    type: LIST_BUSINESSES_SUCCESS,
    payload: data
  }
}

export const getBusiness = (id, history) => {
  return {
    type: GET_BUSINESS,
    payload: { id, history },
  }
}

export const getBusinessSuccess = (data) => {
  return {
    type: GET_BUSINESS_SUCCESS,
    payload: data
  }
}