import React, { useEffect, useState } from 'react'
import { Card, Col, FormControl, InputGroup, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Spinner } from 'reactstrap'
import DonutChart from '../../../components/charts/DonutChart'
import TablePagination from '../../../components/common/Pagination'
import { competitorsBacklinks, getFeedback, listBacklinks, listCompetitors, removeCompetitor } from '../../../store/actions'
import { checkIfLoading } from '../../../store/functions'
import { GET_FEEDBACK, LIST_BACKLINKS, LIST_COMPETITORS } from '../../../store/webreputation/actionTypes'
import BacklinksOverTime from './components/BacklinksOvertime'
import BacklinksTable from './components/BacklinksTable'
import WebReputationNav from './components/Nav'
import NewLostBacklinks from './components/NewLostBacklinks'

function Feedback() {
    const dispatch = useDispatch()
    const [chartData, setChartData] = useState([])

    const { feedback, feedLoading } = useSelector(state => ({
        feedback: state?.webreputation?.feedback,
        feedLoading: checkIfLoading(state, GET_FEEDBACK)
    }))

    useEffect(() => {
        dispatch(getFeedback())
    }, [])

    useEffect(() => {
        let data = []
        if (feedback) {
            for (let [key, value] of Object.entries(feedback)) {
                if(key !== 'reviews' && key !== 'count') {
                    data.push({
                        label: key,
                        value
                    })
                }
            }
            setChartData(data)
        }
    }, [feedback])

    console.log(chartData)

    const renderFeedback = () => {
        if (feedLoading) return <Spinner size="sm" className="m-auto text-center" color="success" />
        const reviews = feedback?.reviews
        if (reviews && reviews?.length > 0) {
            return reviews?.map(e => {
                return <li className='d-flex border-bottom align-items-center'>
                    <div>
                        <h5 className='text-green'>{e.title}</h5>
                        <span className='text-muted'>{e.url}</span>
                        <p>{e.review}</p>
                    </div>
                    <span className='icon'>
                        {
                            e.rating > 3 ? <i class="fas fa-thumbs-up text-success"></i> :
                                e.rating === 3 ? <span className='text-warning'><b>__</b></span> :
                                    e.rating < 3 ? <i class="fas fa-thumbs-down text-danger"></i> : ""
                        }
                    </span>
                </li>
            })
        }
    }

    return (
        <div className='container-fluid reviews page'>
            <WebReputationNav />
            <div className='row mt-5'>
                <div className='col-md-3'>
                    <div>
                    {
                        feedLoading ? <Spinner size="sm" className="m-auto text-center" color="success" /> :
                            <DonutChart data={chartData?.map(e => e.value)} labels={chartData?.map(e => {
                                return e.label
                            })} colors={[function({ value, seriesIndex, w }) {
                                return seriesIndex === 0 ? '#28a745' : seriesIndex === 1 ? '#dc3545' : seriesIndex === 2 ? "#ffc107" : ""
                              }]} position="bottom" />
                    }
                    <div className='mt-4'>
                        <div><i class="fas fa-circle text-success"></i> Positive {feedback?.positive}</div>
                        <div><i class="fas fa-circle text-danger"></i> Negative {feedback?.negative}</div>
                        <div><i class="fas fa-circle text-warning"></i> Neural {feedback?.neural}</div>
                    </div>
                    </div>
                </div>
                <div className='col-md-8'>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1" className='bg-transparent'><i class="fas fa-search"></i></InputGroup.Text>
                        <FormControl
                            placeholder="Search"
                            aria-label="Search"
                            aria-describedby="basic-addon1"
                        />
                    </InputGroup>
                    <Card>
                        <ul className='list-unstyled reviews-list'>
                            {renderFeedback()}
                        </ul>
                    </Card>
                </div>
            </div>
        </div>
    )
}

export default Feedback