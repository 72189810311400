import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'

function RelatedDocuments() {
    const dispatch = useDispatch()
    const history = useNavigate()

    return (
        <div className='related-documents mb-3'>
            <h5>Related Documents</h5>
            <hr />
            <div className='text-right'>
                <span className='text-green'><b>Upload PDF</b></span>
            </div>
            <div className='d-flex'>
                <div></div>
            </div>
        </div>
    )
}

export default RelatedDocuments