import React from 'react';
import ReactDOM from 'react-dom';
import { loadStripe } from '@stripe/stripe-js';
import {
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
    Elements,
    useStripe,
    useElements
} from '@stripe/react-stripe-js';
import { GetPaymentIntentAPI } from '../../store/subscription/calls';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const CheckoutForm = ({ billing, plan, paymentSubmitBtn, setLoading }) => {
    const stripe = useStripe();
    const elements = useElements();
    const history = useNavigate()

    const { auth, workspace } = useSelector(state => ({
        auth: state.auth,
        workspace: state?.workspace?.workspace
    }))

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (elements == null) {
            return;
        }

        const result = await GetPaymentIntentAPI({ ...billing, plan: plan?._id, workspace: workspace?._id }, { auth })
        const clientSecret = result?.data?.data

        const { paymentIntent } = await stripe.confirmCardPayment(
            clientSecret, {
            payment_method: {
                card: elements.getElement(CardNumberElement)
            }
        });

        setLoading(false)
        if(paymentIntent?.status === 'succeeded') history('/settings')
    };

    return (
        <form>
            <div className='row'>
                <div className='col-md-12'>
                    <label>Card number</label>
                    <CardNumberElement className='form-control custom-border py-2' />
                </div>
            </div>
            <div className='row mt-3'>
                <div className='col-md-6'>
                    <label>Month and year</label>
                    <CardExpiryElement className='form-control custom-border py-2' />
                </div>
                <div className='col-md-6'>
                    <label>CVV code</label>
                    <CardCvcElement className='form-control custom-border py-2' />
                </div>
            </div>
            <button ref={paymentSubmitBtn} hidden onClick={handleSubmit} className="btn click-btn green-btn btn-block">Pay</button>
        </form>
    );
};

const stripePromise = loadStripe('pk_test_51JEGAXJ3SNyCIJUeHfVUyowjYsxb96IBuZ2NcWmYmCFO1BzMFyvYulhBlx8FZmYvjlJlOwOrrPBTr1Fdc5ropKxI00uLH6y5LF');

const StripePayment = ({ billing, plan, paymentSubmitBtn, setLoading }) => (
    <Elements stripe={stripePromise}>
        <CheckoutForm billing={billing} plan={plan} paymentSubmitBtn={paymentSubmitBtn} setLoading={setLoading} />
    </Elements>
);

export default StripePayment