export const LIST_PIPELINES = 'LIST_PIPELINES'
export const LIST_PIPELINES_SUCCESS = 'LIST_PIPELINES_SUCCESS'

export const GET_PIPELINE = 'GET_PIPELINE'
export const GET_PIPELINE_SUCCESS = 'GET_PIPELINE_SUCCESS'

export const ADD_PIPELINE = 'ADD_PIPELINE'
export const ADD_PIPELINE_SUCCESS = 'ADD_PIPELINE_SUCCESS'

export const UPDATE_PIPELINE = 'UPDATE_PIPELINE'
export const UPDATE_PIPELINE_SUCCESS = 'UPDATE_PIPELINE_SUCCESS'

export const DELETE_PIPELINE = 'DELETE_PIPELINE'
export const DELETE_PIPELINE_SUCCESS = 'DELETE_PIPELINE_SUCCESS'

export const ADD_COLUMN = 'ADD_COLUMN'
export const ADD_COLUMN_SUCCESS = 'ADD_COLUMN_SUCCESS'

export const UPDATE_COLUMN = 'UPDATE_COLUMN'
export const UPDATE_COLUMN_SUCCESS = 'UPDATE_COLUMN_SUCCESS'

export const DELETE_COLUMN = 'DELETE_COLUMN'
export const DELETE_COLUMN_SUCCESS = 'DELETE_COLUMN_SUCCESS'

export const ADD_TAB = 'ADD_TAB'
export const ADD_TAB_SUCCESS = 'ADD_TAB_SUCCESS'

export const UPDATE_TAB = 'UPDATE_TAB'
export const UPDATE_TAB_SUCCESS = 'UPDATE_TAB_SUCCESS'

export const DELETE_TAB = 'DELETE_TAB'
export const DELETE_TAB_SUCCESS = 'DELETE_TAB_SUCCESS'