import React, { useEffect, useState } from 'react'
import { FormControl, InputGroup } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Modal, Spinner } from 'reactstrap'
import ImageCropModal from '../../../../components/common/ImageCropModal'
import { updateWorkspaceLogo } from '../../../../store/actions'
import { checkIfLoading } from '../../../../store/functions';
import { UPDATE_WORKSPACE } from '../../../../store/workspace/actionTypes'

const WorkspaceTab = () => {
    const [cropModal, setCropModal] = useState(false)
    const [img, setImg] = useState(undefined)
    const [userImage, setUserImage] = useState(null)
    const dispatch = useDispatch()
    
    const { uploadLoading, workspace } = useSelector(state => ({
        uploadLoading: checkIfLoading(state, UPDATE_WORKSPACE),
        workspace: state?.workspace?.workspace,
    }))

    const handleCropSubmit = file => {
        setCropModal(false)
        setUserImage(file)
    }

    useEffect(() => {
        if(userImage) dispatch(updateWorkspaceLogo(userImage))
    }, [userImage])

    const hiddenFileInput = React.useRef(null);

    const handleClick = event => {
        hiddenFileInput.current.click();
    };

    const handleChange = event => {
        const fileUploaded = event.target.files[0];
        setImg(URL.createObjectURL(fileUploaded))
        setTimeout(() => {
            setCropModal(true)
        }, 300)
    };

    const renderCollaborators = () => {
        const team = workspace?.collaborators
        if (team && team?.length > 0) {
            return team?.map(t => {
                return <li className='d-flex align-items-center'>
                    <img className='img-thumbnail rounded-circle image' src={`${process.env.PUBLIC_URL}/assets/images/reviewer.png`} />
                    <div className='ml-2'>
                        <div className='h6 m-0'>{t.fullName}</div>
                        <span className='text-muted'>{t.email}</span>
                    </div>
                </li>
            })
        }
    }

    return (
        <div className='row workspace-tab'>
            <div className='col-md-8'>
                <h4>Primary Information</h4>
                <div className='workspace-info card'>
                    <div className='d-flex align-items-center'>
                        {
                            uploadLoading ? <Spinner className="ml-2" size="sm" color="success" /> : <>
                                <img className='img-thumbnail rounded-circle avatar cursor-pointer' onClick={() => handleClick()} src={workspace?.logo} alt="workspace logo" />
                                <input type="file" accept="image/*" style={{ display: "none" }} ref={hiddenFileInput} onChange={(e) => handleChange(e)} /></>
                        }
                        <div className='d-inline-block ml-3'>
                            <h5 className='m-0 mb-1'>{workspace?.name}</h5>
                            <span className='btn btn-link m-0 p-0' style={{ fontSize: "14px" }}>Change workspace name</span>
                        </div>
                    </div>
                </div>
                <div className='mt-4'>
                    <h4>SEO Suite  API</h4>
                    <div className='card'>
                        <p>This is your SEO Suite API access key. Here we explain everything about what it is
                            and why is it important. You can copy and paste it. However, don’t share it with
                            anybody, even your friends and family  :)</p>
                        <div className='row'>
                            <div className='col-md-8'>
                                <input className='form-control custom-border py-3' value={workspace?.apiKey} placeholder='cysFXeCnTZHesHYS' />
                            </div>
                            <div className='col-md-4 pl-3'>
                                <button className='btn click-btn orange-btn btn-block py-2'>Copy API</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='mt-4'>
                    <h4>Domain & Email Configuration</h4>
                    <span className='text-muted'>
                        <small>Please note that changes in this section may take effect between 1-30 minutes, depending on server congestion.</small>
                    </span>
                    <div className='card mt-2'>
                        <div className='d-flex mb-3 align-items-center justify-content-between'>
                            <div className='d-flex align-items-center'>
                                <h5>Domain Status</h5>
                                <div className={`badge ml-3 ${workspace?.status === 'Registered' ? 'badge-success' : "badge-danger"}`}>{workspace?.status}</div>
                            </div>
                            <span className='text-muted'>{workspace?.domain}</span>
                        </div>
                        <div>
                            <button className='btn btn-outline-secondary mb-3'>Register Domain</button>
                            <p>To experience our services at its maximum, please make sure that both your email and domain are registered. To register your domain, paste a piece of code that we send to your primary sender email.</p>
                            <span><small className='text-muted'>Note: DNS settings changes might take up to 24 hours depending on the server provider.</small></span>
                            <hr />
                            <h5>Sender Email</h5>
                            <div className='d-flex'>
                                <input className='form-control custom-border col-md-8' value={workspace?.senderEmail} />
                                <button className='btn btn-outline-secondary btn-block ml-2'><i className='fas fa-plus'></i> Add sender email</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='mt-4'>
                    <h4>Shopify Settings</h4>
                    <div className='card mt-2'>
                        <div className='mb-4'>
                            <label className='h5 pl-2'><b>Shopify Store Name</b></label>
                            <input className='form-control custom-border' placeholder='Store Name Saved Example' />
                        </div>
                        <div className='mb-4'>
                            <label className='h5 pl-2'><b>API Key</b></label>
                            <input className='form-control custom-border' placeholder='API key' />
                        </div>
                        <div className='mb-4'>
                            <label className='h5 pl-2'><b>API Password</b></label>
                            <input className='form-control custom-border' placeholder='API Password' />
                        </div>
                        <div className='d-flex mt-3 justify-content-end'>
                            <button className='btn btn-link'>Cancel</button>
                            <button className='btn click-btn col-md-2 btn-block green-btn'>Save</button>
                        </div>
                    </div>
                </div>
                <div className='mt-4'>
                    <h4>Delete Workspace</h4>
                    <span><small>Completely remove your current workspace from SEO Suite</small></span>
                    <div className='card d-flex mt-2'>
                        <h5 className='d-inline-block'>Delete workspace</h5>
                        <span>If you want to delete your workspace, you can do so from this <Link to={'#'} className='text-orange'>Delete Workspace</Link> button.</span>
                    </div>
                </div>
            </div>
            <div className='col-md-4'>
                <h4>Your Team</h4>
                <ul className='list-unstyled collaborators-list'>
                    <li className='d-flex align-items-center justify-content-between'>
                        <div className='d-flex align-items-center'>
                            <img className='img-thumbnail rounded-circle image' src={`${process.env.PUBLIC_URL}/assets/images/reviewer.png`} />
                            <div className='ml-2'>
                                <div className='h6 m-0'>{workspace?.manager?.fullName}</div>
                                <span className='text-muted'>{workspace?.manager?.email}</span>
                            </div>
                        </div>
                        <div className='text-muted'>Owner</div>
                    </li>
                    {renderCollaborators()}
                </ul>
            </div>
            <Modal centered={true} size='lg' isOpen={cropModal} toggle={() => { setCropModal(!cropModal) }}>
                <ImageCropModal handleCropSubmit={handleCropSubmit} img={img} toggle={() => { setCropModal(!cropModal) }} />
            </Modal>
        </div>
    )
}

export default WorkspaceTab