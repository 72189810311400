import React from "react";

function Reviews({ data }) {
  const renderData = () => {
    if (data?.length > 0) {
      return data.map(ele =>
        <div className="review" key={ele._id}>
          <div className="img-con">
            <img src={ele?.image} alt="" />
          </div>
          <div className="description">
            <h6>
              <i class="fas fa-star"></i>
              <span className="review-rate">{ele.rating}</span>
              <span>. by {ele.rateBy}  at {new Date(ele.date).toLocaleDateString()}</span>
            </h6>
            <p className="text">“ {ele.review} “</p>
          </div>
        </div>
      )
    } else {
      return <div>No reviews found</div>
    }
  }

  return (
    <div className="reviews">
      <h2>Reviews ({data?.length})</h2>
      {renderData()}
    </div>
  )
}

export default Reviews;
