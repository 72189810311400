import React, {useState} from 'react'
import SideDropdown from '../../../../components/analysisComponents.js/SideDropdown';
import Issues from '../../../../components/analysisComponents.js/Issues';
import ContactUsModal from '../../../../components/modals/ContactUsModal';
import ErrorsQuotation from '../../../../components/analysisComponents.js/ErrorsQuotation';
import KeywordsRanking from './KeywordsRanking';
import KeywordTracking from './KeywordTracking';
import Suggestedkeywords from './Suggestedkeywords';

function CopiedContent() {
    // ** states
    const [modal, setModal] = useState(false);

    // ** function to handle modal toggle
    const toggle = () => setModal(!modal);

    return (
        <div className='analysis'>
            <div className='container-fluid'>
                <div className='row justify-content-center'>
                    <div className='col-11 text-center'>
                        <SideDropdown />
                        <p className='description'>Every good SEO strategy needs to be done page by page. For This reason we indicate deeper analysis in the specific pages</p>
                    </div>
                    <div className='col-xl-10'>
                        <div className='row'>
                            <div className='col-xl-6'>
                                <Issues />
                            </div>
                            <div className='col-xl-6'>
                                <div className='errors-quotation-chart'>
                                    <div className='card'>
                                        <ErrorsQuotation />
                                    </div>
                                    <p className='fix-hint'>Fixed your issues? Analyze again to see if everything is fixed.</p>
                                    <div className='buttons'>
                                        <button type='button' className='btn orange-btn'>Analyze website</button>
                                        <button type='button' className='btn green-btn' onClick={toggle}>Contact us if you need assistance</button>
                                    </div>
                                    <ContactUsModal modal={modal} toggle={toggle} />
                                </div>
                            </div>
                            <div className='col-12'>
                                <KeywordsRanking />
                            </div>
                            <div className='col-12'>
                                <KeywordTracking />
                            </div>
                            <div className='col-12'>
                                <Suggestedkeywords />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CopiedContent