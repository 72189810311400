import React from 'react'

function ErrorsQuotation() {
    
    const data = [
        {
            id: 1,
            quotation: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pharetra justo dignissim enim convallis scelerisque quam facilisis nunc eget. Ipsum posuere ut hendrerit habitant. Volutpat maecenas enim, vestibulum vitae egestas. Vel tortor viverra consectetur volutpat nisi nibh. Cursus vel pulvinar id aliquet quis nunc.",
            links: [
                "https://www.23wordofnet.com/article2",
                "https://www.23wordofnet.com/article2",
                "https://www.23wordofnet.com/article2"
            ]
        },
        {
            id: 2,
            quotation: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pharetra justo dignissim enim convallis scelerisque quam facilisis nunc eget. Ipsum posuere ut hendrerit habitant. Volutpat maecenas enim, vestibulum vitae egestas. Vel tortor viverra consectetur volutpat nisi nibh. Cursus vel pulvinar id aliquet quis nunc.",
            links: [
                "https://www.23wordofnet.com/article2",
                "https://www.23wordofnet.com/article2",
            ]
        },
        {
            id: 3,
            quotation: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pharetra justo dignissim enim convallis scelerisque quam facilisis nunc eget. Ipsum posuere ut hendrerit habitant. Volutpat maecenas enim, vestibulum vitae egestas. Vel tortor viverra consectetur volutpat nisi nibh. Cursus vel pulvinar id aliquet quis nunc.",
            links: [
                "https://www.23wordofnet.com/article2",
            ]
        }
    ]

    const renderData = data.length > 0 ? data.map(ele => 
    <div key={ele.id} className='quotation-error'>
        <p>“{ele.quotation}”</p>
        <div className='links'>
            <h6>Copied from:</h6>
            <ul>
                {ele.links.length > 0 && ele.links.map((link, index) => <li key={index}><a href='#top' className='btn'>{link}</a></li>)}
            </ul>
        </div>
    </div>) : null

    return (
        <>
            {renderData}
        </>
    )
}

export default ErrorsQuotation