import React, { useEffect, useState } from 'react'
import { FormControl, InputGroup } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Spinner } from 'reactstrap'
import TablePagination from '../../../components/common/Pagination'
import { competitorsBacklinks, listCompetitors, removeCompetitor } from '../../../store/actions'
import { checkIfLoading } from '../../../store/functions'
import { COMPETITORS_BACKLINKS, LIST_BACKLINKS, LIST_COMPETITORS } from '../../../store/webreputation/actionTypes'
import CompetitorsBacklinksTable from './components/CompetitorsBacklinksTable'
import CompetitorsTable from './components/CompetitorsTable'
import WebReputationNav from './components/Nav'

function WebReputation() {
  const { competitors, compDetails, backlinks, backlinksDetails, compLoading, bkLoading } = useSelector(state => ({
    competitors: state?.webreputation?.competitors?.list,
    compDetails: state?.webreputation?.competitors?.page_details,
    backlinks: state?.webreputation?.competitorBacklinks?.list,
    backlinksDetails: state?.webreputation?.competitorBacklinks?.page_details,
    compLoading: checkIfLoading(state, LIST_COMPETITORS),
    bkLoading: checkIfLoading(state, COMPETITORS_BACKLINKS)
  }))

  return (
    <div className='container-fluid web-reputation page'>
      <WebReputationNav />
      <div className='text-center my-5'>
      <h3>Increase your web reputation!</h3>
      <span>Analyze your competitors' strategy by finding out who talks about them and get them talked about You</span>
      </div>
      <div className='mt-2 mb-4'>
        <h4>1 Choose your competitors</h4>
        <span>We found 34 competitors for your domain</span>
      </div>
      <CompetitorsTable loading={compLoading} details={compDetails} competitors={competitors} />
      <hr />
      <h5 className='my-4'>2 See who speaks about your competitors select and contact your favourite, or add your own media target</h5>
      <CompetitorsBacklinksTable loading={bkLoading} details={backlinksDetails} competitors={competitors} backlinks={backlinks} />
      <hr />
      <div className='text-center'>
        <h5>3 Contact who is speaking about your competitors</h5>
        <div>Let us generate emails for you based on your area of expertise</div>
        <button className='btn click-btn orange-btn'>Generate your content</button>
      </div>
    </div>
  )
}

export default WebReputation