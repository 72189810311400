import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from 'react-router-dom'
import { checkIfLoading, useQuery } from "../../store/functions";
import { addWorkspace, listWorkspaceLanguages, listWorkspaceRegions, loginUser, verifyUser } from "../../store/actions";
import { Spinner } from "reactstrap";
import { ADD_WORKSPACE, LIST_WORKSPACE_LANGUAGES, LIST_WORKSPACE_REGIONS } from "../../store/workspace/actionTypes";

const CreateWorkspace = () => {
    const history = useNavigate()
    const dispatch = useDispatch()
    const [domain, setDomain] = useState(null)
    const [region, setRegion] = useState(null)
    const [language, setLanguage] = useState(null)
    const query = useQuery()

    const { loading, langLoading, regLoading, languages, regions } = useSelector(state => ({
        langLoading: checkIfLoading(state, LIST_WORKSPACE_LANGUAGES),
        regLoading: checkIfLoading(state, LIST_WORKSPACE_REGIONS),
        loading: checkIfLoading(state, ADD_WORKSPACE),
        languages: state?.workspace?.languages,
        regions: state?.workspace?.regions
    }))

    useEffect(() => {
        dispatch(listWorkspaceLanguages())
        dispatch(listWorkspaceRegions())
    }, [])

    const renderRegions = () => {
        if (regLoading) return <Spinner size="sm" className="m-auto text-center" color="light" />
        if (regions?.length > 0) {
            return regions?.map(reg => {
                return <option value={reg?.location_name}>{reg?.location_name}</option>
            })
        } else {
            return 'No region found'
        }
    }

    const renderLanguages = () => {
        if (langLoading) return <Spinner size="sm" className="m-auto text-center" color="light" />
        if (languages?.length > 0) {
            return languages?.map(reg => {
                return <option value={reg?.language_code}>{reg?.language_name}</option>
            })
        } else {
            return 'No language found'
        }
    }

    const addWorkspaceSubmit = () => {
        const data = { language, region, domain }
        dispatch(addWorkspace(data, history))
    }

    return (
        <form className="auth-form">
            <h3>Create your workspace</h3>
            <div className="form-group">
                <label>Your Website’s Domain</label>
                <input onChange={(e) => setDomain(e.target.value)} type="text" className="form-control" name="domain" />
            </div>
            <div className="form-group my-4">
                <label className="font-weight-bold">Primary Marketing Region</label>
                <select className="form-control custom-green-border " onChange={(e) => setRegion(e.target.value)}>
                    {renderRegions()}
                </select>
            </div>
            <div className="form-group">
                <label className="font-weight-bold">Primary Language</label>
                <select className="form-control custom-green-border " onChange={(e) => setLanguage(e.target.value)}>
                    {renderLanguages()}
                </select>
            </div>
            <button type="button" onClick={addWorkspaceSubmit} className="btn click-btn orange-btn btn-block">
                {
                    loading ? (
                        <Spinner
                            size="sm"
                            className="m-auto text-center"
                            color="light"
                        />
                    ) : (
                        "Continue"
                    )
                }
            </button>
            <div className="divider my-3">Or</div>
            <div className="text-center">
                <Link to={"/select-workspace"}><span className="text-dark text-unstyle"><b>Select a workspace</b></span></Link>
            </div>
        </form>
    );
}

export default CreateWorkspace;