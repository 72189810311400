import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalHeader, ModalBody, Spinner } from 'reactstrap';
import { ADD_PIPELINE } from '../../../../store/salesManagement/actionTypes';
import { checkIfLoading } from '../../../../store/functions';
import { addPipeline } from '../../../../store/salesManagement/actions';
import DateTimePicker from './DateTimePicker';
import { addReminder, updateReminder } from '../../../../store/actions';
import { ADD_REMINDER, UPDATE_REMINDER } from '../../../../store/reminders/actionTypes';

function AddReminderMenu({ open, toggle, setReminder, reminder }) {
    // ** states
    const dispatch = useDispatch()

    const [form, setForm] = useState({
        start: new Date(Date.now() + (1000 * 60 * 60)),
        end: new Date(Date.now() + (1000 * 60 * 60 * 2)),
        summary: null,
        location: null,
        description: null
    })

    useEffect(() => {
        if (reminder) {
            console.log(reminder)
            setForm({
                ...reminder,
                start: new Date(reminder?.start),
                end: new Date(reminder?.end),
            })
        }
    }, [reminder])

    const { addLoading, updateLoading } = useSelector(state => ({
        addLoading: checkIfLoading(state, ADD_REMINDER),
        updateLoading: checkIfLoading(state, UPDATE_REMINDER)
    }))

    const onInputChange = (key, value) => {
        setForm({
            ...form,
            [key]: value
        })
    }

    const submitReminder = () => {
        const data = form
        if (reminder) {
            dispatch(updateReminder(reminder?._id, data, () => {
                toggle()
                setReminder(null)
            }))
        } else {
            dispatch(addReminder(data, toggle))
        }
    }

    return (
        <div className={`add-reminder ${open ? 'show' : ""}`}>
            <div className='reminder-content'>
                <div className='mt-3 d-block d-flex justify-content-end'>
                    <img src='/assets/images/icons/Group 372.svg' onClick={() => {
                        toggle()
                        setReminder(null)
                    }} style={{ cursor: "pointer" }} />
                </div>
                <div className='mt-4'>
                    <input className='form-control' value={form.summary} onChange={e => onInputChange('summary', e.target.value)} placeholder='Enter summary' />
                    <hr />
                    <div className='row align-items-center'>
                        <div className='col-md-3'>
                            <label>Start Time</label>
                        </div>
                        <div className='col-md-9 datetime'>
                            <DateTimePicker date={form.start} setDate={(d) => onInputChange('start', d)} />
                        </div>
                    </div>
                    <div className='row align-items-center mt-1'>
                        <div className='col-md-3'>
                            <label>End Time</label>
                        </div>
                        <div className='col-md-9 datetime'>
                            <DateTimePicker date={form.end} setDate={(d) => onInputChange('end', d)} />
                        </div>
                    </div>
                    <div className='row align-items-center mt-3'>
                        <div className='col-md-3'>
                            <label>Location</label>
                        </div>
                        <div className='col-md-9'>
                            <input className='form-control' value={form.location} onChange={e => onInputChange('location', e.target.value)} placeholder='Enter location' />
                        </div>
                    </div>
                    <div className='row align-items-center mt-3'>
                        <div className='col-md-3'>
                            <label>Add participant</label>
                        </div>
                        <div className='col-md-9'>
                            <input className='form-control' onChange={e => onInputChange('participant', e.target.value)} placeholder='Write Email Address' />
                        </div>
                    </div>
                    <div className='mt-3'>
                        <label className='mb-2'>Description</label>
                        <textarea placeholder='Add Description' value={form.description} onChange={e => onInputChange('description', e.target.value)} className='form-control'></textarea>
                    </div>
                    <div className='mt-3'>
                        <button className='btn click-btn green-btn' onClick={submitReminder}>
                            {
                                addLoading || updateLoading ? <Spinner
                                    size="sm"
                                    className="m-auto text-center"
                                    color="light"
                                /> : "Save"
                            }
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddReminderMenu