import React from 'react'
import { FormControl, InputGroup } from 'react-bootstrap'

const ConnectionsTab = () => {
    return (
        <>
            <div>New Automation</div>
        </>
    )
}

export default ConnectionsTab