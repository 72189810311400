import React from 'react'

function SmallTable({columns, data}) {

    const renderColumns = columns.map((ele, index) => 
        <>
            <th key={index}>{ele.title}</th>
        </>
    )

    const renderData = data.map(ele => 
        <tr key={ele.id}>
            {columns.map((e, index) => 
                <td key={index}>{ele[e.selector]}</td>
            )}
        </tr>
    )

    return (
        <table className='table'>
            <thead>
                <tr>
                    {renderColumns}
                </tr>
            </thead>
            <tbody>
                {renderData}
            </tbody>
        </table>
    )
}

export default SmallTable