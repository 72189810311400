import {
  GET_CONTENT_ANALYSIS,
  GET_CONTENT_ANALYSIS_SUCCESS,
  UPDATE_CONTENT_ANALYSIS,
  UPDATE_CONTENT_ANALYSIS_SUCCESS,
  CONTENT_ANALYSIS_PAGE_KEYWORDS,
  CONTENT_ANALYSIS_PAGE_KEYWORDS_SUCCESS
} from "./actionTypes"

export const getContentAnalysis = (id) => {
  return {
    type: GET_CONTENT_ANALYSIS,
    payload: { id },
  }
}

export const getContentAnalysisSuccess = (data) => {
  return {
    type: GET_CONTENT_ANALYSIS_SUCCESS,
    payload: data
  }
}

export const updateContentAnalysis = (user) => {
  return {
    type: UPDATE_CONTENT_ANALYSIS,
    payload: { user }
  }
}

export const updateContentAnalysisSuccess = (data) => {
  return {
    type: UPDATE_CONTENT_ANALYSIS_SUCCESS,
    payload: data
  }
}

export const ContentAnalysisPageKeywords = (user, history) => {
  return {
    type: CONTENT_ANALYSIS_PAGE_KEYWORDS,
    payload: { user, history },
  }
}

export const ContentAnalysisPageKeywordsSuccess = (data) => {
  return {
    type: CONTENT_ANALYSIS_PAGE_KEYWORDS_SUCCESS,
    payload: data
  }
}