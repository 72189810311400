import api from '../apis/api'
import { tokenConfig } from '../config'

export const ListPipelinesAPI = (state) => {
  return new Promise((res, rej) => {
    api.get(`/pipeline`, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const GetPipelineAPI = (id, state) => {
  return new Promise((res, rej) => {
    api.get(`/pipeline/${id}`, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const AddPipelineAPI = (body, state) => {
  return new Promise((res, rej) => {
    api.post(`/pipeline`, body, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const UpdatePipelineAPI = (id, body, state) => {
  return new Promise((res, rej) => {
    api.put(`/pipeline/${id}`, body, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const DeletePipelineAPI = (id, state) => {
  return new Promise((res, rej) => {
    api.delete(`/pipeline/${id}`, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const AddColumnAPI = (id, body, state) => {
  return new Promise((res, rej) => {
    api.post(`/pipeline/${id}/column`, body, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const UpdateColumnAPI = (id, body, state) => {
  return new Promise((res, rej) => {
    api.put(`/pipeline/column/${id}`, body, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const DeleteColumnAPI = (id, state) => {
  return new Promise((res, rej) => {
    api.delete(`/pipeline/column/${id}`, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const AddTabAPI = (id, body, state) => {
  return new Promise((res, rej) => {
    api.post(`/pipeline/column/${id}/tab`, body, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const UpdateTabAPI = (id, body, state) => {
  return new Promise((res, rej) => {
    api.put(`/pipeline/tab/${id}`, body, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const DeleteTabAPI = (id, state) => {
  return new Promise((res, rej) => {
    api.delete(`/pipeline/tab/${id}`, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}