import React, { useEffect } from 'react'
import Header from './Header'
import TrafficHistory from './TrafficHistory'
import PageViews from './PageViews'
import BounceRate from './BounceRate'
import Visitors from './Visitors'
import OrganicSearch from './OrganicSearch'
import SourceTraffic from './SourceTraffic'
import { useDispatch, useSelector } from 'react-redux'
import { getDashboard } from '../../../store/dashboard/actions'
import { checkIfLoading } from '../../../store/functions'
import { LIST_ANALYTICS } from '../../../store/auth/actionTypes'
import { GET_DASHBOARD } from '../../../store/dashboard/actionTypes'
import CountryTraffic from './CountryTraffic'

function index() {
    const dispatch = useDispatch()

    const { workspace, loading, dashboard } = useSelector(state => ({
        workspace: state?.workspace?.workspace,
        loading: checkIfLoading(state, GET_DASHBOARD),
        dashboard: state?.dashboard?.data
    }))

    useEffect(() => {
        dispatch(getDashboard(workspace?._id))
    }, [workspace])

    return (
        <div className='dashboard'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-12'>
                        <Header workspace={workspace} />
                    </div>
                </div>
                {
                    loading || dashboard && Object.entries(dashboard).length !== 0 ? <><div className='row'>
                    <div className='col-xl-6'>
                        <TrafficHistory data={dashboard?.traffic?.last5months} loading={loading} />
                    </div>
                    <div className='col-xl-6'>
                        <div className='row'>
                            <div className='col-xl-6'>
                                <PageViews total={dashboard?.totalVisits} loading={loading} />
                            </div>
                            <div className='col-xl-6'>
                                <BounceRate total={dashboard?.bounceRate} data={dashboard?.last5daysBounceRate} loading={loading} />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12'>
                                <Visitors
                                    unique={dashboard?.uniqueVisits}
                                    timeOnSiteAvg={dashboard?.timeOnSiteAvg}
                                    pageViewsAvg={dashboard?.pageViewsAvg}
                                    loading={loading}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-xl-6'>
                        <div className='row'>
                            <div className='col-xl-6'>
                                <OrganicSearch loading={loading} data={dashboard?.topKeywords} />
                            </div>
                            <div className='col-xl-6'>
                                <SourceTraffic loading={loading} data={dashboard?.traffic?.sources} />
                            </div>
                        </div>
                    </div>
                    <div className='col-xl-6'>
                        <CountryTraffic loading={loading} data={dashboard?.traffic?.contries} total={dashboard?.traffic?.value} />
                    </div>
                </div></> : "No data to be shown in this page"
                }
            </div>
        </div>
    )
}

export default index