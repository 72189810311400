import React, { useEffect } from 'react'
import { Col, FormControl, InputGroup, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Spinner } from 'reactstrap'
import TablePagination from '../../../components/common/Pagination'
import { competitorsBacklinks, listBacklinks, listCompetitors, removeCompetitor } from '../../../store/actions'
import { checkIfLoading } from '../../../store/functions'
import { LIST_BACKLINKS, LIST_COMPETITORS } from '../../../store/webreputation/actionTypes'
import BacklinksOverTime from './components/BacklinksOvertime'
import BacklinksTable from './components/BacklinksTable'
import WebReputationNav from './components/Nav'
import NewLostBacklinks from './components/NewLostBacklinks'

function CompetitorsList() {
    const dispatch = useDispatch()

    const { competitors, compLoading } = useSelector(state => ({
        competitors: state?.webreputation?.competitors?.list,
        compLoading: checkIfLoading(state, LIST_COMPETITORS)
    }))

    useEffect(() => {
        dispatch(listCompetitors())
    }, [])

    const renderCompetitors = () => {
        if (compLoading) return <Spinner size="sm" className="m-auto text-center" color="success" />
        if (competitors?.length > 0) {
            return competitors?.map(comp => {
                return <li>
                    <span>{comp?.url}</span>
                    <button onClick={() => dispatch(removeCompetitor(comp?._id))} className='btn btn-link m-0 p-0 green float-right'>Remove</button>
                </li>
            })
        } else {
            return <div>No competitor found</div>
        }
    }

    return (
        <div className='container-fluid web-reputation page'>
            <WebReputationNav />
            <div className='mt-5'>
                <h4>List of your competitors</h4>
                <div className='d-flex align-items-center justify-content-between'>
                    <div className='lead'>{competitors?.length} competitors for your domain</div>
                    <button className='btn click-btn green-btn py-2 px-4'>Add new competitor</button>
                </div>
                <div className='row justify-content-between align-items-end'>
                    <div className='col-md-4'>
                        <InputGroup className="mb-3">
                            <InputGroup.Text id="basic-addon1" className='bg-transparent'><i class="fas fa-search"></i></InputGroup.Text>
                            <FormControl
                                placeholder="Search"
                                aria-label="Search"
                                aria-describedby="basic-addon1"
                            />
                        </InputGroup>
                    </div>
                    <div className='col-md-6 text-right'>
                        <span><i class="fas fa-sm mr-2 text-muted fa-sync"></i> Update</span>
                    </div>
                </div>
                <ul className='list-unstyled competitors-list mt-3'>
                    {renderCompetitors()}
                </ul>
            </div>
        </div>
    )
}

export default CompetitorsList