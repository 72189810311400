import {
  GET_DASHBOARD,
  GET_DASHBOARD_SUCCESS,
  UPDATE_DASHBOARD,
  UPDATE_DASHBOARD_SUCCESS
} from "./actionTypes"

export const getDashboard = (id) => {
  return {
    type: GET_DASHBOARD,
    payload: { id },
  }
}

export const getDashboardSuccess = (data) => {
  return {
    type: GET_DASHBOARD_SUCCESS,
    payload: data
  }
}

export const updateDashboard = (user, history) => {
  return {
    type: UPDATE_DASHBOARD,
    payload: { user, history }
  }
}

export const updateDashboardSuccess = (data) => {
  return {
    type: UPDATE_DASHBOARD_SUCCESS,
    payload: data
  }
}