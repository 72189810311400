import React, { useEffect, useState } from 'react'
import ErrorsChart from '../../../components/analysisComponents.js/ErrorsChart'
import Issues from '../../../components/analysisComponents.js/Issues'
import SideDropdown from '../../../components/analysisComponents.js/SideDropdown'
import ContactUsModal from '../../../components/modals/ContactUsModal'
import { useDispatch, useSelector } from 'react-redux'
import { getContentAnalysis } from '../../../store/contentAnalysis/actions'
import { GET_CONTENT_ANALYSIS } from '../../../store/contentAnalysis/actionTypes'
import { checkIfLoading } from '../../../store/functions'
import { Spinner } from 'reactstrap'

function ContentAnalysis() {
  // ** states
  const [modal, setModal] = useState(false);

  const dispatch = useDispatch()

  const { loading, workspace, contentAnalysis } = useSelector(state => ({
    loading: checkIfLoading(state, GET_CONTENT_ANALYSIS),
    workspace: state?.workspace?.workspace,
    contentAnalysis: state?.contentAnalysis?.data
  }))

  useEffect(() => {
    dispatch(getContentAnalysis(workspace?._id))
  }, [])

  // ** function to handle modal toggle
  const toggle = () => setModal(!modal);

  return (
    <div className='analysis'>
      <div className='container-fluid'>
        <div className='row justify-content-center'>
          <div className='col-11 text-center'>
            <SideDropdown pages={contentAnalysis.pages} />
            <p className='description'>Every good SEO strategy needs to be done page by page. For This reason we indicate deeper analysis in the specific pages</p>
          </div>
          <div className='col-xl-10'>
            {
              loading ? <div className='text-center'><Spinner size="xl" className="m-auto text-center" color="success" /></div> :
                <div className='row'>
                  <div className='col-xl-6'>
                    <Issues data={contentAnalysis} />
                  </div>
                  <div className='col-xl-6'>
                    <div className='errors-quotation-chart'>
                      <div className='card'>
                        <ErrorsChart data={contentAnalysis} />
                      </div>
                      <p className='fix-hint'>Fixed your issues? Analyze again to see if everything is fixed.</p>
                      <div className='buttons'>
                        <button type='button' className='btn click-btn orange-btn'>Analyze website</button>
                        <button type='button' className='btn click-btn green-btn' onClick={toggle}>Contact us if you need assistance</button>
                      </div>
                      <ContactUsModal modal={modal} toggle={toggle} />
                    </div>
                  </div>
                </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContentAnalysis