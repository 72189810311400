import React from 'react'
import AreaChart from '../../../../components/charts/AreaChart'

function KeywordTracking() {

  const chart = [
    {
      label: "Sep 24",
      value: 4.5
    },
    {
      label: "Sep 26",
      value: 2
    },
    {
      label: "Sep 28",
      value: 6
    },
    {
      label: "Sep 30",
      value: 5
    },
    {
      label: "Oct 2",
      value: 3
    },
    {
      label: "Oct 4",
      value: 7
    },
    {
      label: "Oct 6",
      value: 2
    },
    {
      label: "Oct 8",
      value: 5
    },
    {
      label: "Oct 10",
      value: 1
    },
    {
      label: "Oct 12",
      value: 4.5
    },
    {
      label: "Oct 14",
      value: 6
    }
  ]

  return (
    <div className='keywords-tracking row'>
      <div className='col-12'>
        <h2>Keyword Tracking</h2>
        <p className='description'>See how each keyword positioned for this page ranked time by time and take action to improve it</p>
        <div className='tabs'>
          <a href='#top' className='btn active'>behance</a>
          <a href='#top' className='btn'>design</a>
          <a href='#top' className='btn'>art</a>
          <a href='#top' className='btn'>photo</a>
          <a href='#top' className='btn'>cinematography</a>
        </div>
        <div className='card'>
          <AreaChart height={280} name="Keyword Tracking" data={chart} curveType="straight" showYaxis={true} />
        </div>
      </div>
    </div>
  )
}

export default KeywordTracking