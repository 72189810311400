import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const MarketingNav = ({ tab, setTab }) => {
    const location = useLocation()
    const navigate = useNavigate()

    return (
        <div className='marketing-nav mt-5'>
            <div className='row'>
                <div className='col-md-12 d-flex'>
                    <ul className='list-group text-center list-group-horizontal-lg'>
                        <li onClick={() => setTab('new-automation')} className={`mr-3 ${tab === 'new-automation' ? 'active' : ""}`}><span><i class="fas mr-1 fa-envelope"></i> Start New Automation</span></li>
                        <li onClick={() => setTab('email-builder')} className={`mr-3 ${tab === 'email-builder' ? 'active' : ""}`}><span><i class="fas mr-1 fa-envelope"></i> Email Builder</span></li>
                        <li onClick={() => setTab('automations')} className={`mr-3 ${tab === 'automations' ? 'active' : ""}`}><span><i class="fas mr-1 fa-envelope"></i> Your Automations</span></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default MarketingNav