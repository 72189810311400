import React from 'react'
import { Spinner } from 'reactstrap'
import BarChart from '../../../components/charts/BarChart'

function PageViews({ total, loading }) {
  return (
    <div className='page-views analytics card'>
      {
        loading ? <Spinner size="md" className="m-auto text-center" color="success" /> : <>
          <h2 className='number'>{(total)?.toLocaleString(undefined, { maximumFractionDigits: 2 })} <i className="fas fa-arrow-up text-success ml-2"></i></h2>
          <p>Page Views</p>
          <BarChart name="Page Views" />
        </>
      }
    </div>
  )
}

export default PageViews