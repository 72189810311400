import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from 'react-router-dom'
import { checkIfLoading, useQuery } from "../../store/functions";
import { getUserData, listWorkspaceRegions } from "../../store/actions";
import { Spinner } from "reactstrap";
import { LIST_WORKSPACE_REGIONS } from "../../store/workspace/actionTypes";
import StripePayment from "../../components/common/PaymentBox";

const PaySubscription = () => {
    const history = useNavigate()
    const dispatch = useDispatch()
    const [billingData, setBillingData] = useState({
        name: null,
        company: null,
        address: {
            line1: null,
            country: null,
            city: null,
            postal_code: null
        },
        phone: null,
    })

    const [loading, setLoading] = useState(false)

    const { regions, regLoading, plan, user } = useSelector(state => ({
        regions: state?.workspace?.regions,
        regLoading: checkIfLoading(state, LIST_WORKSPACE_REGIONS),
        plan: state?.subscription?.plan,
        user: state?.auth?.user
    }))

    useEffect(() => {
        if(user) {
            setBillingData({
                name: user?.fullName,
                address: {
                    line1: user.address.line1,
                    country: user.address.country,
                    city: user.address.city,
                    postal_code: user.address?.postal_code,
                },
                phone: user?.phone
            })
        }
    }, [user])

    const paymentSubmitBtn = React.useRef(null);

    const handleClick = event => {
        setLoading(true)
        paymentSubmitBtn.current.click();
    };

    const inputChange = (key, value) => {
        setBillingData({
            ...billingData,
            [key]: value
        })
    }

    useEffect(() => {
        if(!plan) history('/select-plan')
        dispatch(listWorkspaceRegions())
        dispatch(getUserData())
    }, [])

    const renderRegions = () => {
        if(regLoading) return <option>Loading...</option>
        if(regions && regions?.length > 0) {
            return regions?.map(reg => (
                <option value={reg?.country_iso_code}>{reg?.location_name}</option>
            ))
        } else {
            return <option>No countries found</option>
        }
    }

    return (
        <div className="container pay-subscription">
            <div className="row">
                <div className="col-md-6 mb-3">
                    <h4>Card information</h4>
                    <span className="text-muted">Indicate details of the card from which money will be debited.</span>
                    <div className="card mt-3">
                        <StripePayment setLoading={setLoading} paymentSubmitBtn={paymentSubmitBtn} plan={plan} billing={billingData} />
                    </div>
                </div>
                <div className="col-md-6">
                    <h4 className="mb-3">Billing information</h4>
                    <div className="card">
                        <div className="row mb-3">
                            <div className="col-md-6">
                                <label>Complete name</label>
                                <input onChange={(e) => inputChange('name', e.target.value)} defaultValue={billingData.name} className="form-control custom-border" placeholder="Your name and surname" />
                            </div>
                            <div className="col-md-6">
                                <label>Company name</label>
                                <input onChange={(e) => inputChange('company', e.target.value)} defaultValue={billingData.company} className="form-control custom-border" placeholder="Your company name" />
                            </div>
                        </div>
                        <div className="mb-3">
                            <label>Address</label>
                            <input onChange={(e) => inputChange('address.line1', e.target.value)} defaultValue={billingData.address.line1} className="form-control custom-border" placeholder="Your comapany Address" />
                        </div>
                        <div className="mb-3">
                            <label>Country</label>
                            <select onChange={(e) => inputChange('address.country', e.target.value)} defaultValue={billingData.address.country} className="form-control custom-border">
                                {renderRegions()}
                            </select>
                        </div>
                        <div className="row mb-3">
                            <div className="col-md-6">
                                <label>City</label>
                                <input onChange={(e) => inputChange('address.city', e.target.value)} defaultValue={billingData.address.city} className="form-control custom-border" placeholder="New York, NY" />
                            </div>
                            <div className="col-md-6">
                                <label>ZIP code</label>
                                <input onChange={(e) => inputChange('address.postal_code', e.target.value)} defaultValue={billingData.address.postal_code} className="form-control custom-border" placeholder="12345" />
                            </div>
                        </div>
                        {/* <div className="mb-3">
                            <label>VAT (if european insert the country code to deduct 22%)</label>
                            <input onChange={(e) => inputChange('company', e.target.value)} className="form-control custom-border" placeholder="Your  company VAT number" />
                        </div> */}
                        <div className="mb-3">
                            <label>Phone number</label>
                            <input onChange={(e) => inputChange('phone', e.target.value)} defaultValue={billingData.phone} className="form-control custom-border" placeholder="+123 456 789 1011" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center mt-4">
                <div className="col-md-3">
                    <button disabled={loading} onClick={handleClick} className="btn click-btn green-btn btn-block">
                        {
                            loading ? <Spinner className="ml-2" size="sm" color="light" /> : `Pay ${plan?.amount}`
                        }
                    </button>
                </div>
            </div>
        </div>
    );
}

export default PaySubscription;