import React, { useRef } from 'react'
import { useNavigate } from 'react-router-dom'

const MessagePage = ({ url }) => {
    const history = useNavigate()

    return (
        <div className='text-center'>
            <i class="far fa-check-circle text-green mb-3" style={{ fontSize: "80px" }}></i>
            <h4 className='mb-4'>User Subscribed Successfully</h4>
            <button onClick={() => history(url)} className='btn click-btn green-btn'>Done</button>
        </div>
    )
}

export default MessagePage