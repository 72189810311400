import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { generateIdea } from '../../../../store/contentGenerator/actions'

function index() {
  const dispatch = useDispatch()
  const [data, setData] = useState({})
  const languages = [
    'English (US)',
    'English (UK)',
    'French',
    'Spanish',
    'German',
    "Italian",
    "Dutch",
    'Portuguese',
    'Portuguese (BR)',
    "Swedish",
    "Norwegian",
    "Danish",
    "Finnish",
    "Romanian",
    "Czech",
    "Slovak",
    "Slovenian",
    "Hungarian",
    "Polish",
    "Turkish",
    "Russian",
    "Hindi",
    "Thai",
    "Japanese",
    "Chinese (Simplified)",
    "Korean",
    "Indonesian"
  ]
  
  const onDataUpdate = (e, type) => {
    setData({
      ...data,
      [type]: e.target.value
    })
  }
  
  const submitGenerateIdea = () => {
    console.log(data)
    // dispatch(generateIdea())
  }

  const renderLanguages = () => {
    return languages?.map(lang => {
      return <option value={lang}>{lang}</option>
    })
  }

  return (
    <div className='blog-post container'>
      <div className='row'>
        <div className='col-xl-5'>
          <div className='card form'>
            <h3><img src={`${process.env.PUBLIC_URL}/assets/images/icons/Group 459.png`} alt="" /> Blog Paragraphs</h3>
            <p className='description'>Lorem ipsum dolor sit amet, consectetur adipiscing elit..</p>
            <form>
              <div className='row'>
                <div className='col select-con'>
                  <label>Language</label>
                  <select onChange={e => onDataUpdate(e, 'language')} className='form-control'>
                    {renderLanguages()}
                  </select>
                  <i className="fas fa-angle-down"></i>
                </div>
                <div className='col select-con'>
                  <label>Creativity <i className="fas fa-question-circle"></i></label>
                  <select onChange={e => onDataUpdate(e, 'creativity')} className='form-control'>
                    <option>Regular</option>
                    <option>High</option>
                  </select>
                  <i className="fas fa-angle-down"></i>
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <label>Product Name*</label>
                  <input onChange={e => onDataUpdate(e, 'product')} type='text' className='form-control' placeholder='Netflix, Spotify, UI' />
                </div>
                <div className='col'>
                  <label>Audience*</label>
                  <input onChange={e => onDataUpdate(e, 'audience')} type='text' className='form-control' placeholder='Freelancers' />
                </div>
              </div>
              <div>
                <label>Product Description*</label>
                <textarea onChange={e => onDataUpdate(e, 'description')} className='form-control' placeholder='Explain here to the AI what your product (or service) is about. Rewrite to get diferent results.'></textarea>
                <button type='button' onClick={submitGenerateIdea} className='btn'>Generate</button>
              </div>
            </form>
          </div>
          <p className='increasing-quality'>Getting low quality results? Increase the creativity or write a better description.</p>
        </div>
        <div className='col-xl-7'>
          <div className='card favourites'>
            <div className='header'>
              <div>
                <button type='button' className='btn new-btn'>New</button>
                <button type='button' className='btn favourites-btn'>Favourites <span>(0)</span></button>
              </div>
              <div>
                <button type='button' className='btn select-all-btn'>Select All</button>
              </div>
            </div>
            <div className='body'>
              <p>Generate new ideas by adding info on the left side. Press “Generate” to generate copy.</p>
              <img src={`${process.env.PUBLIC_URL}/assets/images/icons/Group 459.png`} alt="" />
              <h3>Blog Paragraphs</h3>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vel quis odio placerat nibh purus luctus. Sagittis id risus amet odio nunc tincidunt dui. At arcu et, dui, quam gravida lacinia imperdiet.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default index