import {
  LIST_COMPETITORS,
  LIST_COMPETITORS_SUCCESS,
  LIST_BACKLINKS,
  LIST_BACKLINKS_SUCCESS,
  ADD_COMPETITOR,
  ADD_COMPETITOR_SUCCESS,
  COMPETITORS_BACKLINKS,
  COMPETITORS_BACKLINKS_SUCCESS,
  CREATE_EMAIL,
  CREATE_EMAIL_SUCCESS,
  REMOVE_COMPETITOR,
  REMOVE_COMPETITOR_SUCCESS,
  LIST_CAMPAIGNS,
  LIST_CAMPAIGNS_SUCCESS,
  GET_FEEDBACK,
  GET_FEEDBACK_SUCCESS,
  GET_BACKLINKS_DATA,
  GET_BACKLINKS_DATA_SUCCESS,
  GET_COMPETITOR_BACKLINKS,
  GET_COMPETITOR_BACKLINKS_SUCCESS
} from "./actionTypes"

export const listCompetitors = (query) => {
  return {
    type: LIST_COMPETITORS,
    payload: { query },
  }
}

export const listCompetitorsSuccess = (data) => {
  return {
    type: LIST_COMPETITORS_SUCCESS,
    payload: data
  }
}

export const getBacklinksData = () => {
  return {
    type: GET_BACKLINKS_DATA,
    payload: {},
  }
}

export const getBacklinksDataSuccess = (data) => {
  return {
    type: GET_BACKLINKS_DATA_SUCCESS,
    payload: data
  }
}

export const listBacklinks = (query) => {
  return {
    type: LIST_BACKLINKS,
    payload: { query },
  }
}

export const listBacklinksSuccess = (data) => {
  return {
    type: LIST_BACKLINKS_SUCCESS,
    payload: data
  }
}

export const addCompetitor = (url, toggle) => {
  return {
    type: ADD_COMPETITOR,
    payload: { url, toggle },
  }
}

export const addCompetitorSuccess = (data) => {
  return {
    type: ADD_COMPETITOR_SUCCESS,
    payload: data
  }
}

export const competitorsBacklinks = (query) => {
  return {
    type: COMPETITORS_BACKLINKS,
    payload: { query },
  }
}

export const competitorsBacklinksSuccess = (data) => {
  return {
    type: COMPETITORS_BACKLINKS_SUCCESS,
    payload: data
  }
}

export const getCompetitorBacklinks = (query) => {
  return {
    type: GET_COMPETITOR_BACKLINKS,
    payload: { query },
  }
}

export const getCompetitorBacklinksSuccess = (data) => {
  return {
    type: GET_COMPETITOR_BACKLINKS_SUCCESS,
    payload: data
  }
}

export const createEmail = (user, history) => {
  return {
    type: CREATE_EMAIL,
    payload: { user, history },
  }
}

export const createEmailSuccess = (data) => {
  return {
    type: CREATE_EMAIL_SUCCESS,
    payload: data
  }
}

export const removeCompetitor = (id) => {
  return {
    type: REMOVE_COMPETITOR,
    payload: { id },
  }
}

export const removeCompetitorSuccess = (data) => {
  return {
    type: REMOVE_COMPETITOR_SUCCESS,
    payload: data
  }
}

export const getFeedback = () => {
  return {
    type: GET_FEEDBACK,
    payload: {},
  }
}

export const getFeedbackSuccess = (data) => {
  return {
    type: GET_FEEDBACK_SUCCESS,
    payload: data
  }
}

export const listCampaigns = (user, history) => {
  return {
    type: LIST_CAMPAIGNS,
    payload: { user, history },
  }
}

export const listCampaignsSuccess = (data) => {
  return {
    type: LIST_CAMPAIGNS_SUCCESS,
    payload: data
  }
}