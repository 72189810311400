import React, { useEffect, useState } from "react";
import Tabs from "./Tabs";
import AllKeyWords from "./contents/AllKeyWords";
import CommonKeyWords from "./contents/CommonKeyWords";
import Discovering from "./contents/Discovering";
import Posts from "./contents/Posts";
import Reviews from "./contents/Reviews";
import { useSelector } from "react-redux";
import { GET_BUSINESS } from "../../../../store/localseo/actionTypes";
import { checkIfLoading } from "../../../../store/functions";
import { Spinner } from "reactstrap";

function index() {
  // ** states
  const [tab, setTab] = useState(1)

  const { loading, business } = useSelector(state => ({
    business: state?.localseo?.business,
    loading: checkIfLoading(state, GET_BUSINESS)
  }))

  const [selectedContent, setSelectedContent] = useState(<AllKeyWords data={business?.keywords} />)
  console.log(business)

  // ** function to select content
  const handleSelectContent = (e) => {
    switch (e.target.value) {
      case "all":
        setTab(1)
        setSelectedContent(<AllKeyWords data={business?.keywords} loading={loading} />)
        break;
      case "common":
        setTab(2)
        setSelectedContent(<CommonKeyWords data={business?.keywordsCommon} loading={loading} />)
        break;
      case "posts":
        setTab(3)
        setSelectedContent(<Posts data={business?.posts} loading={loading} />)
        break;
      case "reviews":
        setTab(4)
        setSelectedContent(<Reviews data={business?.reviews} loading={loading} />)
        break;
      case "discovering":
        setTab(5)
        setSelectedContent(<Discovering data={business?.keywords} loading={loading} />)
        break;
    }
  }

  const address = encodeURI(business?.address?.address + ',' + business?.address?.city + ',' + business?.address?.country_code)

  return (
    <div className="detailed-report">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <h2>Detailed report about Your business</h2>
            <p>Analyze your keywords, Positioned keywords, Posts and much more</p>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card">
              {
                loading ? <Spinner size="sm" className="m-auto text-center" color="success" /> : <iframe src={`https://maps.google.com/maps?q=${address}&t=&z=13&ie=UTF8&iwloc=&output=embed`} width="100%" height="295" style={{ border: "0", marginBottom: "50px" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              }
              <Tabs business={business} handleSelectContent={handleSelectContent} tab={tab} />
              {
                loading ? <Spinner size="sm" className="m-auto text-center" color="success" /> : selectedContent
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default index;
