import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';

const Feedback = () => { 
  // ** states
  const [modal, setModal] = useState(false);

  // ** function to handle toggle modal
  const toggle = () => setModal(!modal);

  return (
    <>
        <Button className='feedback-btn' onClick={toggle}>
            <img src={`${process.env.PUBLIC_URL}/assets/images/feedback.svg`} alt="feedback-btn" />
        </Button>
        <Modal className='feedback-modal' isOpen={modal} toggle={toggle} centered={true}>
            <ModalHeader toggle={toggle}></ModalHeader>
            <ModalBody>
                <h2>Would you like to have a specific feature in SEO Suite?</h2>
                <textarea class="form-control" placeholder="Please, write your suggestion"></textarea>
                <button class="btn">Submit feature request</button>        
            </ModalBody>
        </Modal>
    </>
  );
}

export default Feedback;