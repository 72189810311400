import api from '../apis/api'
import { tokenConfig } from '../config'

export const GetContentAnalysisAPI = (query, state) => {
  return new Promise((res, rej) => {
    api.get(`/content-analysis/data?${query ? query : ''}`, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const UpdateContentAnalysisAPI = (query, state) => {
  return new Promise((res, rej) => {
    api.get(`/content-analysis/data?${query ? query : ''}`, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const GetContentAnalysisPageKeywordsAPI = (body, state) => {
  return new Promise((res, rej) => {
    api.post(`/content-analysis/page/keywords`, body, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}