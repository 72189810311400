import React from 'react'
import BarChartPositiveNegative from '../../../../components/charts/BarChartPN'

function NewLostBacklinks() {
    return (
        <div className='page-views card'>
            <h3>New & Lost backlinks</h3>
            <BarChartPositiveNegative />
        </div>
    )
}

export default NewLostBacklinks