import React, { useState } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

const DateTimePicker = ({ date, setDate, showTimeSelect = true }) => {
    const filterPassedTime = (time) => {
        const currentDate = new Date();
        const selectedDate = new Date(time);

        return currentDate.getTime() < selectedDate.getTime();
    };

    return (
        <DatePicker
            selected={date}
            onChange={(date) => setDate(date)}
            showTimeSelect={showTimeSelect}
            className="cursor-pointer"
            filterTime={filterPassedTime}
            dateFormat="MMMM d, yyyy h:mm aa"
        />
    );
}

export default DateTimePicker