export const LIST_BILLINGS = 'LIST_BILLINGS'
export const LIST_BILLINGS_SUCCESS = 'LIST_BILLINGS_SUCCESS'

export const GET_SUBSCRIPTION = 'GET_SUBSCRIPTION'
export const GET_SUBSCRIPTION_SUCCESS = 'GET_SUBSCRIPTION_SUCCESS'

export const SELECT_PLAN = 'SELECT_PLAN'
export const SELECT_PLAN_SUCCESS = 'SELECT_PLAN_SUCCESS'

export const CHANGE_SUBSCRIPTION = 'CHANGE_SUBSCRIPTION'
export const CHANGE_SUBSCRIPTION_SUCCESS = 'CHANGE_SUBSCRIPTION_SUCCESS'

export const GET_PAYMENT_METHOD = 'GET_PAYMENT_METHOD'
export const GET_PAYMENT_METHOD_SUCCESS = 'GET_PAYMENT_METHOD_SUCCESS'

export const CHANGE_PAYMENT_METHOD = 'CHANGE_PAYMENT_METHOD'
export const CHANGE_PAYMENT_METHOD_SUCCESS = 'CHANGE_PAYMENT_METHOD_SUCCESS'

export const LIST_PLANS = 'LIST_PLANS'
export const LIST_PLANS_SUCCESS = 'LIST_PLANS_SUCCESS'

export const PAY_SUBSCRIPTION = 'PAY_SUBSCRIPTION'
export const PAY_SUBSCRIPTION_SUCCESS = 'PAY_SUBSCRIPTION_SUCCESS'