import {
  GET_CONTENT_ANALYSIS,
  GET_CONTENT_ANALYSIS_SUCCESS,
  UPDATE_CONTENT_ANALYSIS,
  UPDATE_CONTENT_ANALYSIS_SUCCESS,
  CONTENT_ANALYSIS_PAGE_KEYWORDS,
  CONTENT_ANALYSIS_PAGE_KEYWORDS_SUCCESS
} from "./actionTypes"

const initialState = {
  data: {}
}

const contentAnalysis = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONTENT_ANALYSIS:
      return { ...state }
    case GET_CONTENT_ANALYSIS_SUCCESS:
      return { ...state, data: action.payload }
    default:
      return state
  }
}

export default contentAnalysis
