import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalHeader, ModalBody, Spinner } from 'reactstrap';
import { checkIfLoading } from '../../../store/functions';
import { listBusinesses } from '../../../store/localseo/actions';
import { LIST_BUSINESSES } from '../../../store/localseo/actionTypes';
import SearchResultModal from './SearchResultModal';

function BusinessModal({ modal, toggle }) {
    // ** states
    const [businessModal, setBusinessModal] = useState(false);
    const [name, setName] = useState(null)
    const dispatch = useDispatch()

    // ** function to handle toggle search result modal
    const toggleSearchResultModal = () => setBusinessModal(!businessModal);

    const { listLoading, businesses } = useSelector(state => ({
        listLoading: checkIfLoading(state, LIST_BUSINESSES),
        businesses: state?.localseo?.businesses
    }))

    // ** function to handle toggle search result modal and hide business modal
    const handleSearchResultModal = () => {
        dispatch(listBusinesses(name, toggle, toggleSearchResultModal))
    }

    return (
        <>
            <Modal isOpen={modal} toggle={toggle} centered={true}>
                <ModalHeader className='px-4' toggle={toggle}></ModalHeader>
                <ModalBody className='text-center px-3 pt-1 pb-3'>
                    <h3 className='mb-3'>Enter Google Business Name</h3>
                    <div style={{ width: "80%" }} className="m-auto">
                        <input type='text' onChange={(e) => setName(e.target.value)} className='form-control m-auto bg-transparent' placeholder='Zuma Rome' required />
                        <button type='submit' className='btn click-btn green-btn btn-block mt-2' onClick={handleSearchResultModal}>
                            {
                                listLoading ? <Spinner size="sm" className="m-auto text-center" color="light" /> : "Next"
                            }
                        </button>
                    </div>
                </ModalBody>
            </Modal>
            <SearchResultModal modal={businessModal} toggle={toggleSearchResultModal} />
        </>
    )
}

export default BusinessModal