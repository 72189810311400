import {
  LIST_COMPETITORS,
  LIST_COMPETITORS_SUCCESS,
  LIST_BACKLINKS,
  LIST_BACKLINKS_SUCCESS,
  ADD_COMPETITOR,
  ADD_COMPETITOR_SUCCESS,
  COMPETITORS_BACKLINKS,
  COMPETITORS_BACKLINKS_SUCCESS,
  CREATE_EMAIL,
  CREATE_EMAIL_SUCCESS,
  REMOVE_COMPETITOR,
  REMOVE_COMPETITOR_SUCCESS,
  LIST_CAMPAIGNS,
  LIST_CAMPAIGNS_SUCCESS,
  GET_FEEDBACK,
  GET_FEEDBACK_SUCCESS,
  GET_BACKLINKS_DATA,
  GET_BACKLINKS_DATA_SUCCESS,
  GET_COMPETITOR_BACKLINKS,
  GET_COMPETITOR_BACKLINKS_SUCCESS
} from "./actionTypes"

const initialState = {
  competitors: {},
  competitorBacklinks: {},
  backlinksData: {},
  backlinks: {},
  competitor: null,
  feedback: []
}

const Webreputation = (state = initialState, action) => {
  switch (action.type) {
    case LIST_COMPETITORS:
      return { ...state, competitors: [] }
    case LIST_COMPETITORS_SUCCESS:
      return { ...state, competitors: action.payload }
    case REMOVE_COMPETITOR:
      return { ...state }
    case REMOVE_COMPETITOR_SUCCESS:
      return {
        ...state, competitors: {
          ...state.competitors,
          list: state?.competitors?.list?.filter(com => com?._id !== action.payload)
        }
      }
    case ADD_COMPETITOR:
      return { ...state }
    case ADD_COMPETITOR_SUCCESS:
      return {
        ...state, competitors: {
          ...state.competitors,
          list: [...state?.competitors.list, action.payload]
        }
      }
    case COMPETITORS_BACKLINKS:
      return { ...state }
    case COMPETITORS_BACKLINKS_SUCCESS:
      return { ...state, competitorBacklinks: action.payload }
    case LIST_BACKLINKS:
      return { ...state }
    case LIST_BACKLINKS_SUCCESS:
      return { ...state, backlinks: action.payload }
    case GET_BACKLINKS_DATA:
      return { ...state }
    case GET_BACKLINKS_DATA_SUCCESS:
      return { ...state, backlinksData: action.payload }
    case GET_COMPETITOR_BACKLINKS:
      return { ...state }
    case GET_COMPETITOR_BACKLINKS_SUCCESS:
      return { ...state, competitor: action.payload }
    case GET_FEEDBACK:
      return { ...state }
    case GET_FEEDBACK_SUCCESS:
      return { ...state, feedback: action.payload }
    default:
      return state
  }
}

export default Webreputation