import {
  GET_TECHNICAL_ANALYSIS,
  GET_TECHNICAL_ANALYSIS_SUCCESS,
  UPDATE_TECHNICAL_ANALYSIS,
  UPDATE_TECHNICAL_ANALYSIS_SUCCESS
} from "./actionTypes"

const initialState = {
  data: {}
}

const technicalAnalysis = (state = initialState, action) => {
  switch (action.type) {
    case GET_TECHNICAL_ANALYSIS:
      return { ...state }
    case GET_TECHNICAL_ANALYSIS_SUCCESS:
      return { ...state, data: action.payload }
    default:
      return state
  }
}

export default technicalAnalysis