import React from "react";
import SmallTable from "../../../../components/tables/SmallTable";

function KeywordsRanking() {


  const columns = [
    {
      title: "Keyword",
      selector: "keyword"
    },
    {
      title: "Position",
      selector: "position"
    }
  ]

  const data = [
    {
      id: 1,
      keyword: "behance",
      position: 2
    },
    {
      id: 2,
      keyword: "behance",
      position: 6
    },
    {
      id: 3,
      keyword: "behance",
      position: 8
    },
    {
      id: 4,
      keyword: "behance",
      position: 12
    },
    {
      id: 5,
      keyword: "behance",
      position: 4
    }
  ]

  return (
    <div className="keywords-ranking row">
      <div className="col-12">
        <h2>Keywords Ranking</h2>
        <p className="description">These Keywords are currently ranking on Google for this page</p>
      </div>
      <div className="col-xl-6">
        <div className="card">
          <div className="row justify-content-between">
            <div className="col-xl-6">
              <SmallTable columns={columns} data={data} />
            </div>
            <div className="col-xl-6">
              <SmallTable columns={columns} data={data} />
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-6">
        <div className="card">
          <div className="row justify-content-between">
            <div className="col-xl-6">
              <SmallTable columns={columns} data={data} />
            </div>
            <div className="col-xl-6">
              <SmallTable columns={columns} data={data} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default KeywordsRanking;
