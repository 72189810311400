import api from '../apis/api'
import { tokenConfig } from '../config'

export const ListCompetitorsAPI = (query, body, state) => {
  return new Promise((res, rej) => {
    api.post(`/competitors/data?${query}`, body, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const ListBacklinksAPI = (query, state) => {
  return new Promise((res, rej) => {
    api.get(`/backlink/list?${query}`, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const GetBacklinksDataAPI = (query, state) => {
  return new Promise((res, rej) => {
    api.get(`/backlink?${query}`, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const GetCompetitorBacklinksAPI = (query, state) => {
  return new Promise((res, rej) => {
    api.get(`backlink/list?${query}`, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const AddCompetitorAPI = (body, state) => {
  return new Promise((res, rej) => {
    api.post(`/competitors`, body, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const RemoveCompetitorAPI = (id, state) => {
  return new Promise((res, rej) => {
    api.delete(`/competitors/${id}`, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const ListCompetitorsBacklinksAPI = (query, body, state) => {
  return new Promise((res, rej) => {
    api.post(`/competitors/data/backlinks?${query}`, body, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const CreateEmailAPI = (body, state) => {
  return new Promise((res, rej) => {
    api.post(`/login`, body, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const GetFeedbackAPI = (query, state) => {
  return new Promise((res, rej) => {
    api.get(`/review?${query}`, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const ListCampaigns = (body, state) => {
  return new Promise((res, rej) => {
    api.post(`/login`, body, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}