import {
  GET_TECHNICAL_ANALYSIS,
  GET_TECHNICAL_ANALYSIS_SUCCESS,
  UPDATE_TECHNICAL_ANALYSIS,
  UPDATE_TECHNICAL_ANALYSIS_SUCCESS
} from "./actionTypes"

export const getTechnicalAnalysis = (id) => {
  return {
    type: GET_TECHNICAL_ANALYSIS,
    payload: { id },
  }
}

export const getTechnicalAnalysisSuccess = (data) => {
  return {
    type: GET_TECHNICAL_ANALYSIS_SUCCESS,
    payload: data
  }
}

export const updateTechnicalAnalysis = (id) => {
  return {
    type: UPDATE_TECHNICAL_ANALYSIS,
    payload: { id }
  }
}

export const updateTechnicalAnalysisSuccess = (data) => {
  return {
    type: UPDATE_TECHNICAL_ANALYSIS_SUCCESS,
    payload: data
  }
}