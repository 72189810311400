import {
  LIST_WORKSPACES,
  LIST_WORKSPACES_SUCCESS,
  ADD_WORKSPACE,
  ADD_WORKSPACE_SUCCESS,
  GET_WORKSPACE,
  GET_WORKSPACE_SUCCESS,
  UPDATE_WORKSPACE,
  UPDATE_WORKSPACE_SUCCESS,
  DELETE_WORKSPACE,
  DELETE_WORKSPACE_SUCCESS,
  INVITE_COLLABORATOR,
  INVITE_COLLABORATOR_SUCCESS,
  REMOVE_COLLABORATOR,
  REMOVE_COLLABORATOR_SUCCESS,
  REGISTER_DOMAIN,
  REGISTER_DOMAIN_SUCCESS,
  ADD_SENDER_EMAIL,
  ADD_SENDER_EMAIL_SUCCESS,
  LIST_WORKSPACE_LANGUAGES,
  LIST_WORKSPACE_REGIONS,
  LIST_WORKSPACE_LANGUAGES_SUCCESS,
  LIST_WORKSPACE_REGIONS_SUCCESS,
  LIST_WORKSPACE_BILLINGS,
  LIST_WORKSPACE_BILLINGS_SUCCESS,
  UPDATE_WORKSPACE_LOGO,
  UPDATE_WORKSPACE_LOGO_SUCCESS,
  CANCEL_WORKSPACE_SUBSCRIPTION,
  CANCEL_WORKSPACE_SUBSCRIPTION_SUCCESS
} from "./actionTypes"

export const listWorkspaces = () => {
  return {
    type: LIST_WORKSPACES,
    payload: {},
  }
}

export const listWorkspacesSuccess = (data) => {
  return {
    type: LIST_WORKSPACES_SUCCESS,
    payload: data
  }
}

export const listWorkspaceBillings = (query) => {
  return {
    type: LIST_WORKSPACE_BILLINGS,
    payload: { query },
  }
}

export const listWorkspaceBillingsSuccess = (data) => {
  return {
    type: LIST_WORKSPACE_BILLINGS_SUCCESS,
    payload: data
  }
}

export const addWorkspace = (data, history) => {
  return {
    type: ADD_WORKSPACE,
    payload: { data, history },
  }
}

export const addWorkspaceSuccess = (data) => {
  return {
    type: ADD_WORKSPACE_SUCCESS,
    payload: data
  }
}

export const getWorkspace = (id, history) => {
  return {
    type: GET_WORKSPACE,
    payload: { id, history }
  }
}

export const getWorkspaceSuccess = (data) => {
  return {
    type: GET_WORKSPACE_SUCCESS,
    payload: data
  }
}

export const deleteWorkspace = (id, history) => {
  return {
    type: DELETE_WORKSPACE,
    payload: { id, history }
  }
}

export const deleteWorkspaceSuccess = (data) => {
  return {
    type: DELETE_WORKSPACE_SUCCESS,
    payload: data
  }
}

export const updateWorkspace = (id, data) => {
  return {
    type: UPDATE_WORKSPACE,
    payload: { id, data }
  }
}

export const updateWorkspaceSuccess = (data) => {
  return {
    type: UPDATE_WORKSPACE_SUCCESS,
    payload: data
  }
}

export const updateWorkspaceLogo = (image) => {
  return {
    type: UPDATE_WORKSPACE_LOGO,
    payload: { image }
  }
}

export const updateWorkspaceLogoSuccess = (data) => {
  return {
    type: UPDATE_WORKSPACE_LOGO_SUCCESS,
    payload: data
  }
}

export const inviteCollaborator = (data) => {
  return {
    type: INVITE_COLLABORATOR,
    payload: { data }
  }
}

export const inviteCollaboratorSuccess = (data) => {
  return {
    type: INVITE_COLLABORATOR_SUCCESS,
    payload: data
  }
}

export const removeCollaborator = (data) => {
  return {
    type: REMOVE_COLLABORATOR,
    payload: { data }
  }
}

export const removeCollaboratorSuccess = (data) => {
  return {
    type: REMOVE_COLLABORATOR_SUCCESS,
    payload: data
  }
}

export const registerDomain = () => {
  return {
    type: REGISTER_DOMAIN,
    payload: {}
  }
}

export const registerDomainSuccess = () => {
  return {
    type: REGISTER_DOMAIN_SUCCESS,
    payload: {}
  }
}

export const cancelSubscription = (id) => {
  return {
    type: CANCEL_WORKSPACE_SUBSCRIPTION,
    payload: { id }
  }
}

export const cancelSubscriptionSuccess = (id) => {
  return {
    type: CANCEL_WORKSPACE_SUBSCRIPTION_SUCCESS,
    payload: id
  }
}

export const listWorkspaceLanguages = () => {
  return {
    type: LIST_WORKSPACE_LANGUAGES,
    payload: {}
  }
}

export const listWorkspaceLanguagesSuccess = (data) => {
  return {
    type: LIST_WORKSPACE_LANGUAGES_SUCCESS,
    payload: data
  }
}

export const listWorkspaceRegions = () => {
  return {
    type: LIST_WORKSPACE_REGIONS,
    payload: {}
  }
}

export const listWorkspaceRegionsSuccess = (data) => {
  return {
    type: LIST_WORKSPACE_REGIONS_SUCCESS,
    payload: data
  }
}

export const addSenderEmail = () => {
  return {
    type: ADD_SENDER_EMAIL,
    payload: {},
  }
}

export const addSenderEmailSuccess = () => {
  return {
    type: ADD_SENDER_EMAIL_SUCCESS,
    payload: {}
  }
}