import { put, select, takeEvery } from "redux-saga/effects"
import { AddCommentAPI, AddCustomerAPI, GetAnalyticsAPI, GetCustomerAPI, ListCustomersAPI, ListTagsAPI, ListTop5TagsAPI } from "./calls"
import { addCommentSuccess, addCustomerSuccess, getCrmAnalyticsSuccess, getCustomerSuccess, listCustomersSuccess, listTagsSuccess, listTop5TagsSuccess } from './actions'
import { ADD_CUSTOMER, ADD_CUSTOMER_COMMENT, GET_ANALYTICS, GET_CUSTOMER, LIST_CUSTOMERS, LIST_TAGS, LIST_TOP_5_TAGS } from "./actionTypes"
import { setError, setSuccess, startAction, stopAction } from "../actions"

function* addCustomer({ type, payload: { data, toggle } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const result = yield AddCustomerAPI(data, state)
    console.log(result)
    yield put(setSuccess(result?.data?.message))
    yield put(addCustomerSuccess(result?.data?.data))
    toggle()
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* getCustomer({ type, payload: { id } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const result = yield GetCustomerAPI(id, state)
    console.log(result)
    yield put(getCustomerSuccess(result?.data?.data))
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* listCustomers({ type, payload: { query = {} } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    query = new URLSearchParams({ ...query, workspace: state?.workspace?.workspace?._id }).toString()
    const result = yield ListCustomersAPI(query, state)
    console.log(result)
    yield put(listCustomersSuccess(result?.data?.data))
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* addComment({ type, payload: { comment } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const result = yield AddCommentAPI(comment, state)
    console.log(result)
    yield put(setSuccess(result?.data?.message))
    yield put(addCommentSuccess(result?.data?.data))
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* getCrmAnalytics({ type }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const workspace = state?.workspace?.workspace?._id
    const query = new URLSearchParams({ workspace: workspace }).toString()
    const result = yield GetAnalyticsAPI(query, state)
    console.log(result)
    yield put(getCrmAnalyticsSuccess(result?.data?.data))
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* listTags({ type, payload: { query = {} } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const workspace = state?.workspace?.workspace?._id
    query = new URLSearchParams({ workspace: workspace, ...query }).toString()
    const result = yield ListTagsAPI(query, state)
    console.log(result)
    yield put(listTagsSuccess(result?.data?.data))
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* listTop5Tags({ type, payload: { query = {} } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const workspace = state?.workspace?.workspace?._id
    query = new URLSearchParams({ workspace: workspace, ...query }).toString()
    const result = yield ListTop5TagsAPI(query, state)
    console.log(result)
    yield put(listTop5TagsSuccess(result?.data?.data))
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* CrmSaga() {
  yield takeEvery(ADD_CUSTOMER, addCustomer)
  yield takeEvery(GET_CUSTOMER, getCustomer)
  yield takeEvery(LIST_CUSTOMERS, listCustomers)
  yield takeEvery(ADD_CUSTOMER_COMMENT, addComment)
  yield takeEvery(GET_ANALYTICS, getCrmAnalytics)
  yield takeEvery(LIST_TAGS, listTags)
  yield takeEvery(LIST_TOP_5_TAGS, listTop5Tags)
}

export default CrmSaga