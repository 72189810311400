import React, { useEffect, useState } from 'react'
import { Tab, Tabs, ListGroup, ListGroupItem, Table, FormCheck, InputGroup, FormControl } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Spinner } from 'reactstrap'
import TablePagination from '../../../components/common/Pagination'
import { getCrmAnalytics, listCustomers, listTags, listTop5Tags } from '../../../store/actions'
import { LIST_CUSTOMERS, LIST_TAGS, LIST_TOP_5_TAGS } from '../../../store/crm/actionTypes'
import { checkIfLoading } from '../../../store/functions'
import AddContactModal from './Components/AddContact'
import CustomersTable from './Components/CustomersTable'
import CrmNav from './Components/Nav'
import TagCustomers from './Components/TagCustomers'

function Customers() {
  const dispatch = useDispatch()
  const [query, setQuery] = useState({
    page: 1,
    email: null,
    limit: 5,
    type: "customer"
  })
  const [selectedTag, setSelectedTag] = useState(null)
  const [contactModal, setContactModal] = useState(false)

  const { customers, count, tags, tagLoading, customerLoading, top5tags, top5tagsLoading } = useSelector(state => ({
    customers: state?.crm?.customers?.list,
    count: state?.crm?.customers?.page_details?.total_items,
    tags: state?.crm?.tags?.list,
    tagLoading: checkIfLoading(state, LIST_TAGS),
    customerLoading: checkIfLoading(state, LIST_CUSTOMERS),
    top5tags: state?.crm?.top5tags,
    top5tagsLoading: checkIfLoading(state, LIST_TOP_5_TAGS)
  }))

  const renderTags = () => {
    if (tagLoading) return <Spinner size="sm" className="m-auto text-center" color="success" />
    if (tags && tags?.length > 0) {
      return tags?.map(tag => {
        return <ListGroupItem>{tag.name}</ListGroupItem>
      })
    } else {
      return 'No tags found'
    }
  }

  const renderTop5Tags = () => {
    if (top5tagsLoading) return <Spinner size="sm" className="m-auto text-center" color="success" />
    if (top5tags && top5tags?.length > 0) {
      return top5tags?.map((tag) => {
        return <div className='outer-box' onClick={() => setSelectedTag(tag)}>
          <div className={`tag-box ${selectedTag?._id === tag?._id ? 'bg-orange' : "bg-green"}`}>
            <div className='percent' style={{ height: (100 - tag?.percent) + '%' }}><div className='number'>{tag?.customers}</div></div>
          </div>
          <h6 className='mt-3'>{tag?.name}</h6>
        </div>
      })
    } else {
      return 'No tags found'
    }
  }

  useEffect(() => {
    dispatch(listTags({ page: 1, limit: 5 }))
  }, [])

  useEffect(() => {
    dispatch(listTop5Tags({ type: query.type }))
  }, [query.type])

  useEffect(() => {
    dispatch(listCustomers(query))
  }, [query])

  useEffect(() => {
    if (top5tags && top5tags?.length > 0) setSelectedTag(top5tags[0])
  }, [top5tags])

  const onTabSelect = (e) => {
    setQuery({
      ...query,
      type: e
    })
  }

  const onPageChange = (page) => {
    setQuery({
      ...query,
      page
    })
  }

  return (
    <div className='container-fluid customers page'>
      <CrmNav />
      <Tabs defaultActiveKey="customer" onSelect={onTabSelect} id="uncontrolled-tab-example" className="mb-3">
        <Tab eventKey="customer" title="Current Customers"></Tab>
        <Tab eventKey="lead" title="Leads"></Tab>
      </Tabs>
      <div className='row'>
        <div className='col-md-6'>
          <div className='d-flex'>
            <h6 className='mr-2'>{count} Total Clients</h6>
            <span className='text-muted'>Your Most Recent 5 tags</span>
          </div>
          <div className='d-flex align-items-center'>
            <div className='mr-3'>
              <ListGroup className='list-tags' horizontal>
                {renderTags()}
              </ListGroup>
            </div>
            <InputGroup className='custom'>
              <InputGroup.Text id="basic-addon1" className='bg-transparent'><i class="fas fa-search"></i></InputGroup.Text>
              <FormControl
                placeholder="Search"
                aria-label="Search"
                aria-describedby="basic-addon1"
              />
            </InputGroup>
          </div>
        </div>
        <div className='col-md-6 text-right'>
          <button className='btn btn-outline-secondary' onClick={() => setContactModal(true)}><i class="fas fa-sm fa-plus"></i> Add Contact</button>
        </div>
      </div>
      <CustomersTable query={query} onPageChange={onPageChange} />
      <div className='mt-3'>
        <h4>Your Top 5 tags</h4>
        <div className='top5tags d-flex mt-5'>
          {renderTop5Tags()}
        </div>
      </div>
      <div className='mt-3'>
        <TagCustomers tag={selectedTag} />
      </div>
      <AddContactModal modal={contactModal} toggle={() => setContactModal(!contactModal)} />
    </div>
  )
}

export default Customers