import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Spinner } from 'reactstrap'
import { getCrmAnalytics } from '../../../store/actions'
import { checkIfLoading, stringToHTML } from '../../../store/functions'
import { listReminders } from '../../../store/reminders/actions'
import { LIST_REMINDERS } from '../../../store/reminders/actionTypes'
import AddReminderMenu from './Components/AddReminder'
import CrmNav from './Components/Nav'

function Reminders() {
  const dispatch = useDispatch()
  const [addReminderMenu, setAddReminderMenu] = useState(false)
  const toggle = () => setAddReminderMenu(!addReminderMenu)

  const [selectedReminder, setSelectedReminder] = useState(null)

  const { reminders, loading } = useSelector(state => ({
    reminders: state?.reminder?.reminders,
    loading: checkIfLoading(state, LIST_REMINDERS)
  }))

  useEffect(() => {
    dispatch(listReminders())
  }, [])

  useEffect(() => {
    if(selectedReminder) {
      setAddReminderMenu(true)
    }
  }, [selectedReminder])

  const renderReminders = () => {
    if (loading) return <Spinner size="sm" className="m-auto text-center" color="success" />
    let html = []
    if (reminders) {
      if (reminders?.today && reminders?.today?.length > 0) {
        html.push(<h4>Today</h4>)
        let todayReminders = []
        reminders?.today?.map(reminder => {
          todayReminders.push(
            <li onClick={() => setSelectedReminder(reminder)}
              className={`d-flex align-items-center mb-4 
            ${reminder?._id === selectedReminder?._id ? 'selected' : ""} 
            ${reminder?.status !== 'finished' ? 'active' : ""}`}>
              <h5 className='time mr-5'>{moment(reminder.start).format('hh:mm')}</h5>
              <h5 className='title'>{reminder.summary}</h5>
            </li>
          )
        })
        html.push(<ul className='list-unstyled reminders-list'>{todayReminders}</ul>)
      } else {
        html.push(<span>No Reminders today</span>)
      }
      if (reminders?.tomorrow && reminders?.tomorrow?.length > 0) {
        html.push(<h4>Tomorrow</h4>)
        let tomorrowReminders = []
        reminders?.tomorrow?.map(reminder => {
          tomorrowReminders.push(
            <li onClick={() => setSelectedReminder(reminder)}
              className={`d-flex align-items-center mb-4 
            ${reminder?._id === selectedReminder?._id ? 'selected' : ""} 
            ${reminder?.status !== 'finished' ? 'active' : ""}`}>
              <h5 className='time mr-5'>{moment(reminder.start).format('hh:mm')}</h5>
              <h5 className='title'>{reminder.summary}</h5>
            </li>
          )
        })
        html.push(<ul className='list-unstyled reminders-list'>{tomorrowReminders}</ul>)
      } else {
        html.push(<span>No Reminders tomorrow</span>)
      }
    }
    return html
  }

  const renderTime = () => {
    let time = []
    time.push(<span>{moment().format('hh')}</span>)
    time.push(<span className='text-orange'>:</span>)
    time.push(<span>{moment().format('mm')}</span>)
    return time
  }

  return (
    <>
      <AddReminderMenu reminder={selectedReminder} setReminder={setSelectedReminder} open={addReminderMenu} toggle={toggle} />
      <div className='container-fluid reminders page'>
        <CrmNav />
        <div className='row mt-4 justify-content-center'>
          <div className='col-md-8 text-center'>
            <h2 className='mt-2'>Good Afternoon, Jake!</h2>
            <span className='text-muted'>You’ve got 3 events today. We won’t let you miss them</span>
          </div>
        </div>
        <div className='row mt-4 justify-content-center'>
          <div className='col-md-3'>
            <div className='clock'>
              {renderTime()}
            </div>
          </div>
          <div className='col-md-9 d-flex flex-column'>
            <div>
              <button className='float-right btn click-btn green-btn' onClick={toggle}>New Reminder</button>
            </div>
            <div>
              {renderReminders()}
            </div>
          </div>
        </div>
      </div>
    </>

  )
}

export default Reminders