import React from 'react'
import AreaChart from '../../../../components/charts/AreaChart'

function BacklinksOverTime() {
    // ** fake backend database 
    const trafficData = [
        {
            label: "Jan",
            value: 200
        },
        {
            label: "Feb",
            value: 42
        },
        {
            label: "Mar",
            value: 109
        },
        {
            label: "Apr",
            value: 300
        },
        {
            label: "May",
            value: 500
        },
        {
            label: "Jun",
            value: 55
        },
    ]

    return (
        <div className='traffic-history card'>
            <h3>Backlinks over time</h3>
            <AreaChart name="Traffic Data" data={trafficData} curveType="smooth" showYaxis={true} />
        </div>
    )
}

export default BacklinksOverTime