import React, { useEffect, useState } from 'react'
import { FormControl, InputGroup } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { Spinner } from 'reactstrap'
import TablePagination from '../../../../components/common/Pagination'
import { listCompetitors, removeCompetitor } from '../../../../store/actions'

const CompetitorsTable = ({ loading, competitors, details }) => {
    const dispatch = useDispatch()
    const [filters, setFilters] = useState({
        page: 1,
        limit: 5
    })

    const setPage = (page) => {
        setFilters({
            ...filters,
            page
        })
    }

    useEffect(() => {
        const query = new URLSearchParams(filters).toString()
        console.log(query)
        dispatch(listCompetitors(query))
    }, [filters.page])

    const renderCompetitors = () => {
        if (loading) return <Spinner size="sm" className="m-auto text-center" color="success" />
        if (competitors?.length > 0) {
            return competitors?.map(comp => {
                return <li>
                    <span>{comp?.url}</span>
                    <button onClick={() => dispatch(removeCompetitor(comp?._id))} className='btn btn-link m-0 p-0 green float-right'>Remove</button>
                </li>
            })
        } else {
            return <div>No competitor found</div>
        }
    }

    return (
        <div>
            <div className='row justify-content-between align-items-end'>
                <div className='col-md-4'>
                    <InputGroup>
                        <InputGroup.Text id="basic-addon1" className='bg-transparent'><i class="fas fa-search"></i></InputGroup.Text>
                        <FormControl
                            placeholder="Search"
                            aria-label="Search"
                            aria-describedby="basic-addon1"
                        />
                    </InputGroup>
                </div>
                <div className='col-md-6'>
                    <div className='add-competitor'>
                        <div className='row align-items-end'>
                            <div className='col-md-8'>
                                <input className='form-control' placeholder='Enter name' />
                            </div>
                            <div className='col-md-4'>
                                <button className='btn click-btn orange-btn btn-block py-1 px-1'>Add Competitor</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ul className='list-unstyled competitors-list mt-3'>
                {renderCompetitors()}
            </ul>
            <div className='mt-3'>
                <TablePagination count={details?.total_items} page={filters?.page} setPage={setPage} limit={filters?.limit} loading={loading} />
            </div>
        </div>
    )
}

export default CompetitorsTable