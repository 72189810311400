import {
  CLEAR_ERRROR,
  REFRESH_ACTION_START,
  REFRESH_ACTION_STOP,
  SET_ERROR,
  SET_SUCCESS,
  START_ACTION,
  STOP_ACTION
} from './actionTypes';

export const startAction = (name, params) => ({
  type: START_ACTION,
  payload: {
    action: {
      name,
      params
    }
  }
});

export const stopAction = name => ({
  type: STOP_ACTION,
  payload: { name }
});

export const setError = err => ({
  type: SET_ERROR,
  payload: err
});

export const setSuccess = msg => ({
  type: SET_SUCCESS,
  payload: msg
});

export const clearError = () => ({
  type: CLEAR_ERRROR,
  payload: {}
});

export const refreshActionStart = refreshAction => ({
  type: REFRESH_ACTION_START,
  payload: { refreshAction }
});

export const refreshActionStop = refreshAction => ({
  type: REFRESH_ACTION_STOP,
  payload: { refreshAction }
});