import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import SidemenuCloseBtn from "./SidemenuCloseBtn";

function Sidemenu({open, setOpen}) { 
  // ** states
  const [layout, setLayout] = useState()

  // ** useEffect to set side menu layout value
  useEffect(() => {
    if (open && window.innerWidth < 992) {
      setLayout({transform: 'translateX(0px)'})
    } else if (!open && window.innerWidth < 992) {
      setLayout({transform: 'translateX(-260px)'})
    }
  }, [open])

  return (
    <section className="side-menu" style={layout}>
      <SidemenuCloseBtn setOpen={setOpen} />
      <img src={`${process.env.PUBLIC_URL}/assets/images/logos/logo-admin.afb529f9.svg`} alt="logo" />
      <h4>SEO Tools</h4>
      <ul>
        <li>
          <NavLink to="/" className="btn">
            <i className="fas fa-th-large"></i>
            Dashboard
          </NavLink>
        </li>
        <li>
          <NavLink to="/technical-analysis" className="btn">
            <i className="fas fa-cog"></i>
            Technical Analysis
          </NavLink>
        </li>
        <li>
          <NavLink to="/content-analysis" className="btn">
            <i className="fas fa-file-alt"></i>
            Content Analysis
          </NavLink>
        </li>
        <li>
          <NavLink to="/content-generator" className="btn">
            <i className="fas fa-pen"></i>
            Content Generator
          </NavLink>
        </li>
        <li>
          <NavLink to="/local-seo" className="btn">
            <i className="fas fa-map-marker-alt"></i>
            Local SEO
          </NavLink>
        </li>
        <li>
          <NavLink to="/web-reputation" className="btn">
            <i className="fas fa-recycle"></i>
            Web Reputation
          </NavLink>
        </li>
        <li>
          <NavLink to="/project-management" className="btn">
            <i className="fas fa-layer-group"></i>
            Project Management
          </NavLink>
        </li>
        <li>
          <NavLink to="/crm" className="btn">
            <i className="fas fa-credit-card"></i>
            CRM
          </NavLink>
        </li>
      </ul>
    </section>
  );
}

export default Sidemenu;
