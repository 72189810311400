import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const WebReputationNav = () => {
    const location = useLocation()
    const navigate = useNavigate()

    return (
        <div className='container crm-nav mt-5'>
            <div className='row'>
                <div className='col-md-12 d-flex'>
                    <ul className='list-group m-auto text-center list-group-horizontal-lg'>
                        <li onClick={() => navigate('/web-reputation')} className={`${location.pathname === '/web-reputation' ? 'active' : ""}`}><i class="fas fa-network-wired"></i><h6>Digital Outreach</h6></li>
                        <li onClick={() => navigate('/backlinks')} className={`${location.pathname === '/backlinks' ? 'active' : ""}`}><i class="fas fa-link"></i><h6>Your Backlinks</h6></li>
                        <li onClick={() => navigate('/competitor-backlinks')} className={`${location.pathname === '/competitor-backlinks' ? 'active' : ""}`}><i class="fas fa-user"></i><h6>Competitor's Backlinks</h6></li>
                        <li onClick={() => navigate('/competitors')} className={`${location.pathname === '/competitors' ? 'active' : ""}`}><i class="fas fa-users"></i><h6>Competitor's List</h6></li>
                        <li onClick={() => navigate('/feedback')} className={`${location.pathname === '/feedback' ? 'active' : ""}`}><i class="fas fa-star"></i><h6>Feedback</h6></li>
                        <li onClick={() => navigate('/campaigns')} className={`${location.pathname === '/campaigns' ? 'active' : ""}`}><i class="fas fa-bell"></i><h6>Campaigns</h6></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default WebReputationNav