import { put, select, takeEvery } from "redux-saga/effects"
import { DELETE_USER, FORGET_PASSWORD, GET_USER_DATA, LOGIN_USER, LOGOUT_USER, REGISTER_USER, RESET_PASSWORD, UPDATE_EMAIL, UPDATE_PASSWORD, UPDATE_USER_DATA, VERIFY_USER } from "./actionTypes"
import {
  forgetPasswordSuccess, getUserDataSuccess, listAnalyticsSuccess, listLanguagesSuccess,
  loginUserSuccess, logoutUserSuccess, resetPasswordSuccess, updateEmailSuccess,
  updatePasswordSuccess, updateUserDataSuccess, verifyUserSuccess, verifyUser, registerUserSuccess, logoutUser
} from "./actions"
import { forgetPasswordAPI, getUserDataAPI, listAnalyticsAccountAPI, listLanguagesAPI, LoginAPI, RegisterAPI, resetPasswordAPI, updateEmailAPI, updatePasswordAPI, updateProfileAPI, VerifyAPI } from "./authCalls"
import { setError, setSuccess, startAction, stopAction } from "../actions"
import { uploadImageSaga } from "../upload/saga"
import { START_UPLOAD } from "../upload/actionTypes"

function* loginUser({ type, payload: { user, history } }) {
  try {
    yield put(startAction(type))
    const result = yield LoginAPI(user)
    localStorage.setItem('token', result?.data?.data?.token)
    console.log(result)
    yield put(setSuccess(result?.data?.message))
    yield put(loginUserSuccess(result?.data?.data))
    history('/add-workspace')
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* registerUser({ type, payload: { user, history } }) {
  try {
    console.log(user)
    yield put(startAction(type))
    const result = yield RegisterAPI(user)
    yield put(setSuccess(result?.data?.message))
    yield put(registerUserSuccess())
    setTimeout(() => { history('/login') }, 3000)
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* verifyUserSaga({ type, payload: { user, history } }) {
  try {
    yield put(startAction(type))
    const result = yield VerifyAPI(user)
    yield put(setSuccess(result.data?.message))
    history('/login')
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* getUserData({ type }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const result = yield getUserDataAPI(state)
    console.log(result)
    yield put(getUserDataSuccess(result?.data?.data))
  } catch (err) {
    console.log(err)
    // localStorage.removeItem('token')
    // yield put(logoutUserSuccess())
  } finally {
    yield put(stopAction(type))
  }
}

function* updateUserData({ type, payload: { data } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    let imageUploaded
    // console.log({ image })
    if (data.image) {
      const result = yield uploadImageSaga({
        type: START_UPLOAD,
        payload: {
          file: data.image,
          uploadType: "user"
        }
      })
      data.image = result
    }
    const result = yield updateProfileAPI(data, state)
    console.log(result)
    yield put(setSuccess(result?.data?.message))
    yield put(updateUserDataSuccess(result?.data))
  } catch (err) {
    console.log(err)
    yield put(setError())
  } finally {
    yield put(stopAction(type))
  }
}

function* updatePassword({ type, payload: { data, toggle } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const result = yield updatePasswordAPI(data, state)
    console.log(result)
    yield put(setSuccess(result?.data?.message))
    toggle()
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* forgetPasswordSaga({ type, payload: { email, history } }) {
  try {
    yield put(startAction(type))
    const result = yield forgetPasswordAPI({ email })
    console.log(result)
    yield put(setSuccess(result?.data?.message))
    yield put(forgetPasswordSuccess())
    history('/reset-password')
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* resetPasswordSaga({ type, payload: { data, history } }) {
  try {
    yield put(startAction(type))
    const result = yield resetPasswordAPI(data)
    console.log(result)
    yield put(setSuccess(result?.data?.message))
    yield put(resetPasswordSuccess())
    history('/login')
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* listLanguages({ type, payload: { data } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const result = yield listLanguagesAPI(data, state)
    console.log(result)
    yield put(listLanguagesSuccess(result?.data?.message))
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* listAnalytics({ type, payload: { data } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const result = yield listAnalyticsAccountAPI(data, state)
    console.log(result)
    yield put(listAnalyticsSuccess(result?.data?.message))
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* updateEmail({ type, payload: { data, toggle, history } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const result = yield updateEmailAPI(data, state)
    console.log(result)
    yield put(updateEmailSuccess(data?.email))
    yield put(setSuccess(result?.data?.message))
    toggle()
    yield put(logoutUser(history))
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* deleteUser({ type, payload: { } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const result = yield deleteUserAPI(state)
    localStorage.removeItem('token')
    yield put(setSuccess(result?.data?.message))
    yield put(logoutUserSuccess())
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* logoutUserSaga({ type, payload: { history } }) {
  try {
    yield put(startAction(type))
    localStorage.removeItem('token')
    localStorage.removeItem('workspace')
    yield put(logoutUserSuccess())
    history('/login')
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* loginSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(REGISTER_USER, registerUser)
  yield takeEvery(VERIFY_USER, verifyUserSaga)
  yield takeEvery(GET_USER_DATA, getUserData)
  yield takeEvery(FORGET_PASSWORD, forgetPasswordSaga)
  yield takeEvery(RESET_PASSWORD, resetPasswordSaga)
  yield takeEvery(UPDATE_USER_DATA, updateUserData)
  yield takeEvery(UPDATE_PASSWORD, updatePassword)
  yield takeEvery(UPDATE_EMAIL, updateEmail)
  yield takeEvery(DELETE_USER, deleteUser)
  yield takeEvery(LOGOUT_USER, logoutUserSaga)
}

export default loginSaga