import api from '../apis/api'
import { tokenConfig } from '../config'

export const AddCustomerAPI = (body, state) => {
  return new Promise((res, rej) => {
    api.post(`/customer`, body, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const ListCustomersAPI = (query, state) => {
  return new Promise((res, rej) => {
    api.get(`/customer?${query}`, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const UpdateCustomerAPI = (id, state) => {
  return new Promise((res, rej) => {
    api.put(`/customer/${id}`, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const GetCustomerAPI = (id, state) => {
  return new Promise((res, rej) => {
    api.get(`/customer/${id}`, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const AddTagToCustomerAPI = (id, body, state) => {
  return new Promise((res, rej) => {
    api.post(`/customer/${id}/tag`, body, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const RemoveTagFromCustomerAPI = (id, tagId, state) => {
  return new Promise((res, rej) => {
    api.delete(`/customer/${id}/tag/${tagId}`, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const AddCommentAPI = (body, state) => {
  return new Promise((res, rej) => {
    api.post(`/customer/comment`, body, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const UpdateCustomerWithFileAPI = (id, body, state) => {
  return new Promise((res, rej) => {
    api.put(`/customer/${id}/file`, body, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const AddSpendingAPI = (body, state) => {
  return new Promise((res, rej) => {
    api.post(`/customer/spending`, body, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const GetAnalyticsAPI = (query, state) => {
  return new Promise((res, rej) => {
    api.get(`/customer/crm?${query}`, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const ListTagsAPI = (query, state) => {
  return new Promise((res, rej) => {
    api.get(`/tag?${query}`, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const ListTop5TagsAPI = (query, state) => {
  return new Promise((res, rej) => {
    api.get(`/tag/top5?${query}`, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}