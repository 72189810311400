import React, { useEffect, useState } from 'react'
import { FormCheck, Table } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { Spinner } from 'reactstrap'
import TablePagination from '../../../../components/common/Pagination'
import { LIST_CUSTOMERS } from '../../../../store/crm/actionTypes'
import { ListCustomersAPI } from '../../../../store/crm/calls'
import { checkIfLoading } from '../../../../store/functions'

const TagCustomers = ({ tag }) => {
    const { workspace, auth } = useSelector(state => ({
        workspace: state?.workspace?.workspace,
        auth: state?.auth
    }))
    const [loading, setLoading] = useState(false)
    const [customers, setCustomers] = useState(null)

    const [query, setQuery] = useState({
        page: 1,
        limit: 5
    })

    const onPageChange = (page) => {
        setQuery({
            ...query,
            page
        })
    }

    useEffect(async () => {
        const queryString = new URLSearchParams({ ...query, tag: tag?._id, workspace: workspace?._id }).toString()
        if (tag) {
            setLoading(true)
            const customers = await ListCustomersAPI(queryString, { auth })
            setLoading(false)
            setCustomers(customers?.data?.data)
        }
    }, [tag])

    const renderCustomers = (customers) => {
        console.log(customers)
        if (loading) return <tr><td colSpan={6} className="text-center"><Spinner size="md" className="m-auto text-center" color="success" /></td></tr>
        if (customers && customers?.list?.length > 0) {
            return customers?.list?.map(cust => {
                return <tr>
                    <td><FormCheck /></td>
                    <td>{cust?.name}</td>
                    <td>{cust?.phone}</td>
                    <td>{cust?.email}</td>
                    <td>{cust?.amount}</td>
                    <td>{new Date(cust?.createdAt).toLocaleDateString()}</td>
                </tr>
            })
        } else {
            return <tr><td colSpan={6}>No customers found</td></tr>
        }
    }

    return (
        <div className="customers-table">
            <h5>{customers?.page_details?.total_items} clients tagged ‘{tag?.name}’</h5>
            <Table striped hover>
                <thead>
                    <tr>
                        <th><FormCheck /></th>
                        <th>Name</th>
                        <th>Phone Number</th>
                        <th>Email</th>
                        <th>Amount Spent</th>
                        <th>Date Added</th>
                    </tr>
                </thead>
                <tbody>
                    {renderCustomers(customers)}
                </tbody>
            </Table>
            <TablePagination count={customers?.page_details?.total_items} limit={query.limit} loading={loading} page={query.page} setPage={onPageChange} />
        </div>
    )
}

export default TagCustomers