import React from "react";
import { useLocation } from "react-router-dom";

export const checkIfLoading = (store, ...actionsToCheck) =>
  store.ui.loader.actions.some(action => actionsToCheck.includes(action.name));

export function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function getFirst2chars(name) {
  let namesplit = name?.trim().split(' ')
  let chars = 'UN'
  if (namesplit?.length === 1) {
    chars = namesplit[0][0]
  } else if (namesplit?.length === 2) {
    chars = namesplit[0][0] + namesplit[1][0]
  }
  return chars
}

export function getDayName(date) {
  var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  var d = new Date(date);
  return days[d.getDay()]
}

export const stringToHTML = function (str) {
  var parser = new DOMParser();
  var doc = parser.parseFromString(str, 'text/html');
  console.log(doc)
  return doc.body;
};