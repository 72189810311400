import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalHeader, ModalBody, Spinner } from 'reactstrap';
import { updatePassword } from '../../../../store/actions';
import { UPDATE_PASSWORD } from '../../../../store/auth/actionTypes';
import { checkIfLoading } from '../../../../store/functions';

function ChangePasswordModal({ modal, toggle }) {
    // ** states
    const [oldPassword, setOldpassword] = useState(null)
    const [newPassword, setNewpassword] = useState(null)
    const [confirmPassword, setConfirm] = useState(null)

    const dispatch = useDispatch()

    // ** function to handle toggle search result modal

    const { changeLoading } = useSelector(state => ({
        changeLoading: checkIfLoading(state, UPDATE_PASSWORD)
    }))

    const onSubmit = () => {
        dispatch(updatePassword({ oldPassword, newPassword, confirmPassword }, toggle))
    }

    return (
        <>
            <Modal isOpen={modal} toggle={toggle} centered={true}>
                <ModalHeader className='px-4' toggle={toggle}></ModalHeader>
                <ModalBody className='pt-1 pb-3'>
                    <h3 className='mb-3 text-center'>Change your password</h3>
                    <div style={{ width: "80%" }} className="m-auto">
                        <div className='mb-3'>
                            <label>Old password</label>
                            <input type='password' onChange={(e) => setOldpassword(e.target.value)} className='form-control custom-border' placeholder='Enter your old password' />
                        </div>
                        <div className='mb-3'>
                            <label>New password</label>
                            <input type='password' onChange={(e) => setNewpassword(e.target.value)} className='form-control custom-border' placeholder='Enter your new password' />
                        </div>
                        <div>
                            <label>Confirm password</label>
                            <input type='password' onChange={(e) => setConfirm(e.target.value)} className='form-control custom-border' placeholder='Confirm password' />
                        </div>
                        <button type='submit' className='btn click-btn green-btn btn-block mt-2' onClick={onSubmit}>
                            {
                                changeLoading ? <Spinner size="sm" className="m-auto text-center" color="light" /> : "Update Password"
                            }
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        </>
    )
}

export default ChangePasswordModal