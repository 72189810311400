import React, { useEffect, useState } from 'react'
import { FormControl, InputGroup } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import TablePagination from '../../../../components/common/Pagination';
import { cancelSubscription, listWorkspaceBillings } from '../../../../store/actions'
import { checkIfLoading } from '../../../../store/functions';
import { CANCEL_WORKSPACE_SUBSCRIPTION, LIST_WORKSPACE_BILLINGS } from '../../../../store/workspace/actionTypes';

const BillingTab = ({ workspace, tab }) => {
    const history = useNavigate()
    const dispatch = useDispatch()
    const [query, setQuery] = useState({
        page: 1,
        limit: 5
    })

    const onPageChange = (page) => {
        setQuery({
            ...query,
            page
        })
    }

    const { billings, loading, count, cancelSubLoading } = useSelector(state => ({
        billings: state?.workspace?.billings?.list,
        loading: checkIfLoading(state, LIST_WORKSPACE_BILLINGS),
        count: state?.workspace?.billings?.page_details?.total_items,
        cancelSubLoading: checkIfLoading(state, CANCEL_WORKSPACE_SUBSCRIPTION)
    }))

    useEffect(() => {
        if (tab === 'billing') {
            dispatch(listWorkspaceBillings(query))
        }
    }, [tab, query.page])

    const renderBillings = () => {
        if (loading) return <tr><td colSpan={4} className="text-center"><Spinner size="sm" className="m-auto text-center" color="success" /></td></tr>
        if (billings && billings?.length > 0) {
            return billings?.map(b => (
                <tr>
                    <td>{new Date(b?.createdAt).toLocaleDateString()}</td>
                    <td>{b?.plan?.type ? String(b?.plan?.type[0]).toUpperCase() + String(b?.plan?.type).substring(1) : ""}</td>
                    <td>{b?.transactionId}</td>
                    <td>{b?.price} {b?.currency}</td>
                </tr>
            ))
        }
    }

    return (
        <div className='row'>
            <div className='col-md-8'>
                <h4>Plan & Billing</h4>
                <div className='card'>
                    <span className='text-muted'>Your current plan</span>
                    {
                        workspace?.subscription ? <div className='d-flex justify-content-between'>
                            <div className='subscription-details mt-3'>
                                <h2 className='text-orange'>{String(workspace?.subscription?.type[0]).toUpperCase() + workspace?.subscription?.type?.substring(1)}</h2>
                                <ul>
                                    <li>Unlimited access to all our SEO tools.</li>
                                    <li>1.000+ webpages.</li>
                                    <li>10.000+ contacts.</li>
                                </ul>
                                <div className='text-muted mt-2'>Next payment: €75.99 EUR on Jan 1, 2022</div>
                            </div>
                            <div className='d-flex flex-column'>
                                <button onClick={() => history('/settings/change-plan')} className='btn click-btn green-btn mb-2'>Change plan</button>
                                <button onClick={() => dispatch(cancelSubscription(workspace?.subscription?._id))} className='btn btn-outline-secondary'>
                                    { cancelSubLoading ? <Spinner size="sm" className="m-auto text-center" color="light" /> : "Cancel Subscription" }
                                </button>
                            </div>
                        </div> : <div className='d-flex align-items-center justify-content-between'>
                            <span>You are not subscribed</span>
                            <button onClick={() => history('/settings/change-plan')} className='btn click-btn green-btn mb-2'>Subscribe plan</button>
                        </div>
                    }
                    <hr />
                    <div className='d-flex justify-content-between'>
                        <div>
                            <div className='text-muted'>Payment method</div>
                            <h6> Credit Card   **** 6789</h6>
                        </div>
                        <button className='btn btn-outline-secondary'>Change Card</button>
                    </div>
                </div>
                <div className='card mt-3'>
                    <h4>Billing history</h4>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Type</th>
                                <th>Transaction ID</th>
                                <th>Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            {renderBillings()}
                        </tbody>
                    </table>
                    <TablePagination count={count} limit={query.limit} loading={loading} page={query.page} setPage={onPageChange} />
                </div>
            </div>
        </div>
    )
}

export default BillingTab