import React from 'react'

function Tabs({handleFilterData, tab}) {
    return (
        <div className='tabs'>
            <button type='button' className={tab === 1 ? 'btn active' : 'btn'} value="all" onClick={handleFilterData}>
                All
            </button>
            <button type='button' className={tab === 2 ? 'btn active' : 'btn'} value="blog-content" onClick={handleFilterData}>
                Blog Content
            </button>
            <button type='button' className={tab === 3 ? 'btn active' : 'btn'} value="website-copy" onClick={handleFilterData}>
                Website Copy & SEO
            </button>
            <button type='button' className={tab === 4 ? 'btn active' : 'btn'} value="social-media" onClick={handleFilterData}>
                Social Media & Ads
            </button>
            <button type='button' className={tab === 5 ? 'btn active' : 'btn'} value="marketing" onClick={handleFilterData}>
                Marketing
            </button>
            <button type='button' className={tab === 6 ? 'btn active' : 'btn'} value="more-tools" onClick={handleFilterData}>
                More Tools
            </button>
        </div>
    )
}

export default Tabs