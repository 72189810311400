import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  AUTH_GOOGLE,
  AUTH_GOOGLE_SUCCESS,
  DELETE_USER,
  DELETE_USER_SUCCESS,
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  GET_USER_DATA,
  GET_USER_DATA_SUCCESS,
  LIST_ANALYTICS,
  LIST_ANALYTICS_SUCCESS,
  LIST_LANGUAGES,
  LIST_LANGUAGES_SUCCESS,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  UPDATE_EMAIL,
  UPDATE_EMAIL_SUCCESS,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_USER_DATA,
  UPDATE_USER_DATA_SUCCESS,
  VERIFY_USER,
  VERIFY_USER_SUCCESS
} from "./actionTypes"

export const loginUser = (user, history) => {
  return {
    type: LOGIN_USER,
    payload: { user, history },
  }
}

export const loginUserSuccess = (data) => {
  return {
    type: LOGIN_USER_SUCCESS,
    payload: data
  }
}

export const registerUser = (user, history) => {
  return {
    type: REGISTER_USER,
    payload: { user, history }
  }
}

export const registerUserSuccess = (data) => {
  return {
    type: REGISTER_USER_SUCCESS,
    payload: data
  }
}

export const verifyUser = (user, history) => {
  return {
    type: VERIFY_USER,
    payload: { user, history }
  }
}

export const verifyUserSuccess = (data) => {
  return {
    type: VERIFY_USER_SUCCESS,
    payload: data
  }
}

export const getUserData = () => {
  return {
    type: GET_USER_DATA,
    payload: {}
  }
}

export const getUserDataSuccess = (data) => {
  return {
    type: GET_USER_DATA_SUCCESS,
    payload: data
  }
}

export const updateUserData = (data) => {
  return {
    type: UPDATE_USER_DATA,
    payload: { data }
  }
}

export const updateUserDataSuccess = (data) => {
  return {
    type: UPDATE_USER_DATA_SUCCESS,
    payload: data
  }
}

export const updatePassword = (data, toggle) => {
  return {
    type: UPDATE_PASSWORD,
    payload: { data, toggle }
  }
}

export const updatePasswordSuccess = (data) => {
  return {
    type: UPDATE_PASSWORD_SUCCESS,
    payload: data
  }
}

export const deleteUser = () => {
  return {
    type: DELETE_USER,
    payload: {}
  }
}

export const deleteUserSuccess = () => {
  return {
    type: DELETE_USER_SUCCESS,
    payload: {}
  }
}

export const logoutUser = (history) => {
  return {
    type: LOGOUT_USER,
    payload: { history },
  }
}

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {}
  }
}

export const authGoogle = () => {
  return {
    type: AUTH_GOOGLE,
    payload: {},
  }
}

export const authGoogleSuccess = () => {
  return {
    type: AUTH_GOOGLE_SUCCESS,
    payload: {}
  }
}

export const forgetPassword = (email, history) => {
  return {
    type: FORGET_PASSWORD,
    payload: { email, history },
  }
}

export const forgetPasswordSuccess = () => {
  return {
    type: FORGET_PASSWORD_SUCCESS,
    payload: {}
  }
}

export const resetPassword = (data, history) => {
  return {
    type: RESET_PASSWORD,
    payload: { data, history },
  }
}

export const resetPasswordSuccess = () => {
  return {
    type: RESET_PASSWORD_SUCCESS,
    payload: {}
  }
}

export const listAnalytics = () => {
  return {
    type: LIST_ANALYTICS,
    payload: {},
  }
}

export const listAnalyticsSuccess = () => {
  return {
    type: LIST_ANALYTICS_SUCCESS,
    payload: {}
  }
}

export const listLanguages = () => {
  return {
    type: LIST_LANGUAGES,
    payload: {},
  }
}

export const listLanguagesSuccess = (data) => {
  return {
    type: LIST_LANGUAGES_SUCCESS,
    payload: data
  }
}

export const updateEmail = (data, toggle, history) => {
  return {
    type: UPDATE_EMAIL,
    payload: { data, toggle, history },
  }
}

export const updateEmailSuccess = (data) => {
  return {
    type: UPDATE_EMAIL_SUCCESS,
    payload: data
  }
}