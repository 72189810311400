import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import MarketingNav from './Components/MarketingNav'
import CrmNav from './Components/Nav'
import NewAutomationTab from './Components/NewAutomationTab'
import RelatedDocuments from './Components/RelatedDocuments'
import TimeLine from './Components/Timeline'

function CustomerProfile() {
  const dispatch = useDispatch()
  const history = useNavigate()

  return (
    <div className='container-fluid customer-profile page'>
      <div>
        <i class="fas fa-arrow-left fa-2x text-green" style={{ cursor: "pointer" }} onClick={() => history('/crm')}></i>
      </div>
      <div className='row mt-5'>
        <div className='col-md-5'>
          <div className='row mb-3'>
            <div className='col-md-8'>
              <div className='card'>
                <h4>Ryan Gosling</h4>
                <div className='d-flex'>
                  <i class="fas fa-map-marker-alt"></i>
                  <span>New York City, US</span>
                </div>
                <div>
                  Added: <b>Aug 9, 2021</b>
                </div>
              </div>
            </div>
            <div className='col-md-4'>
              <div className='card'>
                <button className='btn btn-block btn-sm btn-outline-info'>Update</button>
                <button className='btn btn-block btn-sm btn-outline-secondary'>Unsubscribe</button>
                <button className='btn btn-block btn-sm btn-outline-danger'>Delete</button>
              </div>
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-md-12'>
              <div className='card'>
                <div className='d-flex justify-content-between align-items-center'>
                  <h6>Details</h6>
                  <Link to={'#'}>Edit details</Link>
                </div>
                <div>
                  <div className='d-flex'>
                    <label className='col-md-3'>Email:</label>
                    <span>ryangosling49@gmail.com</span>
                  </div>
                  <div className='d-flex'>
                    <label className='col-md-3'>Phone:</label>
                    <span>ryangosling49@gmail.com</span>
                  </div>
                  <div className='d-flex'>
                    <label className='col-md-3'>Address:</label>
                    <span>ryangosling49@gmail.com</span>
                  </div>
                  <div className='d-flex'>
                    <label className='col-md-3'>ZIP Code:</label>
                    <span>ryangosling49@gmail.com</span>
                  </div>
                  <div className='d-flex'>
                    <label className='col-md-3'>Birthdate:</label>
                    <span>ryangosling49@gmail.com</span>
                  </div>
                  <div className='d-flex'>
                    <label className='col-md-3'>Gender:</label>
                    <span>ryangosling49@gmail.com</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-md-12'>
              <div className='card'>
                <div className='d-flex justify-content-between align-items-center'>
                  <h6>Tags</h6>
                  <Link to={'#'}>Edit tags</Link>
                </div>
                <div className='d-flex'>

                </div>
              </div>
            </div>
          </div>
          <div className='row mb-3'>
            <div className='col-md-12'>
              <div className='card'>
                <div className='d-flex justify-content-between align-items-center mb-3'>
                  <h6>Description</h6>
                  <Link to={'#'}>Edit description</Link>
                </div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nibh lectus risus, varius donec tellus cursus augue augue congue. Metus, nulla amet fermentum eu mattis arcu volutpat. Varius donec tellus cursus augue augue congue. Metus, nulla amet fermentum eu mattis arcu volutpat.</p>
              </div>
            </div>
          </div>
        </div>
        <div className='col-md-7'>
          <RelatedDocuments />
          <TimeLine />
        </div>
      </div>
    </div>
  )
}

export default CustomerProfile