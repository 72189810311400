import api from '../apis/api'
import { tokenConfig } from '../config'

export const LoginAPI = (body) => {
  return new Promise((res, rej) => {
    api.post(`/auth/login`, body)
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const RegisterAPI = (body) => {
  return new Promise((res, rej) => {
    api.post(`/auth/register`, body)
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const VerifyAPI = (body) => {
  return new Promise((res, rej) => {
    api.post(`/auth/verify`, body)
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const getUserDataAPI = (state) => {
  return new Promise((res, rej) => {
    api.get(`/auth/profile`, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const updateProfileAPI = (data, state) => {
  return new Promise((res, rej) => {
    api.put(`/auth/profile`, data, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const updatePasswordAPI = (data, state) => {
  return new Promise((res, rej) => {
    api.put(`/auth/password`, data, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const forgetPasswordAPI = (data) => {
  return new Promise((res, rej) => {
    api.post(`/auth/forget-password`, data)
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const resetPasswordAPI = (data) => {
  return new Promise((res, rej) => {
    api.post(`/auth/reset-password`, data)
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const updateEmailAPI = (data, state) => {
  return new Promise((res, rej) => {
    api.put(`/auth/email`, data, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const listLanguagesAPI = (data, state) => {
  return new Promise((res, rej) => {
    api.get(`/auth/languages`, data, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const authGoogleAPI = (data, state) => {
  return new Promise((res, rej) => {
    api.get(`/auth/google`, data, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const listAnalyticsAccountAPI = (data, state) => {
  return new Promise((res, rej) => {
    api.get(`/auth/google/analytics`, data, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const deleteUserAPI = (state) => {
  return new Promise((res, rej) => {
    api.post(`/auth/delete`, {}, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}