import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const Campaigns = () => {
    return (
        <div className='container page mt-5'>
            Campaigns
        </div>
    )
}

export default Campaigns