import moment from 'moment'
import React from 'react'
import { Spinner } from 'reactstrap'
import BarChart from '../../../components/charts/BarChart'

function Visitors({ unique, timeOnSiteAvg, pageViewsAvg, loading }) {
  return (
    <div className='visitors analytics card'>
      {
        loading ? <Spinner size="md" className="m-auto text-center" color="success" /> : <div className='row'>
          <div className='col-xl-6 unique'>
            <h2 className='number'>{(unique)?.toLocaleString(undefined, { maximumFractionDigits: 2 })} <i className="fas fa-arrow-up text-success ml-2"></i></h2>
            <p>Unique Site Visitors</p>
            <BarChart name="Unique Site Visitors" />
          </div>
          <div className='col-xl-6 average'>
            <h6>Avarage visitor session</h6>
            <div className='row justify-content-between'>
              <div className='col-xl-6 average-con'>
                <div>
                  <i className="fas fa-clock mr-2"></i>
                </div>
                <div>
                  <p>Time on site</p>
                  <span>{moment.utc(timeOnSiteAvg * 1000).format('mm:ss')}</span>
                </div>
              </div>
              <div className='col-xl-6 average-con'>
                <div>
                  <i className="fas fa-file-alt mr-2"></i>
                </div>
                <div>
                  <p>Page views</p>
                  <span>{Number(pageViewsAvg).toFixed(2)}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default Visitors