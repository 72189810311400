import {
  ADD_CUSTOMER,
  ADD_CUSTOMER_COMMENT,
  ADD_CUSTOMER_COMMENT_SUCCESS,
  ADD_CUSTOMER_SUCCESS,
  ADD_CUSTOMER_TASK,
  ADD_CUSTOMER_TASK_SUCCESS,
  GET_ANALYTICS,
  GET_ANALYTICS_SUCCESS,
  GET_CUSTOMER,
  GET_CUSTOMER_SUCCESS,
  GET_EMAILS,
  GET_EMAILS_SUCCESS,
  GET_TIMELINE,
  GET_TIMELINE_SUCCESS,
  LIST_TOP_5_TAGS,
  LIST_TOP_5_TAGS_SUCCESS,
  LIST_CUSTOMERS,
  LIST_CUSTOMERS_SUCCESS,
  LIST_TAGS,
  LIST_TAGS_SUCCESS,
  LIST_TAG_CUSTOMERS,
  LIST_TAG_CUSTOMERS_SUCCESS,
} from "./actionTypes"

export const addCustomer = (data, toggle) => {
  return {
    type: ADD_CUSTOMER,
    payload: { data, toggle },
  }
}

export const addCustomerSuccess = (data) => {
  return {
    type: ADD_CUSTOMER_SUCCESS,
    payload: data
  }
}

export const addComment = (comment, history) => {
  return {
    type: ADD_CUSTOMER_COMMENT,
    payload: { comment, history },
  }
}

export const addCommentSuccess = (data) => {
  return {
    type: ADD_CUSTOMER_COMMENT_SUCCESS,
    payload: data
  }
}

export const addTask = (task, history) => {
  return {
    type: ADD_CUSTOMER_TASK,
    payload: { task, history },
  }
}

export const addTaskSuccess = (data) => {
  return {
    type: ADD_CUSTOMER_TASK_SUCCESS,
    payload: data
  }
}

export const getCrmAnalytics = (history) => {
  return {
    type: GET_ANALYTICS,
    payload: { history },
  }
}

export const getCrmAnalyticsSuccess = (data) => {
  return {
    type: GET_ANALYTICS_SUCCESS,
    payload: data
  }
}

export const getCustomer = (id, history) => {
  return {
    type: GET_CUSTOMER,
    payload: { id, history },
  }
}

export const getCustomerSuccess = (data) => {
  return {
    type: GET_CUSTOMER_SUCCESS,
    payload: data
  }
}

export const getEmails = (user, history) => {
  return {
    type: GET_EMAILS,
    payload: { user, history },
  }
}

export const getEmailsSuccess = (data) => {
  return {
    type: GET_EMAILS_SUCCESS,
    payload: data
  }
}

export const getTimeline = (user, history) => {
  return {
    type: GET_TIMELINE,
    payload: { user, history },
  }
}

export const getTimelineSuccess = (data) => {
  return {
    type: GET_TIMELINE_SUCCESS,
    payload: data
  }
}

export const listTop5Tags = (query) => {
  return {
    type: LIST_TOP_5_TAGS,
    payload: { query },
  }
}

export const listTop5TagsSuccess = (data) => {
  return {
    type: LIST_TOP_5_TAGS_SUCCESS,
    payload: data
  }
}

export const listCustomers = (query, history) => {
  return {
    type: LIST_CUSTOMERS,
    payload: { query, history },
  }
}

export const listCustomersSuccess = (data) => {
  return {
    type: LIST_CUSTOMERS_SUCCESS,
    payload: data
  }
}

export const listTags = (query) => {
  return {
    type: LIST_TAGS,
    payload: { query },
  }
}

export const listTagsSuccess = (data) => {
  return {
    type: LIST_TAGS_SUCCESS,
    payload: data
  }
}

export const listTagCustomers = (user, history) => {
  return {
    type: LIST_TAG_CUSTOMERS,
    payload: { user, history },
  }
}

export const listTagCustomersSuccess = (data) => {
  return {
    type: LIST_TAG_CUSTOMERS_SUCCESS,
    payload: data
  }
}