import React, { useEffect, useState } from 'react'
import { FormControl, InputGroup } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { Spinner } from 'reactstrap'
import TablePagination from '../../../../components/common/Pagination'
import { competitorsBacklinks } from '../../../../store/actions'

const CompetitorsBacklinksTable = ({ loading, backlinks, details, competitors }) => {
    const dispatch = useDispatch()
    const [filters, setFilters] = useState({
        page: 1,
        limit: 10
    })

    const [showEmails, setShowEmails] = useState(null)

    const setPage = (page) => {
        setFilters({
            ...filters,
            page
        })
    }

    useEffect(() => {
        if (competitors && competitors?.length > 0) {
            const query = new URLSearchParams(filters).toString()
            dispatch(competitorsBacklinks(query))
        }
    }, [competitors, filters.page])

    const renderEmails = (backlink) => {
        return backlink?.emails?.slice(0, 6)?.map(email => {
            return <li>{email?.email}</li>
        })
    }

    console.log(showEmails)

    const renderBacklinks = () => {
        if (loading) return <Spinner size="sm" className="m-auto text-center" color="success" />
        if (backlinks && backlinks?.length > 0) {
            return backlinks?.map(bk => {
                return <>
                    <tr>
                        <td onClick={() => setShowEmails(showEmails === bk?._id ? null : bk?._id)}><span className={`click-square ${showEmails === bk?._id ? 'active' : ""}`}></span></td>
                        <td>{bk?.domain}</td>
                        <td>{bk?.targetDomain}</td>
                        <td>{bk?.domainAuthority}</td>
                    </tr>
                    <tr style={{ display: showEmails === bk?._id ? "block" : "none" }}>
                        <td colSpan={4} className="border-0">
                            <ul className='list-unstyles'>{renderEmails(bk)}</ul>
                        </td>
                    </tr>
                </>
            })
        } else {
            return <tr><td colSpan={4}>No backlinks found</td></tr>
        }
    }

    return (
        <div>
            <div className='row justify-content-between align-items-end'>
                <div className='col-md-4'>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1" className='bg-transparent'><i class="fas fa-search"></i></InputGroup.Text>
                        <FormControl
                            placeholder="Search"
                            aria-label="Search"
                            aria-describedby="basic-addon1"
                        />
                    </InputGroup>
                </div>
                <div className='col-md-6'>
                    <div className='add-competitor' style={{ height: 'auto' }}>
                        <div className='row align-items-center m-0'>
                            <div className='col-md-8'>
                                <input className='form-control' placeholder='Enter website' />
                            </div>
                            <div className='col-md-4'>
                                <button className='btn click-btn orange-btn btn-block py-1 px-1'>Add Media Target</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-12'>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Who is linking to your competitors?</th>
                                <th>it is linking to:</th>
                                <th>Domain Authority</th>
                            </tr>
                        </thead>
                        <tbody>
                            {renderBacklinks()}
                        </tbody>
                    </table>
                </div>
                <div className='col-md-12 mt-3'>
                    <TablePagination count={details?.total_items} page={filters?.page} setPage={setPage} limit={filters?.limit} loading={loading} />
                </div>
            </div>
        </div>
    )
}

export default CompetitorsBacklinksTable