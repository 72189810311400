import React from 'react'
import { Spinner } from 'reactstrap'

function OrganicSearch({ data, loading }) {
    // ** render data 
    const renderData = data?.length > 0 ? data.map((ele, index) =>
        <li key={index}>
            <span>{ele.keyword}</span>
            <b>{ele.percent}%</b>
        </li>
    ) : 'No data found'

    return (
        <div className='organic-search card'>
            <h4 className='mb-1'>Organic Search</h4>
            <span className='mb-3 text-muted'><small>Traffic by Organic Keywords</small></span>
            {
                loading ? <Spinner size="md" className="m-auto text-center" color="success" /> : <div className='data-list'>
                    {renderData}
                </div>
            }
        </div>
    )
}

export default OrganicSearch