import React, { useState, useEffect } from 'react'
import Tabs from './Tabs'
import Cards from './Cards'
import { contentGeneratorTabs } from '../../../store/data'

function index() {
    // ** states
    const [tab, setTab] = useState(1)
    const [filterData, setFilterData] = useState()

    const data = contentGeneratorTabs

    // ** use effect to set all data in default
    useEffect(() => {
        setFilterData(data)
    }, [])

    // ** function to handle filter data
    const handleFilterData = (e) => {
        switch (e.target.value) {
            case "all":
                setTab(1)
                setFilterData(data)
                break;
            case "blog-content":
                setTab(2)
                setFilterData(data.filter(ele => ele.category === "blog-content"))
                break;
            case "website-copy":
                setTab(3)
                setFilterData(data.filter(ele => ele.category === "website-copy"))
                break;
            case "social-media":
                setTab(4)
                setFilterData(data.filter(ele => ele.category === "social-media"))
                break;
            case "marketing":
                setTab(5)
                setFilterData(data.filter(ele => ele.category === "marketing"))
                break;
            case "more-tools":
                setTab(6)
                setFilterData(data.filter(ele => ele.category === "more-tools"))
                break;
        }
    }

    return (
        <div className='contentGenerator'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-12 text-center title'>
                        <h2>Hello Jake! What are you creating today?</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dolor, at vel molestie arcu morbi sed.</p>
                    </div>
                    <div className='col-12 text-center'>
                        <Tabs handleFilterData={handleFilterData} tab={tab} />
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='row justify-content-center cards'>
                    <Cards filterData={filterData} />
                </div>
            </div>
        </div>
    )
}

export default index