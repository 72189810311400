import React, { useState } from 'react'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'

function LangDropdown() {
    // ** states
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [selectedLang, setSelectedLang] = useState(
        <>
            <img src={`${process.env.PUBLIC_URL}/assets/images/flags/flag_en.png`} alt='en-flag' />
            EN
        </>
    )

    // ** function to handle toggle of dropdown
    const toggle = () => setDropdownOpen((prevState) => !prevState);

    // ** function to show selected choice of language
    const showSelected = (e) => {
        if (e.target.name === "en") {
            setSelectedLang(
                <>
                    <img src={`${process.env.PUBLIC_URL}/assets/images/flags/flag_en.png`} alt='en-flag' />
                    EN
                </>
            )
        } else {
            setSelectedLang(
                <>
                    <img src={`${process.env.PUBLIC_URL}/assets/images/flags/flag-it.png`} alt='it-flag' />
                    IT
                </>
            )
        }
    }

    return (
        <div className="lang-con">
            <Dropdown className='custom-dropdown' isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle caret>
                    {selectedLang}
                </DropdownToggle>
                <DropdownMenu right={true}>
                    <span>Choose Language</span>
                    <DropdownItem>
                        <button className="btn" name='en' onClick={showSelected}>
                            <img src={`${process.env.PUBLIC_URL}/assets/images/flags/flag_en.png`} alt='en-flag' />
                            English
                        </button>
                    </DropdownItem>
                    <DropdownItem>
                        <button className="btn" name='it' onClick={showSelected}>
                            <img src={`${process.env.PUBLIC_URL}/assets/images/flags/flag-it.png`} alt='it-flag' />
                            Italian
                        </button>
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </div>
    )
}

export default LangDropdown