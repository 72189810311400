import React, { useEffect, useState } from 'react'
import { FormControl, InputGroup } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Modal, Spinner } from 'reactstrap'
import ImageCropModal from '../../../../components/common/ImageCropModal'
import { updateUserData } from '../../../../store/actions'
import { checkIfLoading } from '../../../../store/functions'
import { START_UPLOAD } from '../../../../store/upload/actionTypes'
import ChangePasswordModal from './changePasswordModal'
import UpdateEmailModal from './updateEmailModal'

const AccountTab = () => {
    const [cropModal, setCropModal] = useState(false)
    const [img, setImg] = useState(undefined)
    const [userImage, setUserImage] = useState(null)
    const dispatch = useDispatch()

    const [passwordModal, setPasswordModal] = useState(false);
    const [emailModal, setEmailModal] = useState(false);

    const togglePasswordModal = () => setPasswordModal(!passwordModal);
    const toggleEmailModal = () => setEmailModal(!emailModal);

    const { uploadLoading, user } = useSelector(state => ({
        uploadLoading: checkIfLoading(state, START_UPLOAD),
        user: state.auth?.user
    }))

    const handleCropSubmit = file => {
        setCropModal(false)
        setUserImage(file)
    }

    useEffect(() => {
        if (userImage) dispatch(updateUserData({ image: userImage }))
    }, [userImage])

    const hiddenFileInput = React.useRef(null);

    const handleClick = () => {
        hiddenFileInput.current.click();
    };

    const handleChange = event => {
        const fileUploaded = event.target.files[0];
        setImg(URL.createObjectURL(fileUploaded))
        setTimeout(() => {
            setCropModal(true)
        }, 300)
    };

    return (
        <div className='col-md-8'>
            <span>Here you can see settings that are common amongst all the workspaces.</span>
            <div className='mt-4'>
                <h4>Personal information</h4>
                <div className='card'>
                    <div className='d-flex align-items-center'>
                        {
                            uploadLoading ? <Spinner className="ml-2" size="sm" color="success" /> : <>
                                <img className='img-thumbnail rounded-circle avatar cursor-pointer' onClick={() => handleClick()} src={user?.profilePicture || `${process.env.PUBLIC_URL}/assets/images/avatar.jpg`} alt="workspace logo" />
                                <input type="file" accept="image/*" style={{ display: "none" }} ref={hiddenFileInput} onChange={(e) => handleChange(e)} /></>
                        }
                        <div className='d-inline-block ml-3'>
                            <h5 className='m-0 mb-1'>{user?.fullName}</h5>
                            <span className='btn btn-link m-0 p-0' style={{ fontSize: "14px" }}>Change name</span>
                        </div>
                    </div>
                    <div className='row justify-content-between align-items-center my-3'>
                        <div className='col-md-6'>
                            <label className='text-muted'>Email Address</label>
                            <div><b>{user?.email}</b></div>
                        </div>
                        <button className='btn btn-outline-secondary col-md-4 py-2' onClick={() => setEmailModal(true)}>Change email</button>
                    </div>
                    <div className='row justify-content-between align-items-center'>
                        <div className='col-md-6'>
                            <label className='text-muted'>Password</label>
                            <div><b>****************</b></div>
                        </div>
                        <button className='btn btn-outline-secondary col-md-4 py-2' onClick={() => setPasswordModal(true)}>Change password</button>
                    </div>
                    <span className='text-muted'><small>Last changed on Oct 11, 2021</small></span>
                </div>
            </div>
            <div className='mt-4'>
                <h4>Language</h4>
                <span className='text-muted'>Choose language of your SEO Suite</span>
                <div className='card mt-2'>
                    <label>Account language</label>
                    <select defaultValue={user?.language} className='form-control custom-border'>
                        <option value={'en'}>English</option>
                        <option value={'ar'}>Arabic</option>
                    </select>
                </div>
            </div>
            <div className='mt-4'>
                <h4>Delete Your SEO Suite Account</h4>
                <span><small>Completely remove your current workspace from SEO Suite</small></span>
                <div className='card d-flex mt-2'>
                    <h5 className='d-inline-block'>Delete Your SEO Suite Account</h5>
                    <span>If you want to delete your SEO Suite account, you can do so from the page. <Link to={'#'} className='text-orange'>Delete Account</Link> page.</span>
                </div>
            </div>
            <Modal centered={true} size='lg' isOpen={cropModal} toggle={() => { setCropModal(!cropModal) }}>
                <ImageCropModal handleCropSubmit={handleCropSubmit} img={img} toggle={() => { setCropModal(!cropModal) }} />
            </Modal>
            <ChangePasswordModal modal={passwordModal} toggle={togglePasswordModal} />
            <UpdateEmailModal modal={emailModal} toggle={toggleEmailModal} />
        </div>
    )
}

export default AccountTab