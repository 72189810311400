import {
  ADD_PIPELINE,
  ADD_PIPELINE_SUCCESS,
  ADD_COLUMN,
  ADD_COLUMN_SUCCESS,
  ADD_TAB,
  ADD_TAB_SUCCESS,
  DELETE_COLUMN,
  DELETE_COLUMN_SUCCESS,
  DELETE_PIPELINE_SUCCESS,
  DELETE_TAB,
  DELETE_TAB_SUCCESS,
  GET_PIPELINE,
  GET_PIPELINE_SUCCESS,
  LIST_PIPELINES_SUCCESS,
  UPDATE_COLUMN,
  UPDATE_COLUMN_SUCCESS,
  UPDATE_PIPELINE_SUCCESS,
  UPDATE_TAB,
  UPDATE_TAB_SUCCESS,
  DELETE_PIPELINE,
  LIST_PIPELINES,
  UPDATE_PIPELINE
} from "./actionTypes"

export const listPipelines = () => {
  return {
    type: LIST_PIPELINES,
    payload: {},
  }
}

export const listPipelinesSuccess = (data) => {
  return {
    type: LIST_PIPELINES_SUCCESS,
    payload: data
  }
}

export const getPipeline = (id) => {
  return {
    type: GET_PIPELINE,
    payload: { id },
  }
}

export const getPipelineSuccess = (data) => {
  return {
    type: GET_PIPELINE_SUCCESS,
    payload: data
  }
}

export const addPipeline = (data, toggle) => {
  return {
    type: ADD_PIPELINE,
    payload: { data, toggle },
  }
}

export const addPipelineSuccess = (data) => {
  return {
    type: ADD_PIPELINE_SUCCESS,
    payload: data
  }
}

export const updatePipeline = (id, data) => {
  return {
    type: UPDATE_PIPELINE,
    payload: { id, data },
  }
}

export const updatePipelineSuccess = (data) => {
  return {
    type: UPDATE_PIPELINE_SUCCESS,
    payload: data
  }
}

export const deletePipeline = (id,) => {
  return {
    type: DELETE_PIPELINE,
    payload: { id },
  }
}

export const deletePipelineSuccess = (data) => {
  return {
    type: DELETE_PIPELINE_SUCCESS,
    payload: data
  }
}

export const addColumn = (id, data) => {
  return {
    type: ADD_COLUMN,
    payload: { id, data },
  }
}

export const addColumnSuccess = (data) => {
  return {
    type: ADD_COLUMN_SUCCESS,
    payload: data
  }
}

export const updateColumn = (id, data, toggle) => {
  return {
    type: UPDATE_COLUMN,
    payload: { id, data, toggle },
  }
}

export const updateColumnSuccess = (data) => {
  return {
    type: UPDATE_COLUMN_SUCCESS,
    payload: data
  }
}

export const deleteColumn = (id) => {
  return {
    type: DELETE_COLUMN,
    payload: { id },
  }
}

export const deleteColumnSuccess = (data) => {
  return {
    type: DELETE_COLUMN_SUCCESS,
    payload: data
  }
}

export const addTab = (id, data, toggle) => {
  return {
    type: ADD_TAB,
    payload: { id, data, toggle },
  }
}

export const addTabSuccess = (data) => {
  return {
    type: ADD_TAB_SUCCESS,
    payload: data
  }
}

export const updateTab = (id, data) => {
  return {
    type: UPDATE_TAB,
    payload: { id, data },
  }
}

export const updateTabSuccess = (data) => {
  return {
    type: UPDATE_TAB_SUCCESS,
    payload: data
  }
}

export const deleteTab = (id) => {
  return {
    type: DELETE_TAB,
    payload: { id },
  }
}

export const deleteTabSuccess = (data) => {
  return {
    type: DELETE_TAB_SUCCESS,
    payload: data
  }
}