import React, { useEffect } from 'react'
import AvatarDropdown from './AvatarDropdown'
import LangDropdown from './LangDropdown'
import Notification from './Notification'
import DomianDropdown from './DomianDropdown'
import { getUserData, getWorkspace, listWorkspaces, verifyUser } from '../../../store/actions'
import { useDispatch } from 'react-redux'
import { useQuery } from '../../../store/functions'
import { useNavigate } from 'react-router-dom'

function NavbarIndex() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(listWorkspaces())
    dispatch(getUserData())
    dispatch(getWorkspace())
  }, [])

  return (
    <nav className="navbar">
      <DomianDropdown />
      <Notification />
      <LangDropdown />
      <AvatarDropdown />
    </nav>
  )
}

export default NavbarIndex