import api from '../apis/api'
import { tokenConfig } from '../config'

export const ListWorkspacesAPI = (state) => {
  return new Promise((res, rej) => {
    api.get(`/workspace`, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const GetWorkspaceAPI = (id, state) => {
  return new Promise((res, rej) => {
    api.get(`/workspace/${id}`, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const AddWorkspaceAPI = (body, state) => {
  return new Promise((res, rej) => {
    api.post(`/workspace`, body, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const UpdateWorkspaceAPI = (id, body, state) => {
  return new Promise((res, rej) => {
    api.put(`/workspace/${id}`, body, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const DeleteWorkspaceAPI = (state) => {
  return new Promise((res, rej) => {
    api.get(`/users/me`, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const RegisterDomainAPI = (data, state) => {
  return new Promise((res, rej) => {
    api.put(`/profile`, data, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const InviteCollaboratorAPI = (data, state) => {
  return new Promise((res, rej) => {
    api.put(`/password`, data, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const ListWorkspaceLanguagesAPI = (state) => {
  return new Promise((res, rej) => {
    api.get(`/workspace/languages`, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const ListWorkspaceRegionsAPI = (state) => {
  return new Promise((res, rej) => {
    api.get(`/workspace/countries`)
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const ListWorkspaceBillingsAPI = (id, query, state) => {
  return new Promise((res, rej) => {
    api.get(`/workspace/${id}/billings?${query}`, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const DeleteCollaboratorAPI = (state) => {
  return new Promise((res, rej) => {
    api.post(`/delete`, {}, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const AddSenderEmailAPI = (state) => {
  return new Promise((res, rej) => {
    api.post(`/delete`, {}, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}

export const CancelWorkspaceSubscriptionAPI = (id, state) => {
  return new Promise((res, rej) => {
    api.delete(`/subscribe/${id}`, tokenConfig(state))
      .then(resp => res(resp))
      .catch(err => rej(err?.response?.data?.error?.message || 'Error, Please try again later'))
  })
}