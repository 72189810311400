import React, { useEffect, useState } from 'react'
import AreaChart from '../../../components/charts/AreaChart'
import moment from 'moment'
import { Spinner } from 'reactstrap'

function TrafficHistory({ data, loading }) {
  // ** fake backend database 
  const [trafficData, setTrafficData] = useState([])

  useEffect(() => {
    if (data && !loading) {
      const traffic = []
      data.forEach(e => {
        traffic.push({
          label: moment(e[0], 'M').format('MMMM'),
          value: e[1]
        })
      })
      console.log(traffic)
      setTrafficData(traffic)
    }
  }, [data, loading])

  return (
    <div className='traffic-history card'>
      <h3 className='mb-2'>Traffic History</h3>
      {
        loading ? <Spinner size="md" className="m-auto text-center" color="success" /> : <AreaChart name="Traffic Data" data={trafficData} curveType="smooth" showYaxis={true} />
      }
    </div>
  )
}

export default TrafficHistory