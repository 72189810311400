import { put, select, takeEvery } from "redux-saga/effects"
import { ADD_WORKSPACE, CANCEL_WORKSPACE_SUBSCRIPTION, DELETE_WORKSPACE, GET_WORKSPACE, LIST_WORKSPACES, LIST_WORKSPACE_BILLINGS, LIST_WORKSPACE_LANGUAGES, LIST_WORKSPACE_REGIONS, UPDATE_WORKSPACE, UPDATE_WORKSPACE_LOGO } from "./actionTypes"
import { addWorkspaceSuccess, cancelSubscriptionSuccess, deleteWorkspaceSuccess, getWorkspaceSuccess, listWorkspaceBillingsSuccess, listWorkspaceLanguagesSuccess, listWorkspaceRegionsSuccess, listWorkspacesSuccess, updateWorkspaceLogoSuccess, updateWorkspaceSuccess } from "./actions"
import { AddWorkspaceAPI, CancelWorkspaceSubscriptionAPI, DeleteWorkspaceAPI, GetWorkspaceAPI, ListWorkspaceBillingsAPI, ListWorkspaceLanguagesAPI, ListWorkspaceRegionsAPI, ListWorkspacesAPI, UpdateWorkspaceAPI } from "./calls"
import { setError, setSuccess, startAction, stopAction } from "../actions"
import { uploadImageSaga } from "../upload/saga"
import { START_UPLOAD } from "../upload/actionTypes"

function* listWorkspaces({ type }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const result = yield ListWorkspacesAPI(state)
    console.log(result)
    yield put(listWorkspacesSuccess(result?.data?.data))
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* addWorkspace({ type, payload: { data, history } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const result = yield AddWorkspaceAPI(data, state)
    console.log(result)
    yield put(setSuccess(result?.data?.message))
    yield put(addWorkspaceSuccess(result?.data?.data))
    yield put(getWorkspaceSuccess(result?.data?.data))
    if (history) history('/dashboard')
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* getWorkspace({ type, payload: { id, history } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    id = id || state.workspace.workspace._id
    const result = yield GetWorkspaceAPI(id, state)
    console.log(result)
    localStorage.setItem('workspace', JSON.stringify(result?.data?.data))
    yield put(getWorkspaceSuccess(result?.data?.data))
    if (history) history('/dashboard')
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* deleteWorkspace({ type, payload: { id, history } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const result = yield DeleteWorkspaceAPI(id, state)
    console.log(result)
    yield put(setSuccess(result?.data?.message))
    yield put(deleteWorkspaceSuccess(result?.data?.data))
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* updateWorkspace({ type, payload: { id, data } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const result = yield UpdateWorkspaceAPI(id, data, state)
    console.log(result)
    yield put(setSuccess(result?.data?.message))
    yield put(updateWorkspaceSuccess(result?.data?.data))
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* updateWorkspaceLogo({ type, payload: { image } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    let imageUploaded
    // console.log({ image })
    if (image) {
      const result = yield uploadImageSaga({
        type: START_UPLOAD,
        payload: {
          file: image,
          uploadType: "workspace"
        }
      })
      imageUploaded = result
    }
    const workspace = state.workspace.workspace?._id
    const result = yield UpdateWorkspaceAPI(workspace, { logo: imageUploaded }, state)
    yield put(updateWorkspaceSuccess(result?.data?.data))
  } catch (error) {
    yield put(setError(error))
  } finally {
    yield put(stopAction(type))
  }
}

function* listWorkspaceLanguages({ type }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const result = yield ListWorkspaceLanguagesAPI(state)
    console.log(result)
    yield put(listWorkspaceLanguagesSuccess(result?.data?.data))
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* listWorkspaceRegions({ type }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const result = yield ListWorkspaceRegionsAPI()
    console.log(result)
    yield put(listWorkspaceRegionsSuccess(result?.data?.data))
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* listWorkspaceBillings({ type, payload: { query } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const workspace = state.workspace.workspace?._id
    query = new URLSearchParams(query).toString()
    const result = yield ListWorkspaceBillingsAPI(workspace, query, state)
    console.log(result)
    yield put(listWorkspaceBillingsSuccess(result?.data?.data))
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* cancelSubscription({ type, payload: { id } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const result = yield CancelWorkspaceSubscriptionAPI(id, state)
    console.log(result)
    yield put(cancelSubscriptionSuccess())
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* workspaceSaga() {
  yield takeEvery(LIST_WORKSPACES, listWorkspaces)
  yield takeEvery(ADD_WORKSPACE, addWorkspace)
  yield takeEvery(GET_WORKSPACE, getWorkspace)
  yield takeEvery(DELETE_WORKSPACE, deleteWorkspace)
  yield takeEvery(UPDATE_WORKSPACE, updateWorkspace)
  yield takeEvery(LIST_WORKSPACE_LANGUAGES, listWorkspaceLanguages)
  yield takeEvery(LIST_WORKSPACE_REGIONS, listWorkspaceRegions)
  yield takeEvery(LIST_WORKSPACE_BILLINGS, listWorkspaceBillings)
  yield takeEvery(UPDATE_WORKSPACE_LOGO, updateWorkspaceLogo)
  yield takeEvery(CANCEL_WORKSPACE_SUBSCRIPTION, cancelSubscription)
}

export default workspaceSaga