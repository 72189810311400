import React from 'react'
import { FormControl, InputGroup } from 'react-bootstrap'

const AutomationsTab = () => {
    return (
        <>
            <div>Automations</div>
        </>
    )
}

export default AutomationsTab