import React from "react";

function Posts({ data }) {
  const renderData = () => {
    if (data.length > 0) {
      return data.map(ele =>
        <div className="post" key={ele._id}>
          <div className="img-con">
            <img src={ele?.image} alt="" />
          </div>
          <div className="description">
            <h6>{ele.author}</h6>
            <p className="text">{ele.text}</p>
            <p className="time">Posted at {new Date(ele.date).toLocaleDateString()}</p>
          </div>
        </div>
      )
    } else {
      return <div>No posts found</div>
    }
  }

  return (
    <div className="posts">
      <h2>Posts ({data?.length})</h2>
      {renderData()}
    </div>
  )
}

export default Posts;
