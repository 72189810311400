import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import AddTaskModal from './AddTask'

function TimeLine() {
    const dispatch = useDispatch()
    const history = useNavigate()

    const { user } = useSelector(state => ({
        user: state?.auth?.user
    }))

    return (
        <div className='timeline'>
            <h5>Timeline</h5>
            <hr />
            <div className='position-relative'>
                <div className='text-right'><button className='btn click-btn orange-btn'>Add Task</button></div>
                {/* <AddTaskModal /> */}
            </div>
            <div class="timeline">
                <div class="outer">
                    <div className='add-comment-box mt-3'>
                        <div className='d-flex'>
                            <img className='img-thumbnail rounded-circle avatar timeline-avatar mr-2' src={user?.profilePicture || `${process.env.PUBLIC_URL}/assets/images/avatar.jpg`} alt="user avatar" />
                            <input className='form-control custom-border' placeholder='leave a comment' />
                        </div>
                        <div className='text-right'>
                            <small className='text-muted'>Only you and other team memebers can see comments</small>
                        </div>
                    </div>
                    <div class="card mt-4">
                        <div class="info d-flex justify-content-between">
                            <span className='m-0'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, </span>
                            <span className='text-muted'>12:13 PM</span>
                        </div>
                    </div>
                    <div class="card">
                        <div class="info d-flex justify-content-between">
                            <span className='m-0'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, </span>
                            <span className='text-muted'>12:13 PM</span>
                        </div>
                    </div>
                    <div class="card active">
                        <div class="info d-flex justify-content-between">
                            <span className='m-0'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, </span>
                            <span className='text-muted'>12:13 PM</span>
                        </div>
                    </div>
                    <div className='comment-box mt-3 d-flex align-items-center'>
                        <img className='img-thumbnail rounded-circle avatar timeline-avatar mr-2' src={user?.profilePicture || `${process.env.PUBLIC_URL}/assets/images/avatar.jpg`} alt="user avatar" />
                        <div>
                            <div className='d-flex align-items-center'>
                                <h6 className='m-0 mr-3'>Diane Larsson</h6>
                                <span className='text-muted'>12:13 PM</span>
                            </div>
                            <p className='m-0'>A very very important text about bussiness</p>
                        </div>
                    </div>
                    <div className='day'>Today</div>
                    <div class="card active">
                        <div class="info d-flex justify-content-between">
                            <span className='m-0'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, </span>
                            <span className='text-muted'>12:13 PM</span>
                        </div>
                    </div>
                    <div class="card">
                        <div class="info d-flex justify-content-between">
                            <span className='m-0'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, </span>
                            <span className='text-muted'>12:13 PM</span>
                        </div>
                    </div>
                    <div class="card task-assigned">
                        <div class="info d-flex justify-content-between">
                            <div className='d-flex'>
                                <img className='img-thumbnail rounded-circle avatar timeline-avatar mr-2' src={user?.profilePicture || `${process.env.PUBLIC_URL}/assets/images/avatar.jpg`} alt="user avatar" />
                                <div>
                                    <span>Task assigned to Diane Larsson by Maria Kovalchuk</span>
                                    <div className='text-muted'>Contact about the possible sales & new informarmation regarding cats.</div>
                                    <div>When: <b>Dec 23, 2021</b></div>
                                    <button className='btn btn-outline-secondary btn-sm mt-2'>Go to project</button>
                                </div>
                            </div>
                            <div className='text-right' style={{ width: "70px" }}>
                                <span className='text-muted'>12:13 PM</span>
                            </div>
                        </div>
                    </div>
                </div>
                <hr className='mt-4' />
                <div className='text-center'>
                    <button className='btn click-btn green-btn'>Load More Timeline <i class="fas fa-redo fa-sm ml-2" style={{ transform: "scaleX(-1)" }}></i></button>
                </div>
                <hr />
                <div>
                    <h6>Email Activity</h6>
                    <hr />
                    <table className='table'>
                        <thead>
                            <tr className='text-muted'>
                                <th>Email Received</th>
                                <th>Engagement</th>
                                <th>Date</th>
                                <th>Location</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default TimeLine