import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const CrmNav = () => {
    const location = useLocation()
    const navigate = useNavigate()

    return (
        <div className='container crm-nav mt-5'>
            <div className='row'>
                <div className='col-md-12 d-flex'>
                    <ul className='list-group m-auto text-center list-group-horizontal-lg'>
                        <li onClick={() => navigate('/crm')} className={`${location.pathname === '/crm' ? 'active' : ""}`}><i class="fas fa-tachometer-alt"></i><h6>Dashboard</h6></li>
                        <li onClick={() => navigate('/current-customers')} className={`${location.pathname === '/current-customers' ? 'active' : ""}`}><i class="fas fa-users"></i><h6>Customers & Leads</h6></li>
                        <li onClick={() => navigate('/sales-management')} className={`${location.pathname === '/sales-management' ? 'active' : ""}`}><i class="fas fa-balance-scale"></i><h6>Sales Management</h6></li>
                        <li onClick={() => navigate('/marketing')} className={`${location.pathname === '/marketing' ? 'active' : ""}`}><i class="fas fa-cogs"></i><h6>Marketing Automation</h6></li>
                        <li onClick={() => navigate('/reminders')} className={`${location.pathname === '/reminders' ? 'active' : ""}`}><i class="fas fa-bell"></i><h6>Reminds</h6></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default CrmNav