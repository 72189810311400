import React from 'react'
import SmallTable from '../../../../components/tables/SmallTable'

function Suggestedkeywords() {

    const firstTableColumns = [
        {
          title: "Keyword",
          selector: "keyword"
        },
        {
          title: "Audience",
          selector: "audience"
        }
    ]
    
    const firstTableData = [
        {
          id: 1,
          keyword: "behance",
          audience: 2
        },
        {
          id: 2,
          keyword: "behance",
          audience: 6
        },
        {
          id: 3,
          keyword: "behance",
          audience: 8
        },
        {
          id: 4,
          keyword: "behance",
          audience: 12
        },
        {
          id: 5,
          keyword: "behance",
          audience: 4
        }
    ]

    const secondTableColumns = [
        {
          title: "Keyword",
          selector: "keyword"
        },
        {
          title: "Price",
          selector: "price"
        },
        {
          title: "Audience",
          selector: "audience"
        }
    ]
    
    const secondTableData = [
        {
          id: 1,
          keyword: "behance",
          price: "€0.46",
          audience: 2
        },
        {
          id: 2,
          keyword: "behance",
          price: "€0.46",
          audience: 6
        },
        {
          id: 3,
          keyword: "behance",
          price: "€0.46",
          audience: 8
        },
        {
          id: 4,
          keyword: "behance",
          price: "€0.46",
          audience: 12
        },
        {
          id: 5,
          keyword: "behance",
          price: "€0.46",
          audience: 4
        }
    ]

    return (
        <div className='suggested-keywords'>
            <div className='row'>
                <div className='col-xl-6'>
                    <h2>Suggested keywords for this page through <br/> <span>Organic Campaign</span></h2>
                    <div className='card'>
                        <SmallTable columns={firstTableColumns} data={firstTableData} />
                        <div>
                            <p>Based on the list above, you can generate the content with these keywords with our Content Generator Tool.</p>
                            <button type='button' className='btn green-btn'>Generate the content</button> 
                        </div>
                    </div>
                </div>
                <div className='col-xl-6'>
                    <h2>Suggested keywords for this page through <br/> <span>Pay-Per-Click  Campaign</span></h2>
                    <div className='card'>
                        <SmallTable columns={secondTableColumns} data={secondTableData} />
                        <div>
                            <p>Based on the list above, launch a Google Ads campaign to increase your visibility faster.</p>
                            <button type='button' className='btn green-btn'>Launch campaign</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Suggestedkeywords