import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Spinner } from 'reactstrap'
import { listTags, addColumn, getPipeline, addTab, deleteTab } from '../../../store/actions'
import { LIST_TAGS } from '../../../store/crm/actionTypes'
import { checkIfLoading, getDayName, getFirst2chars } from '../../../store/functions'
import { deleteColumn, listPipelines, updateColumn } from '../../../store/salesManagement/actions'
import { GET_PIPELINE, LIST_PIPELINES } from '../../../store/salesManagement/actionTypes'
import AddPipelineModal from './Components/AddPipeline'
import CrmNav from './Components/Nav'

function SalesManagement() {
  const dispatch = useDispatch()
  const [addPipelineModal, setAddPipelineModal] = useState(false);
  const [addTabModal, setAddTabModal] = useState(null)
  const [hovering, setHovering] = useState(null)
  const [selectedTag, setSelectedTag] = useState('')
  const [dropdown, setDropdown] = useState(null)
  const [editMode, setEditMode] = useState(null)

  const [tagOpen, setTagOpen] = useState(false);
  const toggle = () => setTagOpen((prevState) => !prevState);

  const toggleAddPipelineModal = () => setAddPipelineModal(!addPipelineModal);

  const { pipelines, listPipelinesLoading, pipeline, selectPipLoading, tags, tagsLoading } = useSelector(state => ({
    pipelines: state?.sales?.pipelines,
    listPipelinesLoading: checkIfLoading(state, LIST_PIPELINES),
    pipeline: state?.sales?.pipeline,
    selectPipLoading: checkIfLoading(state, GET_PIPELINE),
    tags: state?.crm?.tags?.list,
    tagsLoading: checkIfLoading(state, LIST_TAGS)
  }))

  useEffect(() => {
    dispatch(listPipelines())
    dispatch(listTags())
  }, [])

  const renderPipelinesSelect = () => {
    if (listPipelinesLoading) return <Spinner size="sm" className="m-auto text-center" color="success" />
    if (pipelines && pipelines?.length > 0) {
      return pipelines?.map(pipe => {
        return <option value={pipe?._id}>{pipe?.name}</option>
      })
    } else {
      return <option>No Pipelines found</option>
    }
  }

  const onAddTab = (e) => {
    e.preventDefault()
    const col = e.target[0].value
    const name = e.target[1].value

    dispatch(addTab(col, { name }, () => setAddTabModal(null)))
  }

  const handleNameChangeSubmit = (e) => {
    e.preventDefault()
    const name = e.target[0].value
    console.log({ name })
    dispatch(updateColumn(editMode?._id, { name }, () => setEditMode(null)))
  }

  const renderColumnsAndTabs = () => {
    if (selectPipLoading) return <div className='col-md-3'><Spinner size="sm" className="m-auto text-center" color="success" /></div>
    if (pipeline && pipeline?.columns?.length > 0) {
      return pipeline?.columns?.map(col => {
        return <div className='col-md-3'>
          <div className='header d-flex justify-content-between align-items-center'>
            <div className='d-flex align-items-center'>
              <span className='square mr-2'></span>
              <div className='d-flex align-items-center'>
                {
                  editMode?._id === col?._id ? 
                  <form onSubmit={handleNameChangeSubmit}>
                    <input className='form-control custom-border' defaultValue={editMode.name} placeholder='enter column name' />
                    <button type='submit' hidden></button>
                  </form>
                  :
                  <>
                    <h5 className='m-0 mr-2'>{col?.name}</h5>
                    <span className='text-muted'>({col?.tabs?.length || 0})</span>
                  </>
                }
              </div>
            </div>
            <Dropdown className='custom-dropdown' isOpen={dropdown === col?._id} toggle={() => setDropdown(dropdown === col?._id ? false : col?._id)}>
              <DropdownToggle color='transparent'>
                <span><i class="fas fa-ellipsis-h"></i></span>
              </DropdownToggle>
              <DropdownMenu style={{ width: "150px", overflow: 'hidden' }}>
                <DropdownItem onClick={() => setEditMode(col)}>Rename</DropdownItem>
                <DropdownItem onClick={() => dispatch(addColumn(pipeline?._id, { name: col?.name + ' copy' }))}>Duplicate</DropdownItem>
                <DropdownItem divider />
                <DropdownItem onClick={() => dispatch(deleteColumn(col?._id))}>Delete</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
          <ul className='list-unstyle tabs'>
            {
              col?.tabs?.length === 0 ? '' :
                col?.tabs?.map(tab => {
                  return <li onMouseOver={() => setHovering(tab?._id)} onMouseLeave={() => setHovering(null)}>
                    <div className='d-flex justify-content-between'>
                      <div className='d-flex align-items-center'>
                        <span className='logo'>{getFirst2chars(tab?.customer?.name || tab?.name)}</span>
                        <div className='name'>
                          <h5 className='m-0'>{tab?.customer?.name || tab?.name}</h5>
                          <Link to={`/customer/${tab?.customer?._id}`}>View Profile</Link>
                        </div>
                      </div>
                      {tab?._id === hovering ? <span><span className='tab-x-btn m-0' onClick={() => dispatch(deleteTab(tab?._id))}>x</span></span> : ""}
                    </div>
                    <div className='contact text-muted mt-3'>Contact On <span><b>{getDayName(tab?.createdAt)}</b></span></div>
                    <p className='m-0'>{tab?.description}</p>
                  </li>
                })
            }
          </ul>
          <button onClick={() => {
            setAddTabModal(col?._id === addTabModal ? null : col?._id)
          }} className='btn click-btn btn-outline-secondary btn-block py-1 px-2 mt-2'><i className='fas fa-sm mr-1 fa-plus'></i> New tab</button>
          {
            addTabModal === col?._id ? <form onSubmit={onAddTab}>
              <div className='add-tab p-3'>
                <h4 className='mb-2'>New tab</h4>
                <input value={col._id} hidden />
                <input className='form-control' placeholder='type name or email address' />
                <button type='submit' hidden>submit</button>
              </div>
            </form> : ""
          }
        </div>
      })
    } else {
      return 'No columns found'
    }
  }

  const renderTags = () => {
    if (tagsLoading) return <Spinner size="sm" className="m-auto text-center" color="success" />
    if (tags && tags?.length > 0) {
      return tags?.map(tag => {
        return <DropdownItem onClick={() => setSelectedTag(tag)}>
          <button className="btn">{tag?.name}</button>
        </DropdownItem>
      })
    } else {
      return <option>No tags found</option>
    }
  }

  return (
    <div className='container-fluid sales-management page'>
      <CrmNav />
      <div className='row justify-content-between mt-5'>
        <div className='col-md-3'>
          <select className='form-control custom pipelines-select' value={pipeline?._id} onChange={e => dispatch(getPipeline(e.target.value))}>
            {renderPipelinesSelect()}
          </select>
        </div>
        <div className='col-md-3 text-right'>
          <button className='btn click-btn green-btn py-2' onClick={() => setAddPipelineModal(true)}>Add Pipeline</button>
        </div>
      </div>
      <hr />
      <div className='row justify-content-between'>
        <div className='col-md-6'>
          <div className='form-inline'>
            <Dropdown className='custom-dropdown sales-tag-select' isOpen={tagOpen} toggle={toggle}>
              <DropdownToggle caret>
                {selectedTag?.name || 'List all tags'}
              </DropdownToggle>
              <DropdownMenu left={true}>
                <DropdownItem onClick={() => setSelectedTag('')}>
                  <button className="btn">List all tags</button>
                </DropdownItem>
                {renderTags()}
              </DropdownMenu>
            </Dropdown>
            <input placeholder='search' className='form-control custom ml-3' />
          </div>
        </div>
        <div className='col-md-6 text-right'>
          <button className='btn btn-outline-secondary' onClick={() => dispatch(addColumn(pipeline?._id, { name: "new column" }))}><i class="fas fa-sm fa-plus"></i> New Column</button>
        </div>
      </div>
      <div className='row columns mt-3'>
        {renderColumnsAndTabs()}
      </div>
      <AddPipelineModal modal={addPipelineModal} toggle={toggleAddPipelineModal} />
    </div>
  )
}

export default SalesManagement