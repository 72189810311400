import { put, select, takeEvery } from "redux-saga/effects"
import { GetContentAnalysisAPI, GetContentAnalysisPageKeywordsAPI, UpdateContentAnalysisAPI } from "./calls"
import { ContentAnalysisPageKeywordsSuccess, getContentAnalysisSuccess, updateContentAnalysisSuccess } from './actions'
import { CONTENT_ANALYSIS_PAGE_KEYWORDS, GET_CONTENT_ANALYSIS, UPDATE_CONTENT_ANALYSIS } from "./actionTypes"
import { setError, startAction, stopAction } from "../actions"

function* getContentAnalysis({ type, payload: { id } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const result = yield GetContentAnalysisAPI(`workspace=${id}`, state)
    console.log(result)
    yield put(getContentAnalysisSuccess(result?.data?.data))
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* updateContentAnalysis({ type, payload: { } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const result = yield UpdateContentAnalysisAPi(state)
    console.log(result)
    yield put(updateContentAnalysisSuccess(result?.data?.data))
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* contentAnalysisPageKeywords({ type, payload: { } }) {
  try {
    yield put(startAction(type))
    const state = yield select()
    const result = yield GetContentAnalysisPageKeywordsAPI(state)
    console.log(result)
    yield put(ContentAnalysisPageKeywordsSuccess(result?.data?.data))
  } catch (err) {
    console.log(err)
    yield put(setError(err))
  } finally {
    yield put(stopAction(type))
  }
}

function* ContentAnalysisSaga() {
  yield takeEvery(GET_CONTENT_ANALYSIS, getContentAnalysis)
  yield takeEvery(UPDATE_CONTENT_ANALYSIS, updateContentAnalysis)
  yield takeEvery(CONTENT_ANALYSIS_PAGE_KEYWORDS, contentAnalysisPageKeywords)
}

export default ContentAnalysisSaga