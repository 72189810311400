import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Spinner } from 'reactstrap'
import ErrorsChart from '../../../components/analysisComponents.js/ErrorsChart'
import Issues from '../../../components/analysisComponents.js/Issues'
import SideDropdown from '../../../components/analysisComponents.js/SideDropdown'
import ContactUsModal from '../../../components/modals/ContactUsModal'
import { checkIfLoading } from '../../../store/functions'
import { getTechnicalAnalysis } from '../../../store/technicalAnalysis/actions'
import { GET_TECHNICAL_ANALYSIS } from '../../../store/technicalAnalysis/actionTypes'

function index() {
    // ** states
    const [modal, setModal] = useState(false);
    const dispatch = useDispatch()

    // ** function to handle modal toggle
    const toggle = () => setModal(!modal);

    const { loading, workspace, technicalAnalysis } = useSelector(state => ({
        loading: checkIfLoading(state, GET_TECHNICAL_ANALYSIS),
        workspace: state?.workspace?.workspace,
        technicalAnalysis: state?.technicalAnalysis?.data
    }))

    useEffect(() => {
        dispatch(getTechnicalAnalysis(workspace?._id))
    }, [])

    return (
        <div className='analysis'>
            <div className='container-fluid'>
                <div className='row justify-content-center'>
                    <div className='col-11 text-center'>
                        <SideDropdown />
                        <p className='description'>Every good SEO strategy needs to be done page by page. For This reason we indicate deeper analysis in the specific pages</p>
                    </div>
                    <div className='col-xl-10'>
                        {
                            loading ? <div className='text-center'><Spinner size="xl" className="m-auto text-center" color="success" /></div> :
                                <div className='row'>
                                    <div className='col-xl-6'>
                                        <Issues data={technicalAnalysis} />
                                    </div>
                                    <div className='col-xl-6'>
                                        <div className='errors-quotation-chart'>
                                            <div className='card'>
                                                <ErrorsChart data={technicalAnalysis} loading={loading} />
                                            </div>
                                            <p className='fix-hint'>Fixed your issues? Analyze again to see if everything is fixed.</p>
                                            <div className='buttons'>
                                                <button type='button' className='btn click-btn orange-btn'>Analyze website</button>
                                                <button type='button' className='btn click-btn green-btn' onClick={toggle}>Contact us if you need assistance</button>
                                            </div>
                                            <ContactUsModal modal={modal} toggle={toggle} />
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default index