import React, { useState } from 'react'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'

function Header({ workspace }) {
  // ** states
  const [dropdownOpen, setDropdownOpen] = useState(false);

  // ** function to handle toggle of dropdown
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <div className='dashboard-header'>
      <div className='logo'>
        <div>
          <img className='workspace-logo' src={workspace?.logo || `${process.env.PUBLIC_URL}/assets/images/zuma-logo.png`} alt='' />
        </div>
        <div className='description'>
          <h3>{workspace?.name || workspace?.domain}</h3>
          <p>
            <Dropdown className='custom-dropdown' isOpen={dropdownOpen} toggle={toggle}>
              <DropdownToggle caret>
                <img src={`${process.env.PUBLIC_URL}/assets/images/flags/canda-flag.png`} alt='' /> {workspace?.region} ({workspace?.language})
              </DropdownToggle>
              <DropdownMenu left={true}>
                <DropdownItem>
                  <button className="btn">
                    <img src={`${process.env.PUBLIC_URL}/assets/images/flags/canda-flag.png`} alt='en-flag' />
                    Canada (English)
                  </button>
                </DropdownItem>
                <DropdownItem>
                  <button className="btn">
                    <img src={`${process.env.PUBLIC_URL}/assets/images/flags/british-flag.svg`} alt='it-flag' />
                    United Kingdom (English)
                  </button>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <span className='time'>Last updated: {new Date(workspace?.updatedAt).toLocaleDateString()}</span>
          </p>
        </div>
      </div>
      <div className='update'>
        <button type='button' className='btn'><i className="fas fa-redo"></i> Update</button>
      </div>
    </div>
  )
}

export default Header