import React from 'react'
import { FormCheck, Table } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { Spinner } from 'reactstrap'
import TablePagination from '../../../../components/common/Pagination'
import { LIST_CUSTOMERS } from '../../../../store/crm/actionTypes'
import { checkIfLoading } from '../../../../store/functions'

const CustomersTable = ({ query, onPageChange }) => {

    const { customers, count, customerLoading } = useSelector(state => ({
        customers: state?.crm?.customers?.list,
        count: state?.crm?.customers?.page_details?.total_items,
        customerLoading: checkIfLoading(state, LIST_CUSTOMERS)
    }))

    const renderCustomers = () => {
        if (customerLoading) return <tr><td colSpan={6} className="text-center"><Spinner size="md" className="m-auto text-center" color="success" /></td></tr>
        if (customers && customers?.length > 0) {
            return customers?.map(cust => {
                return <tr>
                    <td><FormCheck /></td>
                    <td>{cust?.name}</td>
                    <td>{cust?.phone}</td>
                    <td>{cust?.email}</td>
                    <td>{cust?.amount}</td>
                    <td>{new Date(cust?.createdAt).toLocaleDateString()}</td>
                </tr>
            })
        } else {
            return <tr><td colSpan={6}>No customers found</td></tr>
        }
    }

    return (
        <div className="customers-table">
            <Table striped hover>
                <thead>
                    <tr>
                        <th><FormCheck /></th>
                        <th>Name</th>
                        <th>Phone Number</th>
                        <th>Email</th>
                        <th>Amount Spent</th>
                        <th>Date Added</th>
                    </tr>
                </thead>
                <tbody>
                    {renderCustomers()}
                </tbody>
            </Table>
            <TablePagination count={count} limit={query.limit} loading={customerLoading} page={query.page} setPage={onPageChange} />
        </div>
    )
}

export default CustomersTable