import React, { useState } from 'react'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

function SideDropdown({ pages }) {
  // ** states    
  const [dropdownOpen, setDropdownOpen] = useState(false);

  // ** function to handle toggle of dropdown
  const toggle = () => setDropdownOpen(prevState => !prevState);

  console.log(pages)

  const renderPages = () => {
    if (pages && pages?.length > 0) {
      return pages?.map(p => {
        return <DropdownItem>{p?.url}</DropdownItem>
      })
    }
  }

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle caret>
        Site wide information
      </DropdownToggle>
      <DropdownMenu>
        {renderPages()}
      </DropdownMenu>
    </Dropdown>
  );
}

export default SideDropdown