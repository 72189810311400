import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Spinner } from 'reactstrap'
import AreaChart from '../../../components/charts/AreaChart'

function BounceRate({ total, data, loading }) {

  // ** fake backend database 
  const [chart, setChart] = useState([])

  useEffect(() => {
    if (data) {
      let chartData = []
      data.forEach(e => {
        chartData.push({
          label: moment().format('MM') + '-' + e[0],
          value: e[1]
        })
      })
      console.log(chartData)
      setChart(chartData)
    }
  }, [data])

  return (
    <div className='bounce-rate analytics card'>
      {loading ? <Spinner size="md" className="m-auto text-center" color="success" /> : <>
        <h2 className='number'>{Number(total).toFixed(2)}% <i className="fas fa-arrow-down text-danger ml-2"></i></h2>
        <p>Bounce Rate</p>
        <AreaChart height={130} name="Bounce Rate" data={chart} curveType="straight" showYaxis={false} />
      </>}
    </div>
  )
}

export default BounceRate