// ** import pages
import React from 'react'
import Login from "../../../pages/Auth/Login";
import Signup from "../../../pages/Auth/Signup";
import ResetPassword from "../../../pages/Auth/ResetPassword";
import ForgetPassword from '../../../pages/Auth/ForgotPassword';
import CreateWorkspace from '../../../pages/Auth/CreateWorkspace';
import SelectWorkspace from '../../../pages/Auth/SelectWorkspace';
import ChoosePlan from '../../../pages/Auth/ChoosePlan';
import PaySubscription from '../../../pages/Auth/PaySubscription';
import PaymentDone from '../../../pages/Auth/PaymentDone';

const authRoutes = [
    // ** login route
    {
        path: "/login",
        component: <Login />,
        layout: "auth"
    },
    // ** signup route
    {
        path: "/signup",
        component: <Signup />,
        layout: "auth"
    },
    {
        path: "/add-workspace",
        component: <CreateWorkspace />,
        layout: "auth"
    },
    {
        path: "/select-workspace",
        component: <SelectWorkspace />,
        layout: "auth"
    },
    {
        path: "/select-plan",
        component: <ChoosePlan />,
        layout: "auth"
    },
    {
        path: "/pay-subscription",
        component: <PaySubscription />,
        layout: "auth"
    },
    {
        path: "/payment-success",
        component: <PaymentDone />,
        layout: "auth"
    },
    // ** reset password route
    {
        path: "/forget-password",
        component: <ForgetPassword />,
        layout: "auth"
    },
    {
        path: "/reset-password",
        component: <ResetPassword />,
        layout: "auth"
    }
]

export default authRoutes